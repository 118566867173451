import { useEffect } from "react";
import { Box, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import SearchField from "elements/Field/SearchField";
import AudiencesDataGrid from "components/AudiencesDataGrid";
import useQueryAudiences from "queries/useQueryAudiences";
import { getAudienceColumns } from "../../tableColumns";

export default function Audiences({
  tableState,
  setSelected,
  selectedAudiences,
  updateTableData,
  ...rest
}) {
  const { t } = useTranslation();
  const { tableData, pageTotalSize, isValid, isLoading, isRefetching, error } =
    useQueryAudiences(tableState, "AUDIENCES");
  const checkRowCount = (params) =>
    selectedAudiences.length < 10 || selectedAudiences.includes(params.row.id);

  useEffect(() => {
    updateTableData(tableData);
  }, [tableData]);

  return (
    <Stack spacing={3} my={0}>
      <AudiencesDataGrid
        tableState={tableState}
        tableColumns={getAudienceColumns()}
        disableDensitySelector
        isRowSelectable={(params) => checkRowCount(params)}
        checkboxSelection
        disableSelectionOnClick={false}
        onSelectionModelChange={(newSelectionModel) => {
          setSelected(newSelectionModel);
        }}
        selectionModel={selectedAudiences}
        tableData={tableData}
        pageTotalSize={pageTotalSize}
        isValid={isValid}
        isLoading={isLoading}
        isRefetching={isRefetching}
        error={error}
        {...rest}
      >
        {({ onSearchChange }) => (
          <Box px={3}>
            <SearchField
              searchDisplay={t("Search Audiences")}
              textFieldProps={{
                fullWidth: true,
                size: "small",
                id: "search_audiences",
              }}
              onSearchChange={onSearchChange}
              debounceTimeout={300}
              triggerValue={tableState?.search}
            />
          </Box>
        )}
      </AudiencesDataGrid>
    </Stack>
  );
}
