import { useMemo, useCallback } from "react";
import {
  TextField,
  MenuItem,
  CircularProgress,
  InputAdornment,
} from "@mui/material";

function CustomMenuItem({
  id = "",
  value = "",
  label = "No Data",
  children,
  disabled = false,
  ...rest
}) {
  return (
    <MenuItem id={id} value={value} disabled={disabled} {...rest}>
      {children || label}
    </MenuItem>
  );
}

const getOption = (options, value) =>
  options && options.length
    ? options.find((option) => option.value === value)
    : null;

// Basic Select Field for filtering/changing
// options expected format: [{ value, label }, ...] or []
export default function SelectField({
  options = [],
  value = "",
  CustomSelectOption = false,
  onSelectChange,
  selectDisplay = "Select",
  textFieldProps = {},
  selectFieldProps = {},
  loading = false,
}) {
  const renderFunction = useCallback(
    (optionValue) => {
      const item = getOption(options, optionValue);
      return item ? item.label : "No Data";
    },
    [options]
  );

  const shouldDisplayEmpty = useMemo(
    () => Boolean(value) || !options.length,
    [options, value]
  );

  return (
    <TextField
      label={selectDisplay}
      select
      variant="outlined"
      onChange={(e) => onSelectChange(e.target.value)}
      defaultValue={value}
      value={value}
      SelectProps={{
        displayEmpty: shouldDisplayEmpty,
        renderValue: (optionValue) =>
          loading ? "" : renderFunction(optionValue),
        ...selectFieldProps,
      }}
      InputLabelProps={{
        shrink: shouldDisplayEmpty,
      }}
      InputProps={{
        endAdornment: loading ? (
          <InputAdornment position="end" sx={{ mr: 2 }}>
            <CircularProgress color="inherit" size={20} />
          </InputAdornment>
        ) : null,
      }}
      {...textFieldProps}
    >
      {options.length ? (
        options.map((option) => (
          <CustomMenuItem key={option.id} {...option}>
            {CustomSelectOption && <CustomSelectOption option={option} />}
          </CustomMenuItem>
        ))
      ) : (
        <CustomMenuItem disabled />
      )}
    </TextField>
  );
}
