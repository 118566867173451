import { Divider, Typography } from "@mui/material";
import PeopleAlt from "@mui/icons-material/PeopleAlt";
import House from "@mui/icons-material/House";
import Grid from "@mui/material/Grid";
import numbro from "numbro";
import { useTranslation } from "react-i18next";
import Details from "./Details";
import IconWithText from "./IconWithText";

export default function RecordsAndIndividuals({
  columnSpacing,
  rowSpacing,
  data,
}) {
  const { t } = useTranslation();

  return (
    <div>
      <Grid container columnSpacing={columnSpacing} rowSpacing={rowSpacing}>
        <Grid item xs={4}>
          <IconWithText text={t("Individuals")} Icon={PeopleAlt} />
        </Grid>
        <Grid item xs={4}>
          <Typography>{numbro(data.distinctBluIds).format()}</Typography>
        </Grid>
      </Grid>

      <Grid
        container
        columnSpacing={columnSpacing}
        rowSpacing={rowSpacing}
        sx={{ marginTop: "0px" }}
      >
        <Grid item xs={4}>
          <IconWithText text={t("Households")} Icon={House} />
        </Grid>
        <Grid item xs={4}>
          <Typography>{numbro(data.distinctHh).format()}</Typography>
        </Grid>
      </Grid>
      <Divider
        sx={{
          m: "17px 0",
        }}
      />
      <Details
        columnSpacing={columnSpacing}
        rowSpacing={rowSpacing}
        data={data}
      />
    </div>
  );
}
