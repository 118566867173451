import { cloneDeep, merge, size, inRange } from "lodash";
import { arrayHasKey } from "utils";

export const AND_OPERATOR = "AND";
export const OR_OPERATOR = "OR";
export const SEGMENT_TYPE = "segment";
export const TAXONOMY_TYPE = "taxonomy";

export const DATA_ITEMS_MIN = 1;
export const BUILDER_ITEMS_MIN = 1;
export const DATA_ITEMS_MAX = 20;
export const BUILDER_ITEMS_MAX = 3;

// TBD
export const BUILDER_STATUSES = {
  new: "NEW",
  draft: "DRAFT",
};

export const builderDataItem = {
  id: 1,
  idx: 0,
  calculationID: 0,
  dataId: "",
  dataType: "",
  dataName: "",
  meta: null,
};

export const primaryBuilderItem = {
  id: 1,
  idx: 0,
  isPrimary: true,
  isLast: false,
  itemsOperator: AND_OPERATOR,
  exclude: null,
  dataItems: [],
};

export const secondaryBuilderItem = {
  id: 2,
  idx: 1,
  isPrimary: false,
  isLast: false,
  itemsOperator: AND_OPERATOR,
  exclude: false,
  dataItems: [],
};

export const builderModel = {
  builderName: "",
  id: null,
  accountId: null,
  status: BUILDER_STATUSES.new,
  builderItems: [],
};
// isLast?
export function reMapItems(items) {
  return items.map((bi, i) => {
    const isLast = i !== 0 && i + 1 === items.length;
    const newId = i + 1;
    return {
      ...bi,
      id: newId,
      idx: i,
      isLast,
    };
  });
}

export function checkItemsMax(items, isDataItem = true) {
  const max = isDataItem ? DATA_ITEMS_MAX : BUILDER_ITEMS_MAX;
  return items.length >= max;
}
// convert data for builder dataItems
export function convertToDataItem(data, dataType, meta, xtra = {}) {
  const dataItem = merge(
    {},
    { ...builderDataItem },
    {
      calculationID: data?.calculateID,
      dataType,
      dataName: data.name,
      dataId: data.id,
      meta: meta || null,
    },
    xtra
  );
  return dataItem;
}

// check for unique, max
export function addToDataItems(items, params) {
  const { data, dataType, meta } = params;
  const max = checkItemsMax(items);
  const isDuplicate = arrayHasKey(items, "dataId", data.id);
  if (max || isDuplicate) {
    return { success: false, error: max ? "max" : "duplicate" };
  }
  const copyItems = [...items];
  const newItem = convertToDataItem(data, dataType, meta);
  copyItems.push(newItem);
  return { success: true, items: reMapItems(copyItems) };
}

export function removeFromDataItems(items, id) {
  const newItems = items.filter((item) => item.id !== id);
  return reMapItems(newItems);
}

export function createNewBuilder(initial = {}) {
  const newBuilder = merge(
    {},
    { ...builderModel },
    { builderItems: [{ ...primaryBuilderItem }] },
    initial
  );
  return newBuilder;
}

export function addBuilderItem(idx, xtra = {}) {
  const id = idx + 1;
  const newItem = cloneDeep(secondaryBuilderItem);
  return {
    id,
    idx,
    ...newItem,
    ...xtra,
  };
}

export function updateBuilderItem(idx, items, vals) {
  return items.map((item, index) => {
    if (index !== idx) {
      return item;
    }
    return {
      ...item,
      ...vals,
    };
  });
}

export function removeBuilderItem(id, items) {
  const newItems = items.filter((item) => item.id !== id);
  return reMapItems(newItems);
}

// idx here is next block to insert (+1)
// if last need to change previos last exclude to false
export function insertBuilderItem(idx, items) {
  const prevIdx = idx - 1;
  // const isLast = prevIdx !== 0 && items.length === idx;
  const isLast = items[prevIdx]?.isLast;
  let copyItems = [...items];
  if (isLast) {
    copyItems = updateBuilderItem(prevIdx, copyItems, { exclude: false });
  }
  const newItem = addBuilderItem(idx);
  // const copyItems = [...items];
  copyItems.splice(idx, 0, newItem);
  return reMapItems(copyItems);
}

// validate builder: account, name, items in range; check for dupes? or use unique
export function validateBuilder(builder) {
  const { accountId, builderName, builderItems } = builder;
  return (
    size(String(accountId)) > 0 &&
    size(builderName) > 0 &&
    inRange(size(builderItems), BUILDER_ITEMS_MIN, BUILDER_ITEMS_MAX + 1) &&
    builderItems.every((b) =>
      inRange(size(b.dataItems), DATA_ITEMS_MIN, DATA_ITEMS_MAX + 1)
    )
  );
}

// passed builderItemsDispatch
export function getBuilderItemToggleActions(act) {
  return {
    toggleIopp: (item) =>
      act("update", item, {
        itemsOperator:
          item.itemsOperator === AND_OPERATOR ? OR_OPERATOR : AND_OPERATOR,
      }),
    // TBD change OR Item to AND ?
    toggleExclude: (item) => {
      const newExclude = !item.exclude;
      return act("update", item, {
        exclude: newExclude,
        itemsOperator: newExclude ? AND_OPERATOR : item.itemsOperator,
      });
    },
    removeDataItem: (item, dataItem) => {
      const newDataItems = removeFromDataItems(item.dataItems, dataItem.id);
      return act("update", item, {
        dataItems: newDataItems,
      });
    },
  };
}
