import { useReducer, useCallback, useMemo } from "react";
import { CHANGE, DEFAULT, RESET, CLEAR } from "../../constants/general";

function getInitState(initTableState) {
  return {
    tableState: initTableState,
    lastState: null,
    defaultState: initTableState,
  };
}

function tableStateReducer(state, action) {
  const { type, payload } = action;
  const current = state.tableState;
  const resetState = state.lastState || state.defaultState;

  switch (type) {
    case CHANGE:
      return {
        ...state,
        tableState: { ...state.tableState, ...payload },
        lastState: current,
      };
    case DEFAULT:
      return {
        ...state,
        defaultState: payload,
      };
    case RESET:
      return {
        ...state,
        tableState: resetState,
      };
    case CLEAR:
      return getInitState(state.defaultState);
    default:
      throw new Error(`Unsupported action type: ${action.type}`);
  }
}

export default function useTableState(initialTableState) {
  const [state, dispatch] = useReducer(
    tableStateReducer,
    initialTableState,
    getInitState
  );
  const tableState = useMemo(() => state.tableState, [state.tableState]);

  const getAllState = useCallback(() => state, [state]);

  const tableClear = useCallback(() => dispatch({ type: CLEAR }), []);
  const tableReset = useCallback(() => dispatch({ type: RESET }), []);
  const tableDefault = useCallback(
    (defState) => dispatch({ type: DEFAULT, payload: defState }),
    []
  );
  const tableChange = useCallback(
    (newState) => dispatch({ type: CHANGE, payload: newState }),
    []
  );

  return {
    getAllState,
    tableClear,
    tableReset,
    tableDefault,
    tableChange,
    tableState,
  };
}
