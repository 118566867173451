import { convertJSONDateForTableDisplay, handleDataColumn } from "utils";

const defaults = {
  type: "string",
  sortable: true,
  align: "left",
  headerAlign: "left",
};

export const getDataScienceColumns = () => [
  {
    ...defaults,
    field: "id",
    headerName: "Audience ID",
    minWidth: 130,
    flex: 1,
  },
  {
    ...defaults,
    field: "name",
    headerName: "Audience Name",
    minWidth: 150,
    flex: 1,
  },
  {
    ...defaults,
    field: "created_at",
    headerName: "Created Date",
    type: "date",
    valueGetter: (params) => convertJSONDateForTableDisplay(params.value),
    width: 150,
  },
  {
    ...defaults,
    field: "refreshed_at",
    headerName: "Refreshed Date",
    type: "date",
    valueGetter: (params) => handleDataColumn(params.value),
    width: 150,
  },
  {
    ...defaults,
    field: "size",
    type: "number",
    headerName: "Size",
    valueGetter: (params) => params.row.households || params.row.individuals,
    width: 150,
  },
];
