import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";

export default function EditAudience({ audienceName, onChange }) {
  const { t } = useTranslation();

  return (
    <TextField
      autoFocus
      value={audienceName}
      onChange={(event) => onChange(event.target.value)}
      size="small"
      label={t("Audience Name")}
      fullWidth
      required
    />
  );
}
