import audienceManagementMenuItems from "./audienceManagement";
import dataScienceMenuItems from "./dataScience";
import distributionItems from "./distribution";
import toolsItems from "./tools";

export const getMenuItems = (t, role, isAccountActive = false) => {
  const audienceManagement = audienceManagementMenuItems(
    t,
    role,
    isAccountActive
  );
  const dataScience = dataScienceMenuItems(t, isAccountActive);
  const distribution = distributionItems(t, role, isAccountActive);
  const tools = toolsItems(t);

  const menuItems = {
    admin: [audienceManagement, dataScience, distribution, tools],
    power_user: [audienceManagement, dataScience, distribution],
    general_user: [audienceManagement, dataScience, distribution],
    data_scientist: [audienceManagement, dataScience, distribution],
    view_only: [audienceManagement, dataScience],
  };

  // console.log(isAccountActive, menuItems);
  // return menuItems.filter((m) => m.items.filter((it) => it.visible !== false));
  return menuItems[role] || [];
};
