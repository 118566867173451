import { map as lMap } from "lodash";
// TODO use constants; align with table segments
// tbd lilely will need infinite query or custom
// account changes will clear context
export const clearListState = {
  // sortField: "created_at",
  // sortOrder: "desc",
  // page: 0,
  search: "",
  folder: null,
  status: 999,
};

export const initialListState = {
  // sortField: "created_at", //these do not sort properly until get BE support
  // sortOrder: "desc",
  // page: 0, //set by infinite scroll
  search: "",
  folder: null,
  status: 999, // Initial value. This id represents option "All" returned from BE
  account: null,
  pageSize: 50,
};

// paging combine?
export function getListChange(action, newState) {
  let ret = {};
  switch (action) {
    case "account":
      ret = { account: newState, ...clearListState };
      break;
    case "folder":
      ret = { folder: newState, page: 0 };
      break;
    case "search":
      ret = { search: newState, page: 0 };
      break;
    default:
  }
  return ret;
}

export function getBtnDisabledSx(backColor = "primary", xtra = {}) {
  return {
    "&.Mui-disabled": {
      backgroundColor: `${backColor}.main`,
      color: "white",
      opacity: 0.5,
    },
    ...xtra,
  };
}
export function getOffsetTipProps(
  placement = "top",
  offset = [0, -20],
  tipProps = {}
) {
  return {
    placement,
    arrow: true,
    PopperProps: {
      modifiers: [
        {
          name: "offset",
          options: {
            offset,
          },
        },
      ],
    },
    ...tipProps,
  };
}

// get taxonomy breadcrumb display from breadcrumbs array
export function getBreadCrumbDisplay(
  categories,
  endName = "",
  reverse = false
) {
  const crumbs = lMap(categories, "name");
  crumbs.shift(); // remove root providers
  crumbs.push(endName);
  if (reverse) {
    crumbs.reverse();
    return crumbs.join(" < ");
  }
  return crumbs.join(" > ");
}
