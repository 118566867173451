import { Outlet } from "react-router-dom";
import RoleBasedGuard from "../../components/Guard/RoleBasedGuard";
import { actions, features, targets } from "../../constants/roles";

export default function DataScienceOutlet() {
  return (
    <RoleBasedGuard
      feature={features.models}
      action={actions.view}
      target={targets.pages}
      sx={{
        px: 3,
        py: 2,
      }}
    >
      <Outlet />
    </RoleBasedGuard>
  );
}
