/* eslint-disable no-console */
import { useRef, useEffect } from "react";

export const TABLEAU_SERVER_DOMAIN = "https://spirereporting.horizonmedia.com";

export default function DataView({ pathname, options = {}, query = "" }) {
  const ref = useRef(null);
  const viewUrl = `${TABLEAU_SERVER_DOMAIN}/t/Agency_Reporting/views/${pathname}${query}`;

  function initView() {
    const instance = new window.tableau.Viz(ref.current, viewUrl, options);
    console.log("instance view:", instance);
  }

  useEffect(() => {
    initView();
  }, []);

  return <div style={{ width: "100%", height: "100%" }} ref={ref} />;
}
