import { Box } from "@mui/material";
import Delete from "@mui/icons-material/Delete";
import numbro from "numbro";
import { useEffect, useState } from "react";
import Details from "./OverlapDetails/Details";
import OverlapDetails from "./OverlapDetails";

function filterOverlapDataById(segmentId, overlapData) {
  return overlapData.filter((data) => data.compareSegmentId === segmentId);
}

export default function Segment({
  segment,
  onRemove,
  mainSegmentId,
  handleOverlapData = () => null,
  showOverlap,
  overlapData,
  hasError,
  mutateOverlapData,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const canRequestOverlapData =
    mainSegmentId && segment?.id && !segment?.overlap;

  useEffect(() => {
    if (canRequestOverlapData) {
      mutateOverlapData({ compareSegmentId: segment?.id, mainSegmentId });
      setIsLoading(true);
    }
  }, [mainSegmentId, segment]);

  useEffect(() => {
    if (hasError) {
      setIsLoading(false);
      onRemove(segment);
    }
  }, [hasError]);

  useEffect(() => {
    // If it has data in redux store
    if (overlapData?.length) {
      const data = filterOverlapDataById(segment?.id, overlapData)[0];
      // If it has data in redux store with current ID of segment
      if (data) {
        handleOverlapData({
          overlap: data,
        });

        setIsLoading(false);
      }
    }
  }, [overlapData]);

  const totalPeople = segment?.households || segment?.individuals;

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: `${
          isLoading ? "1fr 76%" : "1fr 230px 1fr 1fr 22px"
        }`,
        alignItems: "center",
        border: "1px solid",
        borderColor: "borderColors.main",
        borderRadius: "8px",
        p: 2,
      }}
    >
      <Details
        title={segment?.name}
        detail={`${numbro(totalPeople).format()} People`}
      />
      {showOverlap && (
        <OverlapDetails isLoadingOverlap={isLoading} data={segment?.overlap} />
      )}
      {onRemove && !isLoading && (
        <Delete
          onClick={() => onRemove(segment)}
          sx={{
            color: "text.secondary",
            cursor: "pointer",
          }}
        />
      )}
    </Box>
  );
}
