import { Typography, Breadcrumbs as BreadcrumbsMui } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export function formatBreadcrumbsWithOperator(
  dataName,
  operator,
  breadcrumbs = []
) {
  breadcrumbs.shift(); // remove root providers
  const [firstValid, ...rest] = [...breadcrumbs, { id: 0, name: dataName }];

  return [{ ...firstValid, operator }, ...rest];
}

export default function Breadcrumbs({ id, breadcrumbs }) {
  return (
    <BreadcrumbsMui
      key={id}
      maxItems={2}
      component="div"
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumbs"
      sx={{
        ".MuiBreadcrumbs-separator": {
          margin: 0,
        },
      }}
    >
      {breadcrumbs.map(({ id: key, name, operator }) => (
        <div key={key}>
          {operator && (
            <Typography
              variant="h6"
              display="inline"
              mr="12px"
              color="initial"
              sx={{ textTransform: "uppercase" }}
            >
              {operator}
            </Typography>
          )}
          <Typography key={id} variant="body1" color="primary" display="inline">
            {name}
          </Typography>
        </div>
      ))}
    </BreadcrumbsMui>
  );
}
