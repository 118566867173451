import axios from "./axios";

const context = "/taxonomies";

export default {
  get: async (categoryId) => {
    try {
      const response = await axios.get(`${context}/${categoryId}`);
      if (response?.data.attributes.length) {
        response.data.attributes[0].calculateID = categoryId;
        return response.data;
      }
      return response?.data;
    } catch (e) {
      const errorMessages = { messages: e.response?.data?.messages };
      throw errorMessages;
    }
  },
  search: async (attributeName) => {
    try {
      const response = await axios.get(
        `${context}/search?search=${attributeName}`
      );
      return response?.data;
    } catch (e) {
      const errorMessages = { messages: e.response?.data?.messages };
      throw errorMessages;
    }
  },
};
