import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import TooltipMUI, { tooltipClasses } from "@mui/material/Tooltip";
import ListItemText from "@mui/material/ListItemText";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// import Link from "@mui/material/Link";
import { convertJSONDateForTypographyDisplay } from "utils";
import numbro from "numbro";
import { useMemo } from "react";
import { SEGMENT_TYPE, TAXONOMY_TYPE } from "../builderModel";

export function Tooltip({ data, dataType }) {
  const items = useMemo(
    () => ({
      [TAXONOMY_TYPE]: [
        { label: "Size", value: numbro(data.size).format() },
        { label: "Description", value: data.description },
        { label: "Precision Level", value: data.precision_level },
      ],
      [SEGMENT_TYPE]: [
        {
          label: "Size",
          value: numbro(data.individuals || data.households).format(),
        },
        {
          label: "Date Created",
          value: convertJSONDateForTypographyDisplay(data.created_at),
        },
        {
          label: "Refresh Date",
          value: convertJSONDateForTypographyDisplay(data.refreshed_at),
        },
        { label: "Data Source", value: data.data_source },
      ],
    }),
    [data]
  );

  const displayItems = items[dataType];

  const title = (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={0}
    >
      {displayItems.map((li) => (
        <Typography
          key={li.label}
          variant="caption"
          component="div"
          sx={{ maxWidth: 280 }}
        >
          <b>{`${li.label}: `}</b>
          {li.value}
        </Typography>
      ))}
    </Stack>
  );

  return (
    <TooltipMUI
      title={title}
      arrow
      placement="top"
      enterDelay={500}
      enterNextDelay={500}
      componentsProps={{
        popper: {
          sx: {
            [`& .${tooltipClasses.arrow}`]: {
              color: (theme) => theme.palette.neutral[600],
            },
            [`& .${tooltipClasses.tooltip}`]: {
              backgroundColor: (theme) => theme.palette.neutral[600],
            },
          },
        },
      }}
      // sx={{ "&.MuiTooltip-tooltip": { backgroundColor: "neutral.600" } }}
    >
      <ListItemText
        primary={data.name}
        primaryTypographyProps={{ noWrap: true }}
      />
    </TooltipMUI>
  );
}

export function ListBreadcrumbs(props) {
  const { children, breadcrumbProps = {} } = props;
  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumbs"
      {...breadcrumbProps}
    >
      {children}
    </Breadcrumbs>
  );
}

export function ChipLabel({ name, prepend = "" }) {
  return (
    <Typography color="primary" variant="caption" lineHeight="unset">
      {prepend}
      <b>{name}</b>
    </Typography>
  );
}
