import { useQuery } from "react-query";
import { useMemo } from "react";
import foldersApi from "../../../api/folders";
import QUERY_KEYS from "../../../constants/queryKeys";
import { sortArrayOfObjects } from "../../../utils";

const defaultOpts = {
  retry: false,
  staleTime: Infinity,
};

export default function useFoldersQuery({ sort, account }, dataConverter) {
  const folders = useQuery(
    [QUERY_KEYS.FOLDERS, { sort, account }],
    () => foldersApi.get({ account }),
    defaultOpts
  );

  const { isValid, foldersData } = useMemo(() => {
    const valid =
      !folders.isLoading &&
      folders.isSuccess &&
      folders.data &&
      folders.data.length;
    let retData = valid ? sortArrayOfObjects(folders.data, "name", sort) : [];
    if (valid && dataConverter) retData = dataConverter(retData);

    return {
      isValid: valid,
      foldersData: retData,
    };
  }, [folders.isLoading, folders.isSuccess, folders.data]);

  return {
    ...folders,
    isValid,
    foldersData,
  };
}
