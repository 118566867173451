import { useTranslation } from "react-i18next";

export const setContentTitle = (data, singular, plural) => {
  const { t } = useTranslation();
  if (data.length > 0) {
    if (data.length === 1) {
      return t(singular);
    }
    return t(plural);
  }
  return "";
};
