import { Box, Typography } from "@mui/material";

export default function Details({ title, detail, containerSxProps }) {
  return (
    <Box
      sx={{
        ...containerSxProps,
      }}
    >
      <Typography>{title}</Typography>
      <Typography color="text.secondary" variant="body2">
        {detail}
      </Typography>
    </Box>
  );
}
