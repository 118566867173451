import { useMemo } from "react";
import { Box, CardActionArea } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";

import { FormLabelStatus, DataItemsDisplay } from "./DataItems";
import {
  BuilderItemActions,
  BuilderNameInput,
  BuilderItemOperator,
  DataItemActions,
} from "./Actions";
import { getBuilderItemToggleActions, checkItemsMax } from "../builderModel";

const headerHeight = 60;
const dataItemsHeight = 58;

// Builder Item Header wrapper
function BuilderHeaderWrapper({ children, sxProps = {} }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        px: 2,
        height: headerHeight,
        py: 0,
        ...sxProps,
      }}
    >
      {children}
    </Box>
  );
}

// main Builder Item component
export default function BuilderItem(props) {
  const {
    item,
    builderName,
    isBuilderTouched,
    isBuilderValid,
    isBuilderDisabled,
    builderItemsMax,
    setBuilderName,
    builderItemsDispatch,
    selected = false,
  } = props;
  const { dataItems, itemsOperator, exclude, isPrimary, isLast } = item;

  const dataItemsMax = useMemo(() => checkItemsMax(dataItems), [dataItems]);

  const { toggleIopp, toggleExclude, removeDataItem } =
    getBuilderItemToggleActions(builderItemsDispatch);

  return (
    <Box>
      {!isPrimary && <BuilderItemOperator />}
      <Box sx={{ display: "flex" }}>
        <Card
          sx={{ flex: 1, borderColor: selected ? "primary.main" : "" }}
          variant="outlined"
        >
          <CardActionArea
            disableRipple={false}
            disabled={isBuilderDisabled}
            onClick={() => builderItemsDispatch("activate", item)}
            sx={{
              "&:hover .MuiCardActionArea-focusHighlight": {
                // NEED to deal with hover issues and inner components - DO NOT REMOVE
                // backgroundColor: "primary.main",
              },
              "& .MuiCardActionArea-focusHighlight": {
                backgroundColor: "transparent",
              },
            }}
          >
            <BuilderHeaderWrapper>
              <Box flex="1 1 auto">
                {isPrimary && (
                  <BuilderNameInput
                    nameValue={builderName}
                    nameTouched={isBuilderTouched}
                    onBuilderNameChange={setBuilderName}
                  />
                )}
              </Box>
              <DataItemActions
                isExclude={!isPrimary && isLast}
                onExclude={() => toggleExclude(item)}
                onOperator={() => toggleIopp(item)}
                itemsOperator={itemsOperator}
                exclude={exclude}
              />
            </BuilderHeaderWrapper>
            <Divider textAlign="left">
              <FormLabelStatus
                count={dataItems.length}
                dataItemsMax={dataItemsMax}
                label="Data Items"
                actionTxt=""
                selected={selected}
              />
            </Divider>
            <CardContent
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                "&.MuiCardContent-root": {
                  minHeight: dataItemsHeight,
                  padding: "16px 16px", // SET TOP TO 6px
                  "&:last-child": {
                    paddingBottom: "16px",
                  },
                },
              }}
            >
              {dataItems.length > 0 && (
                <DataItemsDisplay
                  items={dataItems}
                  itemsOperator={itemsOperator}
                  dataItemsMax={dataItemsMax}
                  onRemove={(dataItem) => removeDataItem(item, dataItem)}
                />
              )}
            </CardContent>
          </CardActionArea>
        </Card>
        <BuilderItemActions
          isPrimary={isPrimary}
          isActionDisabled={!isBuilderValid || builderItemsMax}
          isBuilderDisabled={isBuilderDisabled}
          onRemove={() => builderItemsDispatch("remove", item)}
          onInsert={() => builderItemsDispatch("insert", item)}
        />
      </Box>
    </Box>
  );
}
