import { useCallback } from "react";
import { Drawer } from "@mui/material";

// Base Dialog to handle click away

export default function BaseDrawer({
  children,
  disableBackdropClick = false,
  disableEscapeKeyDown = false,
  onClose,
  ...rest
}) {
  const handleClose = useCallback(
    (_event, reason) => {
      if (disableBackdropClick && reason === "backdropClick") {
        return false;
      }

      if (disableEscapeKeyDown && reason === "escapeKeyDown") {
        return false;
      }

      if (typeof onClose === "function") {
        onClose();
      }
      return true;
    },
    [onClose]
  );

  return (
    <Drawer
      onClose={handleClose}
      disableEscapeKeyDown={disableEscapeKeyDown}
      {...rest}
    >
      {children}
    </Drawer>
  );
}
