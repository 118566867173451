import { Container, Box, Typography } from "@mui/material";

function Page({ children, title, containerProps = {}, actions }) {
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: "100%",
        overflow: "hidden",
      }}
    >
      <Container maxWidth="xl" sx={{ mt: 3, mb: 3 }} {...containerProps}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Typography variant="h4" component="h1" color="text.primary">
            {title}
          </Typography>
          {actions && actions}
        </Box>
        {children}
      </Container>
    </Box>
  );
}

export default Page;
