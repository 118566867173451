import { Box } from "@mui/material";
import {
  Analytics,
  AssignmentReturn,
  Delete,
  Edit,
  FiberSmartRecord,
  FileCopy,
  FolderShared,
  Layers,
  Cached,
} from "@mui/icons-material";
import useRole from "components/Guard/useRole";
import { actions, features } from "constants/roles";
import Action from "./action";

export const actionsNames = {
  REFRESH: "Refresh Segment",
  MODEL: "Model",
  CREATE_FROM: "Create From",
  EDIT_NAME: "Edit Name",
  OVERLAP_ANALYSIS: "Overlap Analysis",
  DUPLICATE: "Duplicate",
  DELETE: "Delete",
  ADD_TO_FOLDER: "Add to Folder",
  COPY_ACROSS_ACCOUNTS: "Copy Segment Across Accounts",
};

export default function Actions({
  selectedAudiences = 0,
  selectedAccount,
  onClick,
}) {
  const defaultProps = { selectedAudiences, selectedAccount, onClick };
  const { checkPermission } = useRole();

  return (
    <Box sx={{ display: "flex", mr: 8 }}>
      {checkPermission(features.segments, actions.refresh) && (
        <Action
          icon={Cached}
          title={actionsNames.REFRESH}
          allowMultiSelection={false}
          {...defaultProps}
        />
      )}
      {checkPermission(features.models, actions.run) && (
        <Action
          icon={Analytics}
          title={actionsNames.MODEL}
          allowMultiSelection={false}
          {...defaultProps}
        />
      )}
      {checkPermission(features.segments, actions.createFrom) && (
        <Action
          icon={Layers}
          title={actionsNames.CREATE_FROM}
          allowMultiSelection={false}
          {...defaultProps}
        />
      )}
      {checkPermission(features.segments, actions.update) && (
        <Action
          icon={Edit}
          title={actionsNames.EDIT_NAME}
          allowMultiSelection={false}
          {...defaultProps}
        />
      )}
      {checkPermission(features.overlapAnalysis, actions.run) && (
        <Action
          icon={FiberSmartRecord}
          title={actionsNames.OVERLAP_ANALYSIS}
          allowMultiSelection={false}
          {...defaultProps}
        />
      )}
      {checkPermission(features.segments, actions.duplicate) && (
        <Action
          icon={FileCopy}
          title={actionsNames.DUPLICATE}
          allowMultiSelection={false}
          {...defaultProps}
        />
      )}
      {checkPermission(features.segments, actions.delete) && (
        <Action
          icon={Delete}
          title={actionsNames.DELETE}
          allowMultiSelection={false}
          {...defaultProps}
        />
      )}
      {checkPermission(features.segmentsFolders, actions.create) && (
        <Action
          icon={AssignmentReturn}
          title={actionsNames.ADD_TO_FOLDER}
          allowMultiSelection
          {...defaultProps}
        />
      )}
      {checkPermission(features.segments, actions.copy) && (
        <Action
          icon={FolderShared}
          title={actionsNames.COPY_ACROSS_ACCOUNTS}
          allowMultiSelection={false}
          {...defaultProps}
        />
      )}
    </Box>
  );
}
