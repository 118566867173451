import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function Action({
  icon: Icon,
  title,
  allowMultiSelection,
  selectedAudiences,
  selectedAccount,
  onClick,
}) {
  const { t } = useTranslation();

  function getColor() {
    let color = "disabled";
    let enableBtn = false;

    if (selectedAccount) {
      if (
        selectedAudiences === 1 ||
        (selectedAudiences > 1 && allowMultiSelection)
      ) {
        enableBtn = true;
      }
    }

    if (enableBtn) {
      color = "primary";
    }

    return color;
  }

  return (
    <Tooltip arrow title={t(title)}>
      <Icon
        color={getColor()}
        sx={{ fontSize: "26px", ml: 3, cursor: "pointer" }}
        onClick={() => getColor() === "primary" && onClick(title)}
      />
    </Tooltip>
  );
}
