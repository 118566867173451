import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import BluButton from "elements/Button/BluButton";
import Chip from "@mui/material/Chip";
import { AND_OPERATOR, OR_OPERATOR } from "../builderModel";
import { getOffsetTipProps } from "../helpers";

// Insert / Remove Actions
export function BuilderItemActions({
  isPrimary,
  isActionDisabled,
  isBuilderDisabled,
  onInsert,
  onRemove,
}) {
  const { t } = useTranslation();
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={0}
    >
      {!isPrimary && (
        <BluButton
          isIcon
          color="primary"
          size="large"
          tip={t("Remove Builder Item")}
          tipProps={getOffsetTipProps()}
          onClick={() => onRemove()}
          disabled={isBuilderDisabled}
          icon={<RemoveCircleIcon fontSize="inherit" />}
        />
      )}

      <BluButton
        isIcon
        color="primary"
        size="large"
        tip={t("Add Builder Item")}
        disabled={isActionDisabled || isBuilderDisabled}
        tipProps={getOffsetTipProps("bottom")}
        onClick={() => onInsert()}
        icon={<AddCircleIcon fontSize="inherit" />}
      />
    </Stack>
  );
}

export function BuilderItemOperator() {
  return (
    <Chip
      color="primary"
      onMouseDown={(event) => event.stopPropagation()}
      size="small"
      variant="filled"
      label="AND"
      sx={{
        borderRadius: 16,
        padding: "4px 4px",
        minWidth: 54,
        lineHeight: 1.4,
        marginBottom: "20px",
      }}
    />
  );
}

export const handlePrevent = (event) => {
  event.stopPropagation();
  event.preventDefault();
};

export function DataItemsOperatorTabs({ itemsOperator, onOperator, exclude }) {
  // eslint-disable-next-line no-unused-vars
  const handleChange = (_event, _newValue) => {
    onOperator();
  };

  return (
    <Tabs
      value={itemsOperator}
      onChange={handleChange}
      aria-label="data-items-operator"
    >
      <Tab
        label={AND_OPERATOR}
        value={AND_OPERATOR}
        component="a"
        onClick={handlePrevent}
        onMouseDown={(event) => event.stopPropagation()}
      />
      {!exclude && (
        <Tab
          label={OR_OPERATOR}
          value={OR_OPERATOR}
          component="a"
          onClick={handlePrevent}
          onMouseDown={(event) => event.stopPropagation()}
        />
      )}
    </Tabs>
  );
}

export function DataItemsExcludeSwitch({ onExclude, exclude }) {
  // eslint-disable-next-line no-unused-vars
  const handleChange = (_event) => {
    // [event.target.name]: event.target.checked,
    onExclude();
  };

  return (
    <FormControlLabel
      onMouseDown={(event) => event.stopPropagation()}
      // onClick={handlePrevent}
      labelPlacement="end"
      sx={{ "&.MuiFormControlLabel-root": { mr: 0, ml: 0 } }}
      control={
        <Switch
          checked={exclude}
          onChange={handleChange}
          name="exclude"
          color="primary"
          // onMouseDown={(event) => event.stopPropagation()}
          // onClick={handlePrevent}
        />
      }
      label="Exclude"
    />
  );
}

// Data item actions: operators and/or tabs/ exclude switch
export function DataItemActions({
  isExclude = false,
  onExclude,
  onOperator,
  itemsOperator,
  exclude,
}) {
  // Todo - translate
  // const excludeText = exclude ? "Excluded" : "Not Excluded";
  return (
    <Box ml={1.5} display="flex" justifyContent="center" alignItems="center">
      <DataItemsOperatorTabs
        itemsOperator={itemsOperator}
        exclude={exclude}
        onOperator={onOperator}
      />
      {isExclude && (
        <DataItemsExcludeSwitch exclude={exclude} onExclude={onExclude} />
      )}
    </Box>
  );
}

// Builder Name Input (primary Builder item)
// debounce? //min? //error
export function BuilderNameInput(props) {
  const { nameValue, nameTouched = false, onBuilderNameChange } = props;
  const { t } = useTranslation();
  const invalid = useMemo(
    () => nameTouched && !nameValue,
    [nameValue, nameTouched]
  );
  const handleChange = (evt) => {
    const val = evt.target.value;
    onBuilderNameChange(val);
  };

  return (
    <TextField
      autoFocus
      sx={{ mt: 0.5 }}
      required
      error={invalid}
      value={nameValue}
      onClick={handlePrevent}
      onMouseDown={(event) => event.stopPropagation()}
      onChange={handleChange}
      id="builder_name"
      size="small"
      label={t("New Audience Name")}
      fullWidth
    />
  );
}
