import { useTranslation } from "react-i18next";
import { TextField, Typography } from "@mui/material";
import SelectField from "elements/Field/SelectField";
import useQueryAccounts from "queries/useQueryAccounts";
import TextFieldFormik from "../../../../../components/TextFieldFormik";

export const convertToDropdownData = (data) =>
  data.map((item) => ({
    value: item.id,
    label: `${item.advertiser} - ${item.brand}`,
    ...item,
  }));

export default function CopyAcrossAccounts({
  segmentName,
  selectedAccountId,
  onSelectAccountChange,
  activeAccount,
  accountId,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
}) {
  const { t } = useTranslation();

  const { data: accounts, isLoading } = useQueryAccounts({
    // Removing current account from dropdown options
    select: (data) => data.filter((account) => account.id !== accountId),
  });

  return (
    <>
      <TextFieldFormik
        value={segmentName}
        label={t("Segment Name")}
        name="segmentName"
        size="small"
        autoFocus
        fullWidth
        form={{
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
        }}
      />
      <Typography my={2}>{t("Will be copied from")}</Typography>
      <TextField
        disabled
        value={activeAccount.clientAccountName}
        size="small"
        label={t("Account name")}
        fullWidth
      />
      <Typography my={2}>{t("To")}</Typography>
      <SelectField
        selectDisplay={t("Please select an account")}
        options={accounts?.length ? convertToDropdownData(accounts) : []}
        value={selectedAccountId}
        loading={isLoading}
        textFieldProps={{
          size: "small",
          fullWidth: true,
        }}
        onSelectChange={onSelectAccountChange}
      />
    </>
  );
}
