import { useCallback, useRef, useEffect } from "react";

import { debounce } from "lodash";

// callback debounce
function useDebounceCallback(
  callback,
  delay = 500,
  opts = {
    leading: false,
    trailing: true,
  }
) {
  const callbackRef = useRef(callback);
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);
  return useCallback(
    debounce((...args) => callbackRef.current(...args), delay, opts),
    []
  );
}

export default useDebounceCallback;
