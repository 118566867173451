import { useTranslation } from "react-i18next";
import {
  Typography,
  Stack,
  Card,
  CardContent,
  List,
  ListItem,
} from "@mui/material";

export default function Confirmation({ partner, audiences }) {
  const { t } = useTranslation();

  return (
    <Stack m={5} spacing={5}>
      <Card>
        <CardContent>
          <Typography>{t("Partner Connection")}:</Typography>
          <List dense>
            <ListItem>
              <Typography color="text.secondary" variant="caption">
                {t("ID")}: {partner?.id}
              </Typography>
            </ListItem>
            <ListItem>
              <Typography color="text.secondary">
                {t("Nickname")}: {partner?.nickname}
              </Typography>
            </ListItem>
            <ListItem>
              <Typography color="text.secondary">
                {t("Partner Name")}: {partner?.partner_name}
              </Typography>
            </ListItem>
          </List>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Typography>{t("Audience")}:</Typography>
          {audiences.map((segment) => (
            <List key={segment?.id} dense>
              <ListItem>
                <Typography color="text.secondary" variant="caption">
                  {t("ID")}: {segment?.id}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography color="text.secondary">
                  {t("Name")}: {segment?.name}
                </Typography>
              </ListItem>
            </List>
          ))}
        </CardContent>
      </Card>
    </Stack>
  );
}
