import { useQuery } from "react-query";
import QUERY_KEYS from "constants/queryKeys";
import partners from "api/partners";

const defaultOptions = {
  retry: false,
  staleTime: Infinity,
};

export default function useQueryPartners({ accountId, queryOptions }) {
  return useQuery([QUERY_KEYS.PARTNERS, { accountId }], () => partners.get(), {
    ...defaultOptions,
    ...queryOptions,
  });
}
