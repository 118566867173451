import { useState, useCallback } from "react";
import { ListItemText } from "@mui/material";
import { ALL } from "constants/general";
import SelectField from "./SelectField";

function renderActions(option, actions, handleActionClick) {
  return option.value === ALL.value
    ? []
    : actions.map(
        ({
          id,
          ComponentName,
          onClick,
          tip,
          tipProps = { placement: "right" },
          btnSize = "small",
          color = "primary",
          isIcon = true,
        }) => (
          <ComponentName
            key={`${option.value}Action${id}`}
            onClick={handleActionClick((event) => onClick(option, event))}
            tip={`${tip}: ${option.label}`}
            tipProps={tipProps}
            btnSize={btnSize}
            color={color}
            isIcon={isIcon}
          />
        )
      );
}

export default function SelectActionsField({
  onSelectChange,
  actions = [],
  selectFieldProps = {},
  ...fieldProps
}) {
  const [selectOpen, setSelectOpen] = useState(false);

  // returns common function that calls custom action from decorated action - onClick
  const getHandleActionClickFunction = useCallback(
    (onClick) => (event) => {
      event.stopPropagation(); // prevents select onChange firing
      setSelectOpen(false);
      if (onClick) {
        onClick(event);
      }
    },
    []
  );

  const SelectOption = useCallback(
    ({ option }) => (
      <>
        <ListItemText primary={option.label} />
        {actions &&
          renderActions(option, actions, getHandleActionClickFunction)}
      </>
    ),
    [getHandleActionClickFunction, actions]
  );

  return (
    <SelectField
      selectFieldProps={{
        open: selectOpen,
        onOpen: () => setSelectOpen(true),
        onClose: () => setSelectOpen(false),
        ...selectFieldProps,
      }}
      onSelectChange={onSelectChange}
      CustomSelectOption={SelectOption}
      {...fieldProps}
    />
  );
}
