import { useMutation, useQueryClient } from "react-query";
import apiSegments from "api/segments";
import { useActionAlerter } from "components/Alert";
import QUERY_KEYS, { getSegmentsListKey } from "constants/queryKeys";

export function useSegmentsDelete(key) {
  const queryKey = getSegmentsListKey(null, key);
  const queryClient = useQueryClient();
  const { start, complete } = useActionAlerter("Delete Segment");

  return useMutation(apiSegments.delete, {
    onMutate: async () => {
      start();
      await queryClient.cancelQueries(queryKey);
    },
    onSettled: async (data, error) => {
      const isSuccess = !error;
      complete(isSuccess, `Description: ${error?.message}`);
      if (isSuccess) {
        await queryClient.invalidateQueries(queryKey);
      }
    },
  });
}

export function useAudiencesEditName({ key, audienceId }) {
  const queryKey = getSegmentsListKey(null, key);
  const queryClient = useQueryClient();
  const { start, complete } = useActionAlerter("Audience name update");

  return useMutation(apiSegments.edit, {
    onMutate: async () => {
      start();
      await queryClient.cancelQueries(queryKey);
      await queryClient.cancelQueries([
        QUERY_KEYS.SEGMENT_DETAILS,
        { id: audienceId },
      ]);
    },
    onSettled: async (data, error) => {
      const isSuccess = !error;
      if (isSuccess) {
        complete(true);
        await queryClient.invalidateQueries(queryKey);
        await queryClient.invalidateQueries(
          [QUERY_KEYS.SEGMENT_DETAILS, { id: audienceId }],
          { refetchInactive: true }
        ); // Invalidating single audience after renaming it
      } else {
        complete(false, `Description: ${error?.message}`);
      }
    },
  });
}

export function useAudiencesDuplicate({ key }) {
  const queryKey = getSegmentsListKey(null, key);
  const queryClient = useQueryClient();
  const { start, complete } = useActionAlerter("Duplicate Audience");

  return useMutation(apiSegments.duplicate, {
    onMutate: async () => {
      start();
      await queryClient.cancelQueries(queryKey);
    },
    onSettled: async (data, error) => {
      const isSuccess = !error;
      if (isSuccess) {
        complete(true);
        await queryClient.invalidateQueries(queryKey);
      } else {
        complete(false, `Description: ${error?.message}`);
      }
    },
  });
}

export function useSegmentsCopy({ key }) {
  const queryKey = getSegmentsListKey(null, key);
  const queryClient = useQueryClient();
  const { start, complete } = useActionAlerter("Copy Segment");

  return useMutation(apiSegments.copy, {
    onMutate: async () => {
      start();
      await queryClient.cancelQueries(queryKey);
    },
    onSuccess: async () => {
      complete(true);
      await queryClient.invalidateQueries(queryKey);
    },
    onError: async (error) => {
      complete(false, `Description: ${error?.message}`);
    },
  });
}

export function useSegmentsRefresh({ key }) {
  const queryKey = getSegmentsListKey(null, key);
  const queryClient = useQueryClient();
  const { start, complete } = useActionAlerter("Refresh Segment");

  return useMutation(apiSegments.refresh, {
    onMutate: async () => {
      start();
      await queryClient.cancelQueries(queryKey);
    },
    onSuccess: async () => {
      complete(true);
      await queryClient.invalidateQueries(queryKey);
    },
    onError: async (error) => {
      complete(false, `Description: ${error?.message}`);
    },
  });
}
