import {
  Stack,
  Typography,
  Toolbar,
  AppBar,
  IconButton,
  Box,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import BaseDrawer from "./BaseDrawer";

export default function InfoDrawer({
  title,
  open,
  onClose,
  children,
  width = 500,
  isLoading = false,
  ...stackConfig
}) {
  return (
    <BaseDrawer
      anchor="right"
      sx={{
        width,
        "& .MuiDrawer-paper": {
          width,
          boxSizing: "border-box",
        },
      }}
      onClose={onClose}
      open={open}
      variant="temporary"
    >
      <AppBar position="static" sx={{ bgcolor: "primary.main" }}>
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Typography variant="h6">{title}</Typography>
          <IconButton color="inherit" aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Stack {...stackConfig}>
        {isLoading ? (
          <Box sx={{ textAlign: "center", width: "100%", mt: "50px" }}>
            <CircularProgress />
          </Box>
        ) : (
          children
        )}
      </Stack>
    </BaseDrawer>
  );
}
