import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Divider,
} from "@mui/material";

function SectionCard({
  children,
  title,
  secondary,
  sx = {},
  headerSxProps,
  dividerSxProps,
  ...cardProps
}) {
  return (
    <Card
      sx={{
        height: "100%",
        ":hover": {
          boxShadow: "0 2px 14px 0 rgb(32 40 45 / 8%)",
        },
        ...sx,
      }}
      {...cardProps}
    >
      {title && (
        <CardHeader
          sx={{ p: 2.5, ...headerSxProps }}
          title={<Typography variant="h6">{title}</Typography>}
          action={secondary}
        />
      )}
      {title && (
        <Divider
          sx={{
            opacity: 1,
            borderColor: "palette.primary.light",
            ...dividerSxProps,
          }}
        />
      )}
      <CardContent sx={{ py: 3 }}>{children}</CardContent>
    </Card>
  );
}

export default SectionCard;
