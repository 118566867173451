import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import TrendingUpRoundedIcon from "@mui/icons-material/TrendingUpRounded";

export default function distributionItems(t, role, isAccountActive) {
  const distributionPartnerConnections = {
    title: t("Partners"),
    id: "distribution.partners",
    isDefault: false,
    path: "/distribution/partners",
    icon: <PersonAddAltRoundedIcon />,
    visible: isAccountActive,
  };
  const distributionDistributedAudiences = {
    title: t("Distributed Audiences"),
    id: "distribution.distributed-audiences",
    isDefault: true,
    path: "/distribution/distributed-audiences",
    icon: <TrendingUpRoundedIcon />,
    visible: isAccountActive,
  };
  const distributionSubItems = {
    admin: [distributionPartnerConnections, distributionDistributedAudiences],
    power_user: [
      distributionPartnerConnections,
      distributionDistributedAudiences,
    ],
    general_user: [
      distributionPartnerConnections,
      distributionDistributedAudiences,
    ],
    data_scientist: [distributionDistributedAudiences],
    view_only: [],
  };

  return {
    title: t("Distribution"),
    id: "distribution",
    items: distributionSubItems[role],
  };
}
