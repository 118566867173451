import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import BluButton from "./BluButton";

export default function DeleteButton(props) {
  const {
    title = "Delete",
    isIcon = true,
    btnSize = "medium",
    ...otherProps
  } = props;

  return (
    <BluButton
      title={title}
      icon={isIcon ? <DeleteIcon fontSize={btnSize} /> : <DeleteOutlineIcon />}
      sx={{ alignItems: "stretch" }}
      size={btnSize}
      isIcon={isIcon}
      {...otherProps}
    />
  );
}
