/* eslint-disable no-console */
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import DataView from "components/DataView";
import SectionCard from "elements/Cards/SectionCard";
import Page from "elements/Page";
import { actions, features, targets } from "constants/roles";
import RoleBasedGuard from "components/Guard/RoleBasedGuard";

export default function Insights() {
  const { t } = useTranslation();

  return (
    <RoleBasedGuard
      feature={features.models}
      action={actions.view}
      target={targets.pages}
      sx={{
        px: 3,
        py: 2,
      }}
    >
      <Page title={t("Insights")}>
        <Typography variant="h6" align="left" color="text.secondary">
          To add new audiences to the below dropdown please contact the service
          desk.
        </Typography>
        <SectionCard>
          <Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <DataView
              pathname="AudienceInsights/Demographics"
              options={{
                width: "100%",
                height: "700px",
                onFirstInteractive() {
                  console.log("Finished loading Tableau viz");
                },
              }}
            />
          </Box>
        </SectionCard>
      </Page>
    </RoleBasedGuard>
  );
}
