/* eslint-disable no-console */
import { useMemo } from "react";
import toast, { Toaster, resolveValue } from "react-hot-toast";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Slide from "@mui/material/Slide";
import CircularProgress from "@mui/material/CircularProgress";

// custom Toaster - replace ToasterBar with MUI Alert
// extends toast for additional types warning, info; title option
// ToastType = 'success' | 'error' | 'loading' | 'blank' | 'custom';
// alerts outlined, standard, filled : if use outlined then set bgcolor: 'background.paper'

const statusMap = {
  success: "success",
  error: "error",
  loading: "info",
  blank: "info",
};

// Alert toast Bar
function AlertToastBar(props) {
  const { toastData, alertProps = {} } = props;
  // console.log("Alert Data", toastData);
  const { isLoader, status, hasTitle } = useMemo(() => {
    const { type, title, isWarning } = toastData;
    const alertStatus =
      type === "error" && isWarning ? "warning" : statusMap[type] || "info";
    return {
      status: alertStatus,
      isLoader: type === "loading",
      hasTitle: title && type !== "loading",
    };
  }, [toastData.type, toastData.title, toastData.isWarning]);

  const retProps = isLoader
    ? {
        icon: <CircularProgress color="inherit" size={20} />,
      }
    : {
        onClose: () => {
          toast.dismiss(toastData.id);
        },
      };

  return (
    <Slide
      direction="down"
      in={toastData.visible}
      mountOnEnter
      unmountOnExit
      {...(!toastData.visible ? { timeout: 500 } : {})}
    >
      <Alert
        // variant={isLoader ? "standard" : "filled"}
        variant="filled"
        severity={status}
        elevation={4}
        sx={{
          maxWidth: 400,
          // maxHeight: 200,
          minWidth: isLoader ? 160 : 200,
          "&.MuiAlert-filledSuccess": {
            backgroundColor: "success.dark",
          },
          "&.MuiAlert-filledWarning": {
            backgroundColor: "warning.dark",
          },
          "&.MuiAlert-filledInfo": {
            backgroundColor: isLoader ? "info.main" : "info.dark",
          },
          "& .MuiAlert-message": {
            flex: 1,
            textOverflow: "ellipsis",
            whiteSpace: "normal",
            overflow: "hidden",
            // textAlign: hasTitle ? "left" : "center",
          },
        }}
        {...retProps}
        {...alertProps}
      >
        {hasTitle && <AlertTitle>{resolveValue(toastData.title)}</AlertTitle>}
        {resolveValue(toastData.message)}
      </Alert>
    </Slide>
  );
}

// Alert Toaster
export default function AlertToaster() {
  return (
    <Toaster
      position="top-right"
      reverseOrder={false}
      gutter={8}
      containerClassName=""
      containerStyle={{
        top: 80,
        // left: 20,
        // bottom: 20,
        // right: 20,
      }}
      toastOptions={{
        // duration: 4000,
        success: {
          duration: 3000,
        },
        error: {
          duration: 5000,
        },
      }}
    >
      {(t) => <AlertToastBar toastData={t} />}
    </Toaster>
  );
}
