import { useTranslation } from "react-i18next";
import SelectField from "elements/Field/SelectField";
import { convertFolders } from "components/AudiencesDataGrid/helpers";
import useAccount from "contexts/accountContext";
import useFoldersQuery from "../../useFoldersQuery";

export default function AddToFolder({ selectedFolderId, onSelectChange }) {
  const { t } = useTranslation();

  const { accountId } = useAccount();
  const { foldersData, isValid, isLoading } = useFoldersQuery(
    {
      sort: "ASC",
      account: accountId,
    },
    convertFolders
  );

  return (
    <SelectField
      selectDisplay={t("Select Folder")}
      options={isValid ? foldersData : []}
      value={selectedFolderId}
      loading={isLoading}
      textFieldProps={{
        size: "small",
        fullWidth: true,
      }}
      onSelectChange={onSelectChange}
    />
  );
}
