import axios from "../axios";

const context = "/folders";

export default {
  create: async ({ folderId, segmentIds, accountId }) => {
    try {
      const response = await axios.post(`${context}/${folderId}/segments`, {
        segments: segmentIds,
        account: accountId,
      });
      return response?.data;
    } catch (e) {
      throw e?.response?.data;
    }
  },
  delete: async ({ folderId, segmentId }) => {
    try {
      const response = await axios.delete(
        `${context}/${folderId}/segments/${segmentId}`
      );
      return response?.data;
    } catch (e) {
      throw e?.response?.data;
    }
  },
};
