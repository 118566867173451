import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

// Base data grid - tbd common props
function BaseDataGrid({
  sxProps = {},
  columns = [],
  tableData = [],
  pageSize = 10,
  ...rest
}) {
  const { sx: gridSx = {}, ...gridProps } = rest;
  return (
    <Box sx={{ width: "100%", ...sxProps }}>
      <DataGrid
        rows={tableData}
        columns={columns}
        // loading={isLoading || isRefetching}
        // error
        autoHeight
        pageSize={pageSize}
        rowsPerPageOptions={[10, 20, 50]}
        // checkboxSelection
        // disableSelectionOnClick
        sx={{
          border: "none",
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "neutral.100",
          },
          "& .MuiDataGrid-columnHeader": {
            px: 1,
            "&.MuiDataGrid-columnHeaderCheckbox": {
              px: 0,
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontSize: 16,
              // textTransform: "uppercase",
            },
            "& .MuiDataGrid-columnSeparator": {
              color: "neutral.300",
            },
          },
          ...gridSx,
        }}
        {...gridProps}
      />
    </Box>
  );
}

export default BaseDataGrid;
