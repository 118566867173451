import { useMemoizedCallback } from "hooks";
import { useMemo } from "react";
import BaseDataGrid from "components/DataGrid";
import LinearProgress from "@mui/material/LinearProgress";
import { GridFooterContainer } from "@mui/x-data-grid";
import { Typography } from "@mui/material";
import numbro from "numbro";
import { getScaleSegmentColumns } from "./getColumns";

function CustomFooter() {
  return (
    <GridFooterContainer
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 150px 150px 250px 80px",
      }}
    >
      <Typography
        variant="overline"
        display="flex"
        justifyContent="end"
        mr="50px"
      >
        Selected Total
      </Typography>
      <Typography variant="body2" pl="10px">
        {numbro(32279677).format()}
      </Typography>
      <Typography variant="body2" pl="10px">
        50-90%
      </Typography>
      <Typography variant="body2" pl="10px">
        1.72
      </Typography>
    </GridFooterContainer>
  );
}

export default function ScaleSegmentTable({
  segmentIds,
  setSelectedAudiences,
  tableData,
  setTableData,
}) {
  const getRowOnSelection = useMemoizedCallback((ids) => {
    const selectedIDs = new Set(ids);
    const selectedRowData = tableData.filter((row) => selectedIDs.has(row.id));
    setSelectedAudiences(selectedRowData);
  });

  const handleRemove = useMemoizedCallback((segment) => {
    const updatedData = tableData.filter((data) => data.id !== segment.id);
    setTableData(updatedData);
  });

  const handleChangeProbability = useMemoizedCallback((newValue, segmentId) => {
    const updatedValue = tableData.map((data) => {
      if (data.id === segmentId) {
        return {
          ...data,
          probability: newValue,
        };
      }
      return data;
    });

    setTableData(updatedValue);
  });

  const columns = useMemo(
    () => getScaleSegmentColumns({ handleRemove, handleChangeProbability }),
    []
  );

  return (
    <BaseDataGrid
      density="comfortable"
      tableData={tableData}
      columns={columns}
      onSelectionModelChange={(ids) => getRowOnSelection(ids)}
      selectionModel={segmentIds}
      components={{ LoadingOverlay: LinearProgress, Footer: CustomFooter }}
      getRowId={(row) => row.id}
      checkboxSelection
      disableSelectionOnClick
      pageSize={5}
      // The purpose of this CSS below is to make the tooltip visible
      sx={{
        "& .MuiDataGrid-virtualScroller": {
          overflow: "unset !important",
        },
        "& .MuiDataGrid-cell": {
          overflow: "visible",
        },
      }}
    />
  );
}
