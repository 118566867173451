/** All names here about features, pages and actions must match with names returned from roles API
 * The API is in snake_case but the data is being converted to camelCase in the front end.
 * API: security/role
 */

const roles = {
  features: {
    clientLogo: "clientLogo",
    clientAccounts: "clientAccounts",
    segments: "segments",
    overlapAnalysis: "overlapAnalysis",
    folder: "folder",
    segmentsFolders: "segmentsFolders",
    insights: "insights",
    additionalInfo: "additionalInfo",
    distribution: "distribution",
    partnerConnections: "partnerConnections",
    models: "models",
  },
  pages: {
    segments: "segments",
    overlapAnalysis: "overlapAnalysis",
    models: "models",
    builder: "builder",
    distribution: "distribution",
    partnerConnections: "partnerConnections",
    insights: "insights",
  },
};

export const targets = {
  features: "features",
  pages: "pages",
};

export const actions = {
  read: "read",
  create: "create",
  update: "update",
  delete: "delete",
  duplicate: "duplicate",
  copy: "copy",
  createFrom: "createFrom",
  refresh: "refresh",
  download: "download",
  view: "view",
  run: "run",
};

export const roleNames = {
  ADMIN: "admin",
  POWER_USER: "power_user",
  GENERAL_USER: "general_user",
  DATA_SCIENTIST: "data_scientist",
  VIEW_ONLY: "view_only",
};

export const { features, pages } = roles;
