import { Typography, Box, Badge } from "@mui/material";
import Chip from "@mui/material/Chip";
import FormLabel from "@mui/material/FormLabel";
import { getBreadCrumbDisplay } from "../helpers";
import { ChipLabel } from "../components/common";
import { AND_OPERATOR, TAXONOMY_TYPE } from "../builderModel";

// adjusted width for Data Items to handle maxWidth to prevent clipping
// OR - 23px + 8 margin; AND - 36px + 8 margin
const andDataItemWidth = "calc(100% - 44px)";
const orDataItemWidth = "calc(100% - 31px)";

function calcMaxWidth(opp, isFirst) {
  if (isFirst) return "100%";
  return opp === AND_OPERATOR ? andDataItemWidth : orDataItemWidth;
}

// focus on selected
export function FormLabelStatus({
  dataItemsMax,
  count = 0,
  actionTxt = "Select",
  label,
  selected,
}) {
  // const display = dataItemsMax ? "Data Items" : "Data Items";
  const empty = count === 0;
  const status = count === 0 ? "primary" : "secondary";
  const display = empty || count < 2 ? actionTxt : count;
  return (
    <FormLabel
      component="legend"
      color={status}
      error={!selected && empty}
      focused={selected} // makes color
      required={empty}
      sx={{ fontSize: empty ? 14 : 14, fontWeight: 500 }}
    >
      {`${display} ${label}`}
      {dataItemsMax && (
        <Typography
          position="absolute"
          top={-4}
          right={-22}
          variant="caption"
          component="div"
          fontSize={10}
        >
          MAX
        </Typography>
      )}
    </FormLabel>
  );
}

export function DataItemsStatus({ dataItemsMax, count = 0 }) {
  const display = dataItemsMax ? "Data Items" : "Data Items";
  return (
    <Badge
      badgeContent={count}
      // variant="dot"
      // max={19}
      color={dataItemsMax ? "warning" : "info"}
      // overlap="circular"
      sx={{
        "& .MuiBadge-badge": {
          left: -12,
          top: 8,
          // border: `2px solid ${theme.palette.background.paper}`,
          padding: "0 4px",
        },
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Typography
        color="text.secondary"
        component="div"
        variant="subtitle2"
        // ml={1}
        // textTransform="uppercase"
      >
        {display}
      </Typography>
    </Badge>
  );
}

// Data Item with data type chip and operator: handle overflow in chip maxWidth
export function DataItem({
  item,
  isFirst = false,
  itemsOperator,
  handleDelete,
}) {
  const { dataType, dataName, meta } = item;
  const isTaxonomy = dataType === TAXONOMY_TYPE;
  /* const displayName = isTaxonomy
    ? getBreadCrumbDisplay(meta?.breadcrumbs, dataName)
    : dataName; */
  const displayPre = isTaxonomy ? getBreadCrumbDisplay(meta?.breadcrumbs) : "";
  const itemWidth = calcMaxWidth(itemsOperator, isFirst);

  return (
    <Box display="flex" alignItems="center" maxWidth={itemWidth}>
      {!isFirst && (
        <Typography
          variant="button"
          sx={{ mr: 1, fontSize: 18, lineHeight: 1.4 }}
        >
          {itemsOperator}
        </Typography>
      )}
      <Chip
        color="primary"
        onMouseDown={(event) => event.stopPropagation()}
        size="small"
        onDelete={() => handleDelete(item)}
        variant="outlined"
        // label={displayName}
        label={<ChipLabel name={dataName} prepend={displayPre} />}
      />
    </Box>
  );
}

// Data Items Chips; try new css gap to avoid wrapping issues
// https://coryrylan.com/blog/css-gap-space-with-flexbox
export function DataItemsDisplay({
  items,
  itemsOperator,
  onRemove,
  dataItemsMax,
}) {
  const handleDelete = (dataItem) => {
    // console.info("You clicked the delete icon.", dataItem);
    onRemove(dataItem);
  };
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      flexWrap="wrap"
      gap="8px"
      width="100%"
    >
      {items.map((di, idx) => (
        <DataItem
          key={di.id}
          item={di}
          isFirst={idx === 0}
          itemsOperator={itemsOperator}
          handleDelete={handleDelete}
        />
      ))}
      {dataItemsMax && (
        <Typography color="warning.main" component="div" variant="caption">
          Maximum Reached!
        </Typography>
      )}
    </Box>
  );
}
