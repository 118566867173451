import { useEffect, useState } from "react";
import { Outlet, useMatch, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectUserData } from "../../slices/user";
import Header from "./Header";
import Sidebar from "./Sidebar";
import IdleModal from "./IdleModal";
import useAccount from "../../contexts/accountContext";
import useQueryRole from "./Sidebar/useQueryRole";
import { setRole } from "../../slices/role";
import useWebSocket from "./useWebSocket";

const isDevelopment = process.env.NODE_ENV === "development"; // test !== "production"

const MainLayoutContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "isFullWidth",
})(({ theme, isFullWidth }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  paddingTop: 64,
  [theme.breakpoints.up("lg")]: {
    paddingLeft: isFullWidth ? 0 : 280,
  },
}));

export default function MainLayoutContent({ username }) {
  const navigate = useNavigate();
  const { accountId, activateAccount } = useAccount();
  const dispatch = useDispatch();
  const matchDashboardUrl = useMatch({
    path: "/personal-dashboard",
    end: true,
    caseSensitive: true,
  });
  const isPersonalDashboard = !!matchDashboardUrl?.pathname;
  const matchDefaultURL = useMatch({
    path: "/",
    end: true,
    caseSensitive: true,
  });
  const isDefaultURL = !!matchDefaultURL?.pathname;
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isOpenIdleModal, setIsOpenIdleModal] = useState(false);
  // get user attributes
  const user = useSelector((state) => selectUserData(state.user));

  const { data: role } = useQueryRole({
    accountId,
    queryProps: {
      enabled: !!accountId,
    },
  });

  useWebSocket({ username });

  useEffect(() => {
    if (role) {
      dispatch(setRole(role));
    }
  }, [role]);

  useEffect(() => {
    const accountStored = JSON.parse(localStorage.getItem("account"));
    if (!accountId && !accountStored?.id) {
      navigate("personal-dashboard");
    } else if (!accountId) {
      activateAccount(accountStored);
    } else if (isDefaultURL || matchDashboardUrl) {
      navigate("audience-management");
    }
  }, [accountId]);

  return (
    <>
      <MainLayoutContainer isFullWidth={isPersonalDashboard}>
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Outlet />
        </Box>
      </MainLayoutContainer>
      <Header
        isPersonalDashboard={isPersonalDashboard}
        onOpenSidebar={() => setIsSidebarOpen(true)}
        user={user}
      />
      {!isPersonalDashboard && (
        <Sidebar
          onClose={() => setIsSidebarOpen(false)}
          open={isSidebarOpen}
          isDevelopment={isDevelopment}
          role={role}
        />
      )}
      <IdleModal
        isOpen={isOpenIdleModal}
        setIsOpenIdleModal={setIsOpenIdleModal}
      />
    </>
  );
}
