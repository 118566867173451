import { Box } from "@mui/material";

export default function Matching({ match, containerSxProps }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: `${match}%`,
        minWidth: "20%",
        height: "30px",
        ...containerSxProps,
      }}
    >
      {match}%
    </Box>
  );
}
