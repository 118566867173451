/* eslint-disable no-console */
import { useState, useEffect } from "react";
import { useMemoizedCallback } from "hooks";
import useDebounceCallback from "hooks/useDebounceCallback";

const isDebug = false;

// hook to provide middleware - state/values callbacks for autocomplete
export default function useSearch(
  onSearchChange,
  onSearchSelect,
  searchDelay = 500,
  minSearchLength = 3
) {
  const [isSearchValid, setIsSearchValid] = useState(false);
  const [searchText, setSearchText] = useState("");

  const debounceCallback = useDebounceCallback(
    (val) => {
      setIsSearchValid(true);
      // at this point is valid search
      onSearchChange(val, "search");
      // setSearchOpen true
    },
    searchDelay,
    {
      leading: false,
      trailing: true,
    }
  );

  useEffect(
    () => () => {
      debounceCallback.cancel();
    },
    []
  );

  const resetSearch = useMemoizedCallback(
    (resetInput = true, triggerChange = true) => {
      setIsSearchValid(false);
      // setSearchOpen false
      if (resetInput) {
        setSearchText("");
      }
      debounceCallback.cancel();
      if (triggerChange) onSearchChange(false, "reset");
    }
  );
  // autocomplete input change
  const handleInputChange = useMemoizedCallback((_, val, reason) => {
    if (isDebug) console.log("INPUT CHANGE", val, reason);
    // can be from search clear or select clear
    if (reason === "clear") {
      resetSearch(true);
    } else if (reason === "input") {
      setSearchText(val); // set here if maintaining inputVal
      if (val && val.length >= minSearchLength) {
        // setIsSearchValid(true);
        debounceCallback(val);
      } else {
        resetSearch(false);
      }
    } else if (reason === "reset") {
      // setSearchText(searchText);
    }
    // reason reset is from selecting item
  });
  // autocomplete change - specific to selection
  const handleSelectChange = useMemoizedCallback((_, val, reason) => {
    if (isDebug) console.log("SELECT CHANGE", val, reason);
    if (reason === "selectOption") {
      onSearchSelect(val, "select");
    }
  });

  return {
    searchText,
    isSearchValid,
    handleInputChange,
    handleSelectChange,
    resetSearch,
  };
}
