import { useMutation } from "react-query";
import { useActionAlerter } from "components/Alert";
import apiCalculations from "api/calculations";

export function useMutationCalculations() {
  const { start, complete } = useActionAlerter("Create Calculations");

  return useMutation(apiCalculations.post, {
    onMutate: async () => {
      start();
      // option to cancel other queries if necessary
    },
    onSuccess: async () => {
      complete(true);
    },
    onError: async (error = "failed to create calculation") => {
      complete(false, `Description: ${error}`);
    },
  });
}
