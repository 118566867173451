import { useState, useMemo } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import InfoDrawer from "elements/Dialog/InfoDrawer";
import { useMemoizedCallback } from "hooks";
import BluButton from "elements/Button/BluButton";
import useAccount from "contexts/accountContext";
import useTableState from "components/DataGrid/useTableState";
import {
  initialTableState,
  getTableChange,
} from "components/AudiencesDataGrid/helpers";
import { useMutationDistributions } from "../useMutationDistributions";
import Audiences from "./Audiences";
import Confirmation from "./Confirmation";
import PartnerConnections from "./PartnerConnections";

const createStepsContent = {
  steps: ["Select Partner", "Choose Segment", "Confirm Distribute"],
  step1: "Step 1: Select a Partner Connection",
  step2: "Step 2: Choose an Audience Segment",
  step3: "Step 3: Confirm your choices and create Distribution",
  finish: "Distribution completed!",
  error: "Problem Creating Distribution",
};

function StepAction(props) {
  const {
    step,
    isComplete,
    isLast,
    handleAction,
    handleBack,
    isActiveAction,
    disableNext,
  } = props;
  const hasBack = !isComplete && step !== 0;
  const info = isComplete
    ? createStepsContent.finish
    : createStepsContent[`step${step + 1}`];
  // eslint-disable-next-line no-nested-ternary
  const btnActionTxt = isComplete
    ? "START ANOTHER DISTRIBUTION"
    : isLast
    ? "CREATE DISTRIBUTION"
    : "NEXT";
  return (
    <Box display="flex" pt={3} alignItems="center">
      <Typography
        variant="subtitle1"
        color="text.secondary"
        fontWeight={500}
        component="div"
      >
        {info}
      </Typography>
      <Box sx={{ flex: "1 1 auto" }} />
      {hasBack && (
        <BluButton
          size="small"
          color="primary"
          variant="text"
          disabled={isActiveAction}
          title="BACK"
          onClick={() => handleBack()}
        />
      )}
      <BluButton
        size="small"
        color="primary"
        sx={{ ml: 1 }}
        disabled={isActiveAction || disableNext}
        // variant="text"
        title={btnActionTxt}
        onClick={() => handleAction()}
      />
    </Box>
  );
}

export default function CreateDistribution(props) {
  /*

  Vendor code has issues with table pagination where navigating to a second page erases
  table data, and retrieves a fresh table data definition from the api. The lack of
  total table view causes the table to forget selections made on previous pages.

  Adding a temporary system to record data retrieved from all pages to allow us
  to select across pages.    const updateTableData and const [tableData, setTableData] = useState([]);

  TODO-> rebuild audience query and all audience tables to allow for singular data retrieval

   */

  const { accountId } = useAccount();
  const [partnerConnectionsPage, setPartnerConnectionsPage] = useState(0);
  const { tableState, tableChange } = useTableState({
    ...initialTableState,
    account: accountId,
    pageSize: 10,
    action: "distribution",
  });
  const { onClose, isActive, drawerProps = {} } = props;
  const { t } = useTranslation();
  const [isError, setIsError] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedPartnerConnections, setSelectedPartnerConnections] = useState(
    []
  );
  const [selectedAudiences, setSelectedAudiences] = useState([]);
  const [tableData, setTableData] = useState([]);
  const { steps, error } = createStepsContent;
  const totalSteps = steps.length;
  const lastStep = totalSteps - 1;
  const isLast = lastStep === activeStep;
  const isComplete = activeStep === totalSteps;

  const disableNextAction = useMemo(() => {
    const disabled =
      (activeStep === 0 && selectedPartnerConnections.length < 1) ||
      (activeStep === 1 && selectedAudiences.length < 1);
    return disabled;
  }, [selectedAudiences, selectedPartnerConnections, activeStep]);

  const {
    mutate: createDistributionMutate,
    isLoading: isCreateDistributionLoading,
  } = useMutationDistributions({ accountId });

  const handleNext = useMemoizedCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setIsError(false);
  });

  const handleBack = useMemoizedCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setIsError(false);
  });

  const handleReset = useMemoizedCallback(() => {
    // reset partner / segments
    setPartnerConnectionsPage(0);
    setSelectedAudiences([]);
    setSelectedPartnerConnections([]);
    setActiveStep(0);
    setIsError(false);
    setTableData([]);
  });

  const handleDistributeAction = useMemoizedCallback(() => {
    if (!isLast) {
      handleNext();
    } else {
      setIsError(false);
      createDistributionMutate(
        {
          partnerConnectionId: selectedPartnerConnections[0].id,
          audienceIds: selectedAudiences,
        },
        {
          onSuccess: () => {
            handleNext();
          },
          onError: () => {
            setIsError(true);
          },
        }
      );
    }
  });

  const handleClose = useMemoizedCallback(() => {
    handleReset();
    onClose();
  });

  const updateTableData = (data) => {
    setTableData(tableData.concat(data));
  };

  let confirmationAudienceData;
  if (selectedAudiences) {
    confirmationAudienceData = selectedAudiences.map((segment) => {
      const selectedName = tableData.find(
        (tableRow) => tableRow.id === segment
      ).name;
      return { id: segment, name: selectedName };
    });
  }

  return (
    <InfoDrawer
      title={t("Create Distribution")}
      open={isActive}
      onClose={handleClose}
      width="75%"
      {...drawerProps}
    >
      <Box p={3}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => {
            const labelProps = {};
            if (isError && index === activeStep) {
              labelProps.error = true;
              labelProps.optional = (
                <Typography
                  variant="caption"
                  color="error"
                  component="div"
                  sx={{ textAlign: "center" }}
                >
                  {error}
                </Typography>
              );
            }
            return (
              <Step key={label} disabled={false}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <StepAction
          step={activeStep}
          isLast={isLast}
          isComplete={isComplete}
          handleBack={handleBack}
          isActiveAction={isCreateDistributionLoading}
          handleAction={isComplete ? handleReset : handleDistributeAction}
          disableNext={disableNextAction}
        />
      </Box>
      {activeStep === 0 && (
        <PartnerConnections
          setSelected={setSelectedPartnerConnections}
          selected={selectedPartnerConnections}
          account={accountId}
          page={partnerConnectionsPage}
          onPageChange={(newPage) => setPartnerConnectionsPage(newPage)}
          rowsPerPageOptions={[10]}
          density="compact"
        />
      )}
      {activeStep === 1 && (
        <Audiences
          setSelected={setSelectedAudiences}
          selectedAudiences={selectedAudiences}
          selectedIds={selectedAudiences}
          tableState={tableState}
          tableChange={tableChange}
          getTableChange={getTableChange}
          updateTableData={updateTableData}
          rowsPerPageOptions={[10]}
          density="compact"
        />
      )}
      {activeStep === 2 && (
        <Confirmation
          partner={selectedPartnerConnections[0]}
          audiences={confirmationAudienceData}
        />
      )}
    </InfoDrawer>
  );
}
