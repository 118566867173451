import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function NoNotifications() {
  const { t } = useTranslation();
  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="subtitle2">
        {t("There are no notifications")}
      </Typography>
    </Box>
  );
}
