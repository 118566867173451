import { useQuery } from "react-query";
import QUERY_KEYS from "constants/queryKeys";
import connections from "api/connections";

const defaultOptions = {
  retry: false,
  staleTime: Infinity,
};

export default function useQueryPartnerConnections({
  accountId,
  queryProps = {},
}) {
  return useQuery(
    [QUERY_KEYS.PARTNER_CONNECTIONS, { accountId }],
    () => connections.get({ accountId }),
    { ...defaultOptions, ...queryProps }
  );
}
