import { useState } from "react";
import { Badge, Box } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import BluButton from "../../../../elements/Button/BluButton";
import Popover from "./Popover";

export default function Notifications() {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const notifications = useSelector((state) => state.notifications);

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <BluButton
        size="large"
        aria-label={t("notifications")}
        color="default"
        onClick={handleOpenPopover}
        tip={t("Notifications")}
        ref={anchorEl}
        isIcon
      >
        <Badge badgeContent={notifications.unread} color="error">
          <NotificationsIcon />
        </Badge>
      </BluButton>
      <Popover
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        open={Boolean(anchorEl)}
        notifications={notifications}
      />
    </Box>
  );
}
