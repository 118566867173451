import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";

function Item(props) {
  const { sx, account, onClick } = props;
  const { id, clientAccountName, logoUrl } = account;
  const isDevelopment = process.env.NODE_ENV === "development";
  // Images are hosted on S3. This variable makes it display locally, since we don't have the files
  // in our machines. It's the Horizon dev URL
  const originUrl = isDevelopment
    ? "https://d3s2z0lrnnlgdh.cloudfront.net"
    : window.location.origin;

  return (
    <Box
      key={id}
      onClick={() => onClick(id)}
      sx={{
        p: 1,
        m: 1,
        width: 350,
        height: 150,
        bgcolor: "white",
        padding: 0,
        color: "grey.800",
        border: "0px",
        borderRadius: 2,
        fontSize: "0.875rem",
        fontWeight: "700",
        cursor: "pointer",
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 3px",
        ...sx,
      }}
    >
      <div
        style={{
          padding: "20px 20px 0 20px",
          display: "flex",
          height: "88px",
        }}
      >
        <div style={{ width: "55%" }}>
          <div>{clientAccountName}</div>
        </div>
        <div style={{ width: "45%", textAlign: "center" }}>
          <img
            style={{ maxWidth: "100%", maxHeight: "100%" }}
            src={`${originUrl}/${logoUrl}`}
            alt="logo"
          />
        </div>
      </div>
      <hr style={{ border: "1px solid #ededed" }} />
      <div style={{ padding: "10px 20px" }}>
        <div
          style={{ fontSize: ".775rem", marginTop: "0px", color: "#3d7cb3" }}
        >
          Select This Customer
        </div>
      </div>
    </Box>
  );
}

export default function ClientAccountsSelectionGrid({
  setSelectedAccount,
  clientAccounts,
}) {
  const navigate = useNavigate();
  const getAccountOnSelection = (id) => {
    const selectedRowData = clientAccounts.filter((row) => row.id === id);
    setSelectedAccount(selectedRowData[0]);
    navigate("/audience-management");
  };

  return (
    <div style={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          p: 1,
          m: 1,
          maxWidth: "100%",
          borderRadius: 1,
        }}
      >
        {clientAccounts.map((account) => (
          <Item
            key={account.id}
            account={account}
            onClick={getAccountOnSelection}
          />
        ))}
      </Box>
    </div>
  );
}
