import { useMemo } from "react";
import { GridToolbar } from "@mui/x-data-grid";
import BaseDataGrid from "components/DataGrid";
import { useMemoizedCallback } from "hooks";
import useQueryPartnerConnections from "../../useQueryPartnerConnections";
import { getPartnerConnectionColumns } from "../../tableColumns";
import { roleNames } from "../../../../constants/roles";
import useRole from "../../../../components/Guard/useRole";

export default function PartnerConnections({
  account,
  selected,
  setSelected,
  ...rest
}) {
  const {
    data: partnerConnectionsData,
    isLoading,
    isRefetching,
    error,
  } = useQueryPartnerConnections({ accountId: account });
  const { role } = useRole();
  const roleName = role?.role;
  const hidePoweredBy = roleName === (roleNames.ADMIN && roleName.POWER_USER);
  const columns = useMemo(
    () => getPartnerConnectionColumns(partnerConnectionsData),
    [partnerConnectionsData]
  );

  const selectedIds = useMemo(
    () => selected.map((part) => part.id),
    [selected]
  );

  const handleOnSelection = useMemoizedCallback((ids) => {
    const selectedIDs = new Set(ids);
    const selectedRowData = partnerConnectionsData.filter((row) =>
      selectedIDs.has(row.id)
    );

    setSelected(selectedRowData);
  });

  return (
    <BaseDataGrid
      tableData={partnerConnectionsData || []}
      columns={columns}
      columnVisibilityModel={{ powered_by: hidePoweredBy }}
      loading={isLoading || isRefetching}
      error={error}
      selectionModel={selectedIds}
      onSelectionModelChange={handleOnSelection}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: "nickname",
              sort: "asc",
            },
          ],
        },
      }}
      components={{ Toolbar: GridToolbar }}
      componentsProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
      {...rest}
    />
  );
}
