import { List, ListSubheader, Chip, Typography } from "@mui/material";
import ListItemLink from "./ListItemLink";

export function StatusChip({ text = "New", color = "info" }) {
  return (
    <Chip
      color={color}
      label={
        <Typography
          sx={{
            fontSize: "13px",
            fontWeight: "500",
          }}
        >
          {text}
        </Typography>
      }
      size="small"
    />
  );
}

function renderNavItems({ depth = 0, items }) {
  if (!items) {
    return null;
  }
  return items.map((nav) => {
    const {
      id,
      title,
      path,
      icon,
      isNew = false,
      isDefault = false,
      visible,
    } = nav;
    return (
      <ListItemLink
        key={id}
        primary={title}
        to={path}
        isDefault={isDefault}
        visible={visible}
        depth={depth}
        icon={icon}
        secondary={isNew ? <StatusChip /> : null}
      />
    );
  });
}

// generate nav section TBD: roles, branding etc
function ListSection(props) {
  const { items, title, children, ...other } = props;
  return (
    <List
      subheader={
        <ListSubheader
          disableGutters
          disableSticky
          sx={{
            color: "neutral.400",
            fontSize: "0.75rem",
            fontWeight: 600,
            lineHeight: 2.5,
            ml: 2,
            textTransform: "uppercase",
          }}
        >
          {title}
        </ListSubheader>
      }
      {...other}
    >
      {renderNavItems({
        items,
      })}
      {children && children}
    </List>
  );
}

export default ListSection;
