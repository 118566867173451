import { useTranslation } from "react-i18next";
import { Grid, Stack, Box, Typography } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Page from "../../elements/Page";
import SectionCard from "../../elements/Cards/SectionCard";
import LinkWrapper from "../../elements/LinkWrapper";
import { TextColor, BackgroundColor } from "./colors";
import { Types, Headings } from "./typography";
import {
  TextButtons,
  ContainedButtons,
  OutlinedButtons,
  OutlinedSizeButtons,
  TextSizeButtons,
  ContainedSizeButtons,
} from "./buttons";
import BluButton from "../../elements/Button/BluButton";
import CreateButton from "../../elements/Button/CreateButton";
import EditButton from "../../elements/Button/EditButton";
import DeleteButton from "../../elements/Button/DeleteButton";
import StatCards from "./statCards";
import Toasts from "./toasts";
import DataGrid from "./DataGrid";

export default function StyleGuide() {
  const { t } = useTranslation();

  return (
    <Page title={t("Style Guide")}>
      <Grid container spacing={3} alignItems="stretch">
        {/* <Grid item xs={12} sm={6}>
          <SectionCard title="Sub Card">
            <Typography
              variant="body"
              align="left"
              color="text.secondary"
              paragraph
            >
              Something short and leading about the collection below—its
              contents, the creator, etc. Make it short and sweet, but not too
              short so folks don&apos;t simply skip over it entirely.
            </Typography>
          </SectionCard>
        </Grid>
        <Grid item xs={12} sm={6}>
          <SectionCard title="Another Card">
            <Typography
              variant="body"
              align="left"
              color="text.secondary"
              paragraph
            >
              Something short and leading about the collection below—its
              contents, the creator, etc. Make it short and sweet, but not too
              short so folks don&apos;t simply skip over it entirely. Something
              short and leading about the collection below—its contents, the
              creator, etc. Make it short and sweet, but not too short so folks
              don&apos;t simply skip over it entirely.
            </Typography>
          </SectionCard>
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <SectionCard
            title="Typography Headings"
            secondary={
              <LinkWrapper href="https://mui.com/material-ui/react-typography/">
                MUI Typography
              </LinkWrapper>
            }
          >
            <Headings />
          </SectionCard>
        </Grid>
        <Grid item xs={12} sm={6}>
          <SectionCard
            title="Typography Types"
            secondary={
              <LinkWrapper href="https://mui.com/material-ui/react-typography/">
                MUI Typography
              </LinkWrapper>
            }
          >
            <Types />
          </SectionCard>
        </Grid>
        <Grid item xs={12} sm={6}>
          <SectionCard
            title="Color"
            secondary={
              <LinkWrapper href="https://mui.com/system/palette/#color">
                MUI Palette
              </LinkWrapper>
            }
          >
            <TextColor />
          </SectionCard>
        </Grid>
        <Grid item xs={12} sm={6}>
          <SectionCard
            title="Background Color"
            secondary={
              <LinkWrapper href="https://mui.com/system/palette/#background-color">
                MUI Palette
              </LinkWrapper>
            }
          >
            <BackgroundColor />
          </SectionCard>
        </Grid>
        <Grid item lg={8}>
          <SectionCard
            title="Button Types"
            secondary={
              <LinkWrapper href="https://mui.com/material-ui/react-button/">
                MUI Buttons
              </LinkWrapper>
            }
          >
            <Stack spacing={2}>
              <TextButtons />
              <ContainedButtons />
              <OutlinedButtons />
            </Stack>
          </SectionCard>
        </Grid>
        <Grid item lg={4}>
          <SectionCard
            title="Button Sizes"
            secondary={
              <LinkWrapper href="https://mui.com/material-ui/react-button/">
                MUI Buttons
              </LinkWrapper>
            }
          >
            <Stack spacing={2}>
              <TextSizeButtons />
              <ContainedSizeButtons />
              <OutlinedSizeButtons />
            </Stack>
          </SectionCard>
        </Grid>
        <Grid item lg={12}>
          <SectionCard title="Blu Custom Button Component">
            <Stack spacing={2} direction="row">
              <BluButton title="Default" />
              <BluButton
                title="Button Tooltip w/ Icon"
                color="secondary"
                tip="This is a button with tooltip and props"
                icon={<DashboardIcon />}
              />
              <BluButton
                isIcon
                color="info"
                tip="This is icon button with tooltip and props"
                icon={<DashboardIcon />}
              />
              <CreateButton tip="Create something" />
              <CreateButton tip="Create something" isIcon />
              <EditButton tip="Edit something" />
              <EditButton tip="Edit something" isIcon />
              <DeleteButton tip="Delete something" isIcon={false} />
              <DeleteButton tip="Delete something" />
            </Stack>
          </SectionCard>
        </Grid>
        <Grid item lg={12}>
          <SectionCard
            title="Data Grid (Blu Base Data Grid)"
            secondary={
              <LinkWrapper href="https://mui.com/x/react-data-grid/getting-started/">
                MUI Data Grid
              </LinkWrapper>
            }
          >
            <Box sx={{ display: "flex", pl: 2 }}>
              <Typography
                variant="subtitle2"
                gutterBottom
                component="div"
                color="text.secondary"
              >
                Features: paging, sorting, action columns, filtering, searching,
                selection, editing (name, discount)
              </Typography>
            </Box>
            <DataGrid />
          </SectionCard>
        </Grid>
        <Grid item lg={12}>
          <SectionCard title="Stat Cards">
            <Box sx={{ p: 3, bgcolor: "grey.300" }}>
              <StatCards />
            </Box>
            <Box sx={{ p: 3, mt: 3 }}>
              <StatCards />
            </Box>
          </SectionCard>
        </Grid>
        <Grid item lg={12}>
          <SectionCard
            title="Alert Toast Component"
            secondary={
              <LinkWrapper href="https://react-hot-toast.com/">
                React Hot Toast
              </LinkWrapper>
            }
          >
            <Toasts />
          </SectionCard>
        </Grid>
      </Grid>
    </Page>
  );
}
