import axios from "./axios";

const context = "/distributions";

export default {
  get: async ({ accountId }) => {
    try {
      const response = await axios.get(context, {
        params: {
          account: accountId,
        },
      });
      return response?.data;
    } catch (e) {
      throw e?.response?.data;
    }
  },
  post: async ({ audienceIds, partnerConnectionId }) => {
    try {
      const response = await axios.post(context, {
        segment_ids: audienceIds,
        partner_connection_id: partnerConnectionId,
      });
      return response?.data;
    } catch (e) {
      throw e?.response?.data;
    }
  },
};
