/* eslint-disable no-console */
import { useEffect, useMemo } from "react";
import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import { useMemoizedCallback } from "../../hooks";

const isDebug = false;

// TODO reenable filter/sort mode to server

// grid sort
// needs convert
/* sortModel={{
  field: "name",
  sort: "desc",
} */
// grid filter item
/* {
  "items": [
      {
          "columnField": "name",
          "operatorValue": "contains",
          "id": 62470
      }
  ],
  "quickFilterValues": [
      "randy"
  ]
} */
// error, isValid - use?

// from tableState sort - convert for grid
function convertSortValuesToSortModel(sortField, sortOrder) {
  if (!sortField) {
    return [];
  }
  return [{ field: sortField, sort: sortOrder.toLowerCase() }];
}

// from grid sort - convert for table state - reset to initial sort?
// JUST LET TABLE STATE DECIDE - as should provide initial sort
function convertSortModelToSortValues(sortItems) {
  if (!sortItems || !sortItems.length) {
    return { sortField: null, sortOrder: null };
  }
  return { sortField: sortItems[0].field, sortOrder: sortItems[0].sort };
}

function BluDataGrid(props) {
  const {
    columns = [],
    tableData,
    tableState,
    tableDispatch,
    customizationStorage,
    handleCustomizationStorageChange,
    pageTotalSize,
    isValid,
    error,
    sxProps = {},
    mode = "server",
    isLoading = false,
    density,
    ...rest
  } = props;

  const {
    page,
    pageSize,
    rowsPerPageOptions = [20, 50, 100],
    sortField,
    sortOrder,
  } = tableState;

  const sortingModel = useMemo(() => {
    const convertSort = convertSortValuesToSortModel(sortField, sortOrder);
    if (isDebug) console.log("SORTING MODEL CHANGE FROM STATE", convertSort);
    return convertSort;
  }, [sortField, sortOrder]);

  const handleTableDispatch = useMemoizedCallback((type, newState) => {
    if (isDebug) console.log("table dispatch", type, newState);
    let ret;
    switch (type) {
      case "page":
        ret = { page: newState };
        break;
      case "pageSize":
        ret = { pageSize: newState };
        break;
      case "sort":
        ret = { ...newState };
        break;
      default:
    }
    tableDispatch(type, ret, true);
  });

  // TBD - sorting - conversion
  const handleSortChange = useMemoizedCallback((newSort) => {
    // is an array
    const sortConvert = convertSortModelToSortValues(newSort);
    handleTableDispatch("sort", sortConvert);
  });

  const handlePageChange = useMemoizedCallback((newPage) => {
    handleTableDispatch("page", newPage);
  });

  const handleColumnVisibilityModelChange = useMemoizedCallback((newValues) => {
    if (handleCustomizationStorageChange) {
      handleCustomizationStorageChange(
        "columns.columnVisibilityModel",
        newValues
      );
    }
  });

  const handlePageSizeChange = useMemoizedCallback((newSize) => {
    handleTableDispatch("pageSize", newSize);

    if (handleCustomizationStorageChange) {
      handleCustomizationStorageChange("pageSize", newSize);
    }
  });

  const handleStateChange = useMemoizedCallback((gridState) => {
    if (
      density !== gridState.density.value &&
      handleCustomizationStorageChange
    ) {
      handleCustomizationStorageChange("density", gridState.density.value);
    }
  });

  useEffect(() => {
    if (isDebug) console.log("Grid", tableState, tableData);
  }, [tableState, tableData]);

  return (
    <Box sx={{ width: "100%", ...sxProps }}>
      <DataGrid
        initialState={customizationStorage}
        onStateChange={handleStateChange}
        autoHeight
        loading={isLoading}
        error={error}
        rows={tableData}
        columns={columns}
        onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
        density={density}
        page={page}
        pageSize={pageSize}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        rowCount={pageTotalSize}
        rowsPerPageOptions={rowsPerPageOptions}
        checkboxSelection
        disableSelectionOnClick
        disableColumnFilter
        keepNonExistentRowsSelected
        /* initialState={{
          sorting: initialSorting,
        }} */
        filterMode="client"
        paginationMode={mode}
        sortingMode="client"
        sortModel={sortingModel}
        onSortModelChange={handleSortChange}
        // filterModel={filterModel}
        // onFilterModelChange={handleFilterChange}
        components={{
          LoadingOverlay: LinearProgress,
          Toolbar: GridToolbar,
        }}
        /*  componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }} */
        rowHeight={60}
        sx={{
          border: "none",
          "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
            {
              display: "none",
            },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "neutral.100",
          },
          "& .MuiDataGrid-columnHeader": {
            px: 2,
            "&.MuiDataGrid-columnHeaderCheckbox": {
              px: 0,
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontSize: 16,
              // textTransform: "uppercase",
            },
            "& .MuiDataGrid-columnSeparator": {
              color: "neutral.300",
            },
          },
        }}
        {...rest}
      />
    </Box>
  );
}

export default BluDataGrid;
