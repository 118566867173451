import { useQuery } from "react-query";
import { useMemo } from "react";
import segments from "api/segments";
import { cleanParams } from "utils";
import { getSegmentsListKey } from "constants/queryKeys";

const queryOptions = {
  keepPreviousData: true,
  retry: false,
  staleTime: Infinity,
};

// adapted for reuse as table grid and list
// infinite query may be needed for list
export default function useQueryAudiences(tableState, key, queryProps = {}) {
  const params = cleanParams(tableState);
  const tKey = getSegmentsListKey(params, key);
  const tableQuery = useQuery(tKey, () => segments.get(params), {
    ...queryOptions,
    ...queryProps,
  });
  const { isValid, pageTotalSize, tableData } = useMemo(() => {
    const valid =
      !tableQuery.isLoading && tableQuery.isSuccess && tableQuery.data !== null;

    return {
      isValid: valid,
      pageTotalSize: valid ? tableQuery.data.total_results : 0,
      tableData: valid ? tableQuery.data.results : [],
    };
  }, [tableQuery.isLoading, tableQuery.isSuccess, tableQuery.data]);

  return {
    ...tableQuery,
    isValid,
    pageTotalSize,
    tableData,
  };
}
