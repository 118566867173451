import { useTranslation } from "react-i18next";
import { Outlet, Link, useLocation } from "react-router-dom";
import { Tabs, Tab } from "@mui/material";
import Page from "../../elements/Page";

// TBD routed tabs for admin
const tabPaths = ["general", "customization"];

export default function Admin() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const currentTab = tabPaths.find((p) => pathname.includes(p)) || tabPaths[0];

  return (
    <Page title={t("Administration")}>
      <Tabs
        indicatorColor="primary"
        // onChange={handleTabsChange}
        // scrollButtons="auto"
        textColor="primary"
        value={currentTab}
        // variant="scrollable"
        sx={{ my: 3 }}
      >
        <Tab
          label={t("General")}
          value="general"
          to="/admin/general"
          component={Link}
        />
        <Tab
          label={t("Customization")}
          value="customization"
          to="/admin/customization"
          component={Link}
        />
      </Tabs>
      <Outlet />
    </Page>
  );
}
