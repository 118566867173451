import { useQuery } from "react-query";
import taxonomiesApi from "../../../../api/taxonomies";
import QUERY_KEYS from "../../../../constants/queryKeys";

const defaultOptions = {
  retry: false,
  staleTime: Infinity,
};

export function getTaxonomies(categoryId, queryProps = {}) {
  const taxonomies = useQuery(
    [QUERY_KEYS.GET_TAXONOMIES, { categoryId }],
    () => taxonomiesApi.get(categoryId),
    {
      ...defaultOptions,
      ...queryProps,
    }
  );

  return {
    ...taxonomies,
  };
}

export function searchTaxonomies(tableState, queryKey, queryProps = {}) {
  const attributeName = tableState?.search;
  const taxonomies = useQuery(
    [queryKey, { attributeName }],
    () => taxonomiesApi.search(attributeName),
    {
      ...defaultOptions,
      ...queryProps,
    }
  );

  return {
    ...taxonomies,
  };
}
