export default {
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_AWS_DOMAIN,
      scope: [
        "phone",
        "email",
        "openid",
        "profile",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN_URL,
      redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT_URL,
      responseType: "code",
    },
  },
};
