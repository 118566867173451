import { useState, useMemo, useEffect } from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import { useFirstRender } from "../../../hooks";
import useDebounce from "./useDebounce";
import { TRIGGER, SEARCH, CLEAR, RESET } from "../../../constants/general";

// Basic Search Field
// callbacks with search; triggerValue, triggerReset to change from parent; loading state
export default function SearchField(props) {
  const {
    onSearchChange,
    searchDisplay = "Search",
    triggerValue = "",
    textFieldProps = {},
    allowClear = true,
    loading = false,
    triggerReset = false,
    debounceTimeout = 0, // Milliseconds
  } = props;
  const isFirst = useFirstRender();
  const [searchValue, setSearchValue] = useState(triggerValue);
  const [type, setType] = useState("");
  const debouncedValue = useDebounce(searchValue, debounceTimeout);

  const isActive = useMemo(
    () => Boolean(!loading && searchValue && searchValue.length),
    [searchValue]
  );

  useEffect(() => {
    onSearchChange(debouncedValue, type);
  }, [debouncedValue]);

  const handleSearchChange = (val, searchType) => {
    setSearchValue(val);
    setType(searchType);
  };

  useEffect(() => {
    if (!isFirst) {
      handleSearchChange(triggerValue, TRIGGER);
    }
  }, [triggerValue]);

  useEffect(() => {
    if (!isFirst) {
      handleSearchChange("", RESET);
    }
  }, [triggerReset]);

  return (
    <TextField
      placeholder={searchDisplay}
      type="text"
      variant="outlined"
      onChange={(e) => handleSearchChange(e.target.value, SEARCH)}
      value={searchValue}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment:
          // eslint-disable-next-line no-nested-ternary
          isActive && allowClear ? (
            <IconButton
              aria-label="clear search"
              onClick={() => handleSearchChange("", CLEAR)}
            >
              <CancelIcon />
            </IconButton>
          ) : loading ? (
            <InputAdornment position="end">
              <CircularProgress color="inherit" size={20} />
            </InputAdornment>
          ) : null,
      }}
      {...textFieldProps}
    />
  );
}
