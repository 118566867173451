import { Box, LinearProgress } from "@mui/material";
import numbro from "numbro";
import { useTranslation } from "react-i18next";
import Matching from "../Matching";
import Details from "./Details";

export default function OverlapDetails({ data, isLoadingOverlap }) {
  const { t } = useTranslation();
  const showMatch = data?.percentage > 0;
  const showNoMatch = data?.percentage < 100;
  // eslint-disable-next-line no-unsafe-optional-chaining
  const noMatch = 100 - data?.percentage;

  return !isLoadingOverlap && data ? (
    <>
      <Box
        sx={{
          display: "flex",
          color: "white",
          gap: "2px",
          width: "230px",
        }}
      >
        {showMatch && (
          <Matching
            match={data.percentage}
            containerSxProps={{
              backgroundColor: "primary.main",
            }}
          />
        )}
        {showNoMatch && (
          <Matching
            match={noMatch}
            containerSxProps={{
              backgroundColor: "primary.dark",
            }}
          />
        )}
      </Box>
      <Details
        title={t("Overlap")}
        detail={`${numbro(data?.overlap).format()} People`}
        containerSxProps={{
          ml: 4,
        }}
      />
      <Details title={t("Percentage")} detail={`${data.percentage}%`} />
    </>
  ) : (
    <Box sx={{ width: "100%" }}>
      <LinearProgress />
    </Box>
  );
}
