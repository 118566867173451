import { useState } from "react";
import { useMemoizedCallback, usePrevious } from "hooks";

// Builder Status display
// Todo: use language and constants

const builderStatusCfg = {
  ready: {
    mode: "ready",
    statusTxt: "Ready",
    info: "Create Builder: Enter an Audience name and add data to begin",
    status: "info",
    isInitial: true,
  },
  start: {
    mode: "start",
    statusTxt: "Started",
    success: "Create/Calculate Builder or continue editing",
    warning: "Enter required (*) Builder information",
    status: "success",
    isInitial: true,
  },
  create: {
    mode: "create",
    statusTxt: "Creating",
    // success: "Builder Creation Complete",
    success: "Builder Creation in progress",
    error: "Error creating Builder",
    status: "success",
    isInitial: true,
  },
};

function getStatus(mode, xtra = {}) {
  const cfg = builderStatusCfg[mode];
  return {
    ...cfg,
    ...xtra,
  };
}

export default function useBuilderStatus(initialMode = "ready") {
  const [builderStatus, setBuilderStatus] = useState(
    getStatus(initialMode, { isInitial: true })
  );

  const prevBuilderStatus = usePrevious(builderStatus);
  const changeBuilderStatus = useMemoizedCallback((status) =>
    setBuilderStatus({ ...builderStatus, isInitial: false, status })
  );
  const changeBuilderMode = useMemoizedCallback((mode, statusCfg = {}) => {
    const newCfg = getStatus(mode, { isInitial: true, ...statusCfg });
    setBuilderStatus(newCfg);
  });
  return {
    builderStatus,
    prevBuilderStatus,
    changeBuilderMode,
    changeBuilderStatus,
  };
}
