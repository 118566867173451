/* eslint-disable no-shadow */
import { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Avatar,
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Menu,
  Typography,
} from "@mui/material";
import Logout from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Settings from "@mui/icons-material/Settings";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { getInitials } from "../../../../utils";
import { sxIsHidden } from "../../../../utils/styles";
import BluButton from "../../../../elements/Button/BluButton";
import { logout } from "../../../../slices/authentication";
import AccountsMenu from "./Accounts";

function UserAvatar(props) {
  const { size = 40, initials } = props;
  return (
    <Avatar
      sx={{
        width: size,
        height: size,
        fontSize: 16,
        bgcolor: "primary.main",
      }}
    >
      {initials}
    </Avatar>
  );
}

const showSettings = false; // flag to test admin with settings

// Profile menu dropdown with user info; leaving Settings as option to show use of link/admin etc
export default function UserProfile({ user, isAdmin, isPersonalDashboard }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const username = useSelector((state) => state.user.username);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  // TBD based on user data
  const { initials, display, email, full } = useMemo(() => {
    const full = `${user?.firstName} ${user?.lastName}`;
    const display = user?.displayName || full;
    const initials = getInitials(full);
    const email = user.email || "";
    return {
      full,
      display,
      initials,
      email,
    };
  }, [user]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    dispatch(logout(username));
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <BluButton
          onClick={handleClick}
          size="small"
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          isIcon
          icon={<UserAvatar initials={initials} />}
          tip={display}
        />
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="profile-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        // keepMounted
        PaperProps={{
          sx: {
            width: 320,
            mt: 1,
            "& .MuiMenuItem-root": {
              py: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box
          sx={{
            alignItems: "center",
            p: 2,
            display: "flex",
          }}
        >
          <UserAvatar initials={initials} size={40} />
          <Box
            sx={{
              ml: 1,
            }}
          >
            <Typography variant="body1">{full}</Typography>
            <Typography color="textSecondary" variant="body2">
              {email}
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ mb: 1 }} />
        <MenuItem>
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">Account</Typography>}
          />
        </MenuItem>
        <MenuItem
          component={Link}
          to="admin/customization"
          sx={sxIsHidden(isAdmin && showSettings, { display: "flex" })}
        >
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">Settings</Typography>}
          />
        </MenuItem>
        {!isPersonalDashboard && <AccountsMenu handleMenuClose={handleClose} />}
        <MenuItem>
          <ListItemIcon>
            <HelpOutlineIcon />
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">Help</Typography>}
          />
        </MenuItem>

        <Divider />
        <MenuItem onClick={() => handleLogout()}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">Logout</Typography>}
          />
        </MenuItem>
      </Menu>
    </>
  );
}
