import { useMemo } from "react";
import { DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

// Dialog Header for general use with close button and colored header

export default function DialogHeader(props) {
  const { children, onClose, statusColor, sxProps = {}, ...other } = props;

  const { bgCol, col } = useMemo(
    () => ({
      bgCol: statusColor ? `${statusColor}.main` : "inherit",
      col: statusColor ? `${statusColor}.contrastText` : "inherit",
    }),
    [statusColor]
  );

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        bgcolor: bgCol,
        color: col,
        position: "relative",
        ...sxProps,
      }}
      {...other}
    >
      {/* <Typography variant="h6">{children}</Typography> */}
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: col,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
