import { createSlice } from "@reduxjs/toolkit";
import {
  reMapItems,
  addBuilderItem,
  primaryBuilderItem,
} from "pages/AudienceManagement/AudienceBuilder/builderModel";

const initBuilderMode = {
  builderItems: [{ ...primaryBuilderItem }],
  builderName: "",
  id: null,
  status: "NEW",
  accountId: null,
};

const initialState = {
  dataShow: true,
  calcShow: false,
  builderState: {},
  defaultState: {},
  isBuilderTouched: false,
  lastState: {},
  builderModel: initBuilderMode,
};

const slice = createSlice({
  name: "audience_builder",
  initialState,
  reducers: {
    // Update DataShow
    updateDataShow(state, action) {
      state.dataShow = action.payload;
    },
    // Update Calculator Display
    updateCalcShow(state, action) {
      state.calcShow = action.payload;
    },
    // Remove Builder Item
    removeBuilderItem(state, action) {
      const newItems = state.builderModel.builderItems.filter(
        (item) => item.id !== action.payload
      );
      state.builderModel.builderItems = reMapItems(newItems);
    },
    // Insert Builder Item
    insertBuilderItem(state, action) {
      const prevIdx = action.payload - 1;
      // const isLast = prevIdx !== 0 && items.length === idx;
      const isLast = state.builderModel.builderItems[prevIdx]?.isLast;
      let copyItems = [...state.builderModel.builderItems];
      if (isLast) {
        copyItems = copyItems.map((item, index) => {
          if (index !== action.payload) {
            return item;
          }
          return {
            ...item,
            ...{ exclude: false },
          };
        });
      }
      const newItem = addBuilderItem(action.payload);
      copyItems.splice(action.payload, 0, newItem);
      state.builderModel.builderItems = reMapItems(copyItems);
    },
    // Update Builder Items
    updateBuilderItems(state, action) {
      const { idx, vals } = action.payload;
      state.builderModel.builderItems = state.builderModel.builderItems.map(
        (item, index) => {
          if (index !== idx) {
            return item;
          }
          return {
            ...item,
            ...vals,
          };
        }
      );
    },
    // Update Builder Name
    updateBuilderName(state, action) {
      state.builderModel.builderName = action.payload;
    },
    // Update Account Id
    updateAccountId(state, action) {
      state.builderModel.accountId = action.payload.accountId;
    },
    // Clear Builder Items
    initBuilderState(state, action) {
      const { newState } = action.payload;
      state.builderModel.builderItems = [{ ...primaryBuilderItem }];
      state.builderModel.builderName = "";
      state.builderState = newState;
    },
    changeBuilderName(state, action) {
      state.builderModel.builderName = action.payload;
    },
  },
});

export const { reducer } = slice;

export const updateDataShow = (data) => async (dispatch) => {
  dispatch(slice.actions.updateDataShow(data));
};

export const updateCalcShow = (data) => async (dispatch) => {
  dispatch(slice.actions.updateCalcShow(data));
};

export const removeBuilderItem = (data) => async (dispatch) => {
  dispatch(slice.actions.removeBuilderItem(data));
};

export const insertBuilderItem = (data) => async (dispatch) => {
  dispatch(slice.actions.insertBuilderItem(data));
};

export const updateBuilderItems = (data) => async (dispatch) => {
  dispatch(slice.actions.updateBuilderItems(data));
};

export const updateBuilderName = (data) => async (dispatch) => {
  dispatch(slice.actions.updateBuilderName(data));
};

export const updateAccountId = (data) => async (dispatch) => {
  dispatch(slice.actions.updateAccountId(data));
};

export const initBuilderState = (data) => async (dispatch) => {
  dispatch(slice.actions.initBuilderState(data));
};
