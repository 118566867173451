import GroupIcon from "@mui/icons-material/Group";
import GroupsIcon from "@mui/icons-material/Groups";
import AnalyticsIcon from "@mui/icons-material/Analytics";

export default function audienceManagementMenuItems(t, role, isAccountActive) {
  const audienceManagementAudiences = {
    title: t("Audiences"),
    id: "audience-management.audiences",
    path: "/audience-management/audiences",
    isDefault: true,
    icon: <GroupIcon />,
    visible: true,
  };
  const audienceManagementInsights = {
    title: t("Insights"),
    id: "audience-management.insights",
    path: "/audience-management/insights",
    icon: <AnalyticsIcon />,
    visible: true,
  };
  const audienceManagementBuilder = {
    title: t("Audience Builder"),
    id: "audience-management.audience-builder",
    path: "/audience-management/audience-builder",
    icon: <GroupsIcon />,
    visible: isAccountActive,
  };
  const audienceManagementSubItems = {
    admin: [
      audienceManagementAudiences,
      audienceManagementInsights,
      audienceManagementBuilder,
    ],
    power_user: [
      audienceManagementAudiences,
      audienceManagementInsights,
      audienceManagementBuilder,
    ],
    general_user: [
      audienceManagementAudiences,
      audienceManagementInsights,
      audienceManagementBuilder,
    ],
    data_scientist: [
      audienceManagementAudiences,
      audienceManagementInsights,
      audienceManagementBuilder,
    ],
    view_only: [audienceManagementAudiences],
  };

  return {
    title: t("Audience Management"),
    id: "audience-management",
    items: audienceManagementSubItems[role],
  };
}
