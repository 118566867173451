import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";

export default function Header({ columnSpacing }) {
  const { t } = useTranslation();

  return (
    <Grid columnSpacing={columnSpacing} container item>
      <Grid item xs={4} />
      <Grid item xs={4}>
        {t("Matched Individuals")}
      </Grid>
      <Grid item xs={4}>
        {t("Matched Records")}
      </Grid>
    </Grid>
  );
}
