import { Link } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";

export default function LinkWrapper({
  href = "",
  isBlank = true,
  isIcon = true,
  children,
  linkProps = { variant: "body2" },
}) {
  const retProps = isBlank
    ? { target: "_blank", rel: "noopener", ...linkProps }
    : { ...linkProps };
  return (
    <Link href={href} {...retProps}>
      {children}
      {isIcon && (
        <LaunchIcon
          sx={{
            marginLeft: "4px",
            fontSize: "inherit",
            fontWeight: 500,
          }}
          color="action"
        />
      )}
    </Link>
  );
}
