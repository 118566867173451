import { useCallback, useMemo } from "react";
import { set } from "lodash";
import useLocalStorage from "../../hooks/useLocalStorage";

export default function useCustomizationStorage(key, initialState) {
  const [storage, setStorage] = useLocalStorage(key, {
    columns: { columnVisibilityModel: initialState.columnVisibilityModel },
    pageSize: initialState.pageSize,
    density: initialState.density,
  });

  const customizationStorage = useMemo(() => storage, [storage]);

  const setCustomizationStorage = useCallback(
    (type, newValue) =>
      setStorage((previous) => ({ ...set(previous, type, newValue) })),
    []
  );

  return [customizationStorage, setCustomizationStorage];
}
