import { useTranslation } from "react-i18next";
import Page from "elements/Page";
import useQueryAccounts from "queries/useQueryAccounts";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ClientAccountsSelectionGrid from "./ClientAccountsSelectionGrid";
import SearchBar from "./SearchBar";
import useQueryRole from "../../layout/MainLayout/Sidebar/useQueryRole";
import { setRole } from "../../slices/role";
import axios from "../../api/axios";
import useAccount from "../../contexts/accountContext";
import { keysToCamel } from "../../utils";

export default function PersonalDashboard() {
  const [searchableClientAccounts, setSearchableClientAccounts] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { accountId, activateAccount, activeAccount } = useAccount();
  const accountKey = activeAccount?.accountKey;

  const onChangeSearchText = (searchText) => {
    if (!searchText) setSearchResults(searchableClientAccounts);
    const newSearch = searchableClientAccounts.filter((x) =>
      x.clientAccountName.toLowerCase().includes(searchText.toLowerCase())
    );
    setSearchResults(newSearch);
  };

  const { data: role } = useQueryRole({
    accountId,
    queryProps: {
      enabled: !!accountId,
    },
  });

  useEffect(() => {
    if (accountKey) {
      // BE needs it in every request (except for roles and client accounts) to check for permissions
      // It's because the role is associated with the client account selected
      axios.defaults.headers.common.account_key = accountKey;
    }
  }, [activeAccount]);

  useEffect(() => {
    if (role) {
      dispatch(setRole(role));
    }
  }, [role]);

  const {
    data: clientAccounts,
    isLoading,
    error,
  } = useQueryAccounts({
    select: useCallback(
      (data) =>
        data.map(({ advertiser, brand, ...account }) =>
          keysToCamel({
            ...account,
            clientAccountName: `${advertiser} - ${brand}`,
          })
        ),
      []
    ),
  });

  useEffect(() => {
    if (clientAccounts) {
      setSearchableClientAccounts(clientAccounts);
      setSearchResults(clientAccounts);
    }
  }, [clientAccounts.length]);

  return (
    <Page title={t("Customer Dashboard")}>
      <SearchBar onChange={onChangeSearchText} />
      <div style={{ margin: "10px 0 0 30px", fontSize: ".775rem" }}>
        Choose a customer below:
      </div>
      <ClientAccountsSelectionGrid
        error={error}
        clientAccounts={searchResults}
        setSelectedAccount={activateAccount}
        isLoading={isLoading}
      />
    </Page>
  );
}
