import { useTranslation } from "react-i18next";
import {
  Box,
  List as ListMUI,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";

import {
  ErrorOutline,
  ReportProblemOutlined,
  InfoOutlined,
  CheckCircleOutlined,
  Close,
} from "@mui/icons-material";
import BluButton from "elements/Button/BluButton";

function getIcon(type) {
  let Icon;
  switch (type) {
    case "error":
      Icon = ErrorOutline;
      break;
    case "warning":
      Icon = ReportProblemOutlined;
      break;
    case "info":
      Icon = InfoOutlined;
      break;
    case "success":
      Icon = CheckCircleOutlined;
      break;
    default:
      return null;
  }

  return <Icon color={type} fontSize="medium" />;
}

// this needs to be configurable for different contexts/ use router link
function getNotificationContent(notification) {
  return (
    <>
      <Box
        sx={{
          mt: 0.5,
          mr: 2,
          display: "flex",
          alignSelf: "center",
        }}
      >
        {getIcon(notification.type)}
      </Box>
      <ListItemText
        primary={
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography sx={{ mr: 0.5 }} variant="subtitle1">
              {notification.title}
            </Typography>
            <Typography variant="subtitle2">
              {notification.description}
            </Typography>
          </Box>
        }
        secondary={
          <Typography color="textSecondary" variant="caption">
            {notification.createdAt}
          </Typography>
        }
        sx={{ my: 0 }}
      />
    </>
  );
}

export default function List({ notifications, handleRemoveOne, handleRead }) {
  const { t } = useTranslation();
  return (
    <Box sx={{ maxHeight: 400 }}>
      <ListMUI disablePadding>
        {notifications.map((notification) => (
          <ListItem
            onClick={() => handleRead(notification.id)}
            divider
            key={notification.id}
            sx={{
              backgroundColor: notification.read ? "inherit" : "action.unread",
              alignItems: "flex-start",
              "&:hover": {
                backgroundColor: "action.hover",
              },
              "& .MuiListItemSecondaryAction-root": {
                top: "24%",
              },
            }}
            secondaryAction={
              <BluButton
                tip={t("Remove")}
                size="small"
                edge="end"
                icon={<Close fontSize="small" />}
                isIcon
                onClick={() => handleRemoveOne(notification.id)}
                sx={{
                  color: "text.secondary",
                }}
              />
            }
          >
            {getNotificationContent(notification)}
          </ListItem>
        ))}
      </ListMUI>
    </Box>
  );
}
