import { Box, Typography } from "@mui/material";

export default function IconWithText({ Icon, text }) {
  return (
    <Box display="flex" alignItems="center">
      <Icon
        sx={{
          color: "neutral.500",
        }}
      />
      <Typography ml="10px">{text}</Typography>
    </Box>
  );
}
