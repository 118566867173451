import FormControlLabel from "@mui/material/FormControlLabel";
import { Radio, RadioGroup } from "@mui/material";
import { BOTH, COMBINE_AUDIENCE, UNIQUE_AUDIENCE } from "constants/general";

export default function RadioGroupScaling({
  selectedOptionValue,
  setSelectedOptionValue,
}) {
  return (
    <RadioGroup
      aria-labelledby="radio-buttons-group-audiences-label"
      defaultValue={BOTH}
      name="radio-buttons-group-audiences"
      value={selectedOptionValue}
      onChange={(event) => setSelectedOptionValue(event.target.value)}
      sx={{
        "& .MuiFormControlLabel-root": {
          height: "34px",
        },
      }}
    >
      <FormControlLabel
        value={COMBINE_AUDIENCE}
        control={<Radio />}
        label="Combine Audience"
      />
      <FormControlLabel
        value={UNIQUE_AUDIENCE}
        control={<Radio />}
        label="Unique Audiences"
      />
      <FormControlLabel value={BOTH} control={<Radio />} label="Both" />
    </RadioGroup>
  );
}
