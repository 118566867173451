import axios from "../axios";

const context = "/segments/overlap_analysis";

export default {
  post: async ({ compareSegmentId, mainSegmentId }) => {
    try {
      const response = await axios.post(context, {
        main_segment_id: mainSegmentId,
        compare_segment_id: compareSegmentId,
      });
      return response?.data?.results;
    } catch (e) {
      const errorMessages = { error: e?.message };
      throw errorMessages;
    }
  },
};
