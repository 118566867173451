import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Box, Drawer, Divider, useMediaQuery, Typography } from "@mui/material";
import useAccount from "contexts/accountContext";
import Scrollbar from "elements/Scrollbar";
import BluLogo from "assets/BluLogo";
import ListSection from "elements/Menu/ListSection";
import { getMenuItems } from "config/menu";
import BluButton from "elements/Button/BluButton";
import { DevMenu } from "../DevMenu";

const documentationLink =
  "https://hminc.sharepoint.com/sites/blu2/SitePages/blu.-Playbook---HOME.aspx";

function Sidebar(props) {
  const { onClose, open, isDevelopment, role } = props;
  const { isAccountActive } = useAccount();
  const { t } = useTranslation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"), {
    noSsr: true,
  });

  const menuSections = useMemo(
    () => getMenuItems(t, role?.role, isAccountActive),
    [t, role?.role, isAccountActive]
  );

  const content = (
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          height: "100%",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
        onClick={() => (lgUp ? null : onClose())}
      >
        <div>
          <Box sx={{ pt: 2, pl: 3 }}>
            <Link to="/personal-dashboard">
              {" "}
              <BluLogo
                sx={{ width: 60, height: 30 }}
                viewBox="0 0 102 49"
                htmlColor="white"
              />
            </Link>
          </Box>
        </div>
        <Divider
          sx={{
            borderColor: "#2D3748",
            mt: 3,
            mb: 3,
          }}
        />
        <Box sx={{ flexGrow: 1 }}>
          {menuSections.map((section) => (
            <ListSection
              key={section.id}
              title={section.title}
              items={section.items}
              id={section.id}
            />
          ))}
          {isDevelopment && <DevMenu />}
        </Box>
        <Divider
          sx={{
            borderColor: "#2D3748",
          }}
        />
        <Box sx={{ p: 2 }}>
          <Typography color="neutral.100" variant="subtitle2">
            {t("Need Help?")}
          </Typography>
          <Typography color="neutral.500" variant="body2">
            {t("Check our docs")}
          </Typography>
          <BluButton
            href={documentationLink}
            sx={{ my: 2 }}
            fullWidth
            target="_blank"
          >
            {t("Documentation")}
          </BluButton>
        </Box>
      </Box>
    </Scrollbar>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "neutral.900",
            borderRightColor: "divider",
            borderRightStyle: "solid",
            borderRightWidth: (theme) =>
              theme.palette.mode === "dark" ? 1 : 0,
            color: "#FFFFFF",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "neutral.900",
          color: "#FFFFFF",
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
}

export default Sidebar;
