import {
  compareAlphanumericStrings,
  convertJSONDateForTableDisplay,
} from "utils";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import ErrorIcon from "@mui/icons-material/Error";
import CheckIcon from "@mui/icons-material/Check";
import { Box } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

const columnDefaults = {
  type: "string",
  sortable: true,
  align: "left",
  headerAlign: "left",
};

function getStatusColor(status) {
  const statuses = {
    processing: <HourglassTopIcon color="warning" />,
    completed: <CheckIcon color="secondary" />,
    error: <ErrorIcon color="error" />,
  };
  return statuses[status] || statuses.processing;
}

const getDistributionColumns = (defaults = columnDefaults) => [
  {
    ...defaults,
    field: "distribution_id",
    headerName: "Distribution ID",
    width: 130,
  },
  {
    ...defaults,
    field: "audience_id",
    headerName: "Audience ID",
    minWidth: 130,
    flex: 1,
  },
  {
    ...defaults,
    field: "powered_by",
    headerName: "Powered By",
    minWidth: 130,
    hide: true,
    flex: 1,
  },
  {
    ...defaults,
    field: "audience_name",
    headerName: "Audience Name",
    minWidth: 150,
    flex: 1,
    sortComparator: compareAlphanumericStrings,
    renderCell: (params) => (
      <Box sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>
        <Tooltip title={params.value}>
          <span>{params.value}</span>
        </Tooltip>
      </Box>
    ),
  },
  {
    ...defaults,
    field: "source",
    headerName: "Source",
    minWidth: 130,
    flex: 1,
  },
  {
    ...defaults,
    field: "created_at",
    headerName: "Created",
    type: "date",
    valueGetter: (params) => convertJSONDateForTableDisplay(params.value),
    width: 110,
  },
  {
    ...defaults,
    field: "refreshed_at",
    headerName: "Refresh Date",
    type: "date",
    valueGetter: (params) => convertJSONDateForTableDisplay(params.value),
    width: 125,
  },
  {
    ...defaults,
    field: "distributed_at",
    headerName: "Distributed Date",
    type: "date",
    valueGetter: (params) => convertJSONDateForTableDisplay(params.value),
    width: 150,
  },
  {
    ...defaults,
    field: "status",
    headerName: "Status",
    align: "center",
    headerAlign: "center",
    width: 70,
    renderCell: (params) => getStatusColor(params.value),
  },
  {
    ...defaults,
    editable: true,
    field: "nickname",
    headerName: "Nickname",
    minWidth: 120,
    flex: 5,
    sortComparator: compareAlphanumericStrings,
  },
  {
    ...defaults,
    field: "partner_name",
    headerName: "Partner",
    minWidth: 95,
    flex: 1,
    sortComparator: compareAlphanumericStrings,
  },
  {
    ...defaults,
    field: "ad_account_id",
    headerName: "AD Account ID",
    type: "number",
    minWidth: 150,
    flex: 2,
  },
  {
    ...defaults,
    field: "size",
    headerName: "Size",
    type: "number",
    width: 95,
    align: "left",
    headerAlign: "left",
  },
  {
    ...defaults,
    field: "live_ramp_id",
    headerName: "External Segment ID",
    width: 125,
  },
];

const getPartnerConnectionColumns = (defaults = columnDefaults) => [
  {
    ...defaults,
    field: "id",
    headerName: "ID",
    minWidth: 50,
    flex: 1,
  },
  {
    ...defaults,
    editable: true,
    field: "nickname",
    headerName: "Nickname",
    minWidth: 95,
    flex: 5,
    sortComparator: compareAlphanumericStrings,
  },
  {
    ...defaults,
    field: "partner_name",
    headerName: "Partner Name",
    minWidth: 120,
    flex: 5,
    sortComparator: compareAlphanumericStrings,
  },
  {
    ...defaults,
    field: "destination_account_id",
    headerName: "Destination Account ID",
    type: "number",
    minWidth: 180,
    flex: 3,
  },
  {
    ...defaults,
    field: "ad_account_id",
    headerName: "AD Account ID",
    type: "number",
    minWidth: 120,
    flex: 2,
  },
  {
    ...defaults,
    field: "client_name",
    headerName: "Client Name",
    minWidth: 105,
    flex: 2,
    sortComparator: compareAlphanumericStrings,
  },
  {
    ...defaults,
    editable: true,
    field: "account_representative_name",
    headerName: "Account Rep. Name",
    minWidth: 155,
    flex: 4,
    sortComparator: compareAlphanumericStrings,
  },
  {
    ...defaults,
    editable: true,
    field: "account_representative_email",
    headerName: "Account Rep. Email",
    minWidth: 155,
    flex: 4,
    sortComparator: compareAlphanumericStrings,
  },
  {
    ...defaults,
    field: "powered_by",
    headerName: "Powered By",
    minWidth: 105,
    flex: 1,
  },
];

const getAudienceColumns = (defaults = columnDefaults) => [
  {
    ...defaults,
    field: "id",
    headerName: "ID",
    flex: 1,
    minWidth: 140,
    maxWidth: 300,
  },
  {
    ...defaults,
    field: "name",
    headerName: "Audience Name",
    sortable: false,
    flex: 1,
    minWidth: 140,
  },
  {
    ...defaults,
    field: "created_at",
    headerName: "Created",
    type: "date",
    valueGetter: (params) => convertJSONDateForTableDisplay(params.value),
    width: 130,
  },
  {
    ...defaults,
    field: "updated_at",
    headerName: "Refresh date",
    type: "date",
    valueGetter: (params) => convertJSONDateForTableDisplay(params.value),
    width: 130,
  },
  {
    ...defaults,
    field: "source",
    headerName: "Source",
    width: 130,
  },
];

export {
  getDistributionColumns,
  getPartnerConnectionColumns,
  getAudienceColumns,
};
