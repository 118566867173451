import { Box, Card, Container, Divider, Typography } from "@mui/material";
import Authentication from "./Authentication";
import BluLogo from "../../assets/BluLogo";

function Login() {
  return (
    <Box
      component="main"
      sx={{
        backgroundColor: "background.default",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <Container maxWidth="sm">
        <Card elevation={16} sx={{ p: 4 }}>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <BluLogo
              sx={{ width: 102, height: 49 }}
              viewBox="0 0 102 49"
              htmlColor="#0082CA"
            />
            <Typography variant="h5" mt={2} mb={2}>
              Log in
            </Typography>
            <Typography fontSize={14} color="text.secondary">
              Sign in with your corporate ID
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 3,
            }}
          >
            <Authentication />
          </Box>
          <Divider sx={{ my: 3 }} />
        </Card>
      </Container>
    </Box>
  );
}

export default Login;
