/* eslint-disable no-console */
import { useMemo, useState } from "react";
import { Box } from "@mui/material";
import useQueryAudiences from "queries/useQueryAudiences";
import { getTableChange } from "components/AudiencesDataGrid/helpers";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useMemoizedCallback } from "../../../../hooks";
import BluDataGrid from "../../../../components/DataGrid/BluDataGrid";
import SegmentDrawer from "./SegmentDrawer";
import getTableColumns from "./tableColumns";
import useAccount, {
  AccountWrapper,
} from "../../../../contexts/accountContext";
import ActionsHeader from "../ActionsHeader";
import { ACCOUNT } from "../../../../constants/general";

const isDebug = false;

export default function SegmentsTable({
  setSelectedAudiences,
  tableState,
  tableChange,
  storage,
  setStorage,
  segmentIds,
}) {
  const [showInfoDrawer, setShowInfoDrawer] = useState({
    active: false,
    segment: null,
  });

  const { activeAccount } = useAccount();

  const { tableData, pageTotalSize, isValid, isLoading, isRefetching, error } =
    useQueryAudiences(tableState, "AUDIENCES");

  const onOpenInfoDrawer = useMemoizedCallback((segment) => {
    setShowInfoDrawer({ active: true, segment });
  });

  const columns = useMemo(
    () => getTableColumns(onOpenInfoDrawer),
    [onOpenInfoDrawer]
  );

  const tableDispatch = useMemoizedCallback(
    (type, newState, isFromTable = false) => {
      if (isDebug) console.log("State change", type, newState, tableState);
      const ret = isFromTable ? newState : getTableChange(type, newState);
      tableChange(ret);
    }
  );
  // problem here is will overide stored pageSize
  const onAccountChange = useMemoizedCallback((act) => {
    if (isDebug) console.log("ON ACCOUNT CHANGE", act);
    const actId = act ? act.id : null;
    tableDispatch(ACCOUNT, actId);
  });

  const onCloseDrawer = useMemoizedCallback(() => {
    setShowInfoDrawer({ active: false, segment: null });
  });

  const getRowOnSelection = useMemoizedCallback((ids) => {
    const selectedIDs = new Set(ids);
    const selectedRowData = tableData.filter((row) =>
      selectedIDs.has(row.id.toString())
    );
    setSelectedAudiences(selectedRowData);
  });

  const CustomToolbar = useMemoizedCallback(() => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport
        csvOptions={{
          fileName: `${activeAccount.clientAccountName}_audience`,
        }}
      />
    </GridToolbarContainer>
  ));

  return (
    <AccountWrapper onAccountChange={onAccountChange}>
      {({ isAccountActive }) => (
        <>
          <Box sx={{ p: 0 }}>
            <ActionsHeader
              tableDispatch={tableDispatch}
              tableState={tableState}
              activeAccount={activeAccount}
              isAccountActive={isAccountActive}
            />
            <BluDataGrid
              customizationStorage={storage}
              handleCustomizationStorageChange={setStorage}
              tableState={tableState}
              tableDispatch={tableDispatch}
              tableData={tableData}
              isValid={isValid}
              error={error}
              isLoading={isLoading || isRefetching}
              columns={columns}
              pageTotalSize={pageTotalSize}
              density={storage.density}
              onSelectionModelChange={(ids) => getRowOnSelection(ids)}
              selectionModel={segmentIds}
              disableColumnFilter={false}
              components={{ Toolbar: CustomToolbar }}
            />
          </Box>
          {showInfoDrawer.segment?.id && (
            <SegmentDrawer
              segmentId={showInfoDrawer.segment?.id}
              title={showInfoDrawer.segment?.name}
              open={showInfoDrawer.active}
              onClose={onCloseDrawer}
            />
          )}
        </>
      )}
    </AccountWrapper>
  );
}
