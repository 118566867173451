// Query names
const QUERY_KEYS = {
  ACCOUNTS: "ACCOUNTS",
  FOLDERS: "FOLDERS",
  SEGMENTS_LIST: "SEGMENTS_LIST",
  NOTIFICATIONS: "NOTIFICATIONS",
  GET_TAXONOMIES: "GET_TAXONOMIES",
  SEARCH_TAXONOMIES: "SEARCH_TAXONOMIES",
  LIST_DATA: "LIST_DATA",
  SEGMENT_DETAILS: "SEGMENT_DETAILS",
  ADMIN_CUSTOMIZATION: "ADMIN_CUSTOMIZATION",
  RUN_OVERLAP_ANALYSIS: "RUN_OVERLAP_ANALYSIS",
  DISTRIBUTIONS: "DISTRIBUTIONS",
  PARTNER_CONNECTIONS: "PARTNER_CONNECTIONS",
  PARTNERS: "PARTNERS",
  ROLE: "ROLE",
};

export const getSegmentsListKey = (params, prepend) => {
  const keyName = prepend
    ? `${prepend}_${QUERY_KEYS.SEGMENTS_LIST}`
    : QUERY_KEYS.SEGMENTS_LIST;
  return params ? [keyName, params] : keyName;
};

export default QUERY_KEYS;
