import axios from "../axios";

const context = "segments/calculate";

export default {
  post: async (postObj) => {
    try {
      const response = await axios.post(context, postObj);
      return response?.data;
    } catch (e) {
      throw e?.response?.data;
    }
  },
};
