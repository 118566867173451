import { useMutation } from "react-query";
import apiOverlapAnalysis from "api/segments/overlapAnalysis";
import { useActionAlerter } from "components/Alert";

export function useOverlapAnalysisMutations() {
  const { start, complete } = useActionAlerter(
    "Overlap data is being calculated"
  );

  return useMutation(apiOverlapAnalysis.post, {
    onMutate: async () => {
      start();
    },
    onSettled: async (data, error) => {
      const isSuccess = !error;
      complete(isSuccess, `Description: ${error?.message}`);
    },
  });
}
