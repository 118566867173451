import { useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

export default function SearchBar({ onChange }) {
  const [searchText, setSearchText] = useState("Search Customers");
  const onChangeSearchText = (event) => {
    setSearchText(event.target.value);
    onChange(event.target.value);
  };

  return (
    <div style={{ width: "100%", backgroundColor: "white", padding: "30px" }}>
      <OutlinedInput
        style={{ width: "100%" }}
        id="input-with-icon-adornment"
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        value={searchText}
        onClick={() => setSearchText("")}
        onChange={(event) => onChangeSearchText(event)}
      />
    </div>
  );
}
