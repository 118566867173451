import { useMemo } from "react";
import ConfirmDialog from "./ConfirmDialog";

const DEFAULT_OPTIONS = {
  content: "Are you sure you want to delete this item?",
  title: "Delete",
  confirmText: "Delete",
  confirmColor: "error",
};

const getProps = (props) => ({
  ...DEFAULT_OPTIONS,
  ...props,
});

// delete dialog: based on activeValue - open, confirm, reset close
// allow controlled close versus confirm (for async completion) - isCloseOnConfirm
export default function DeleteDialog(props) {
  const {
    activeValue,
    resetActiveValue,
    isCloseOnConfirm = true,
    onConfirm,
    onCancel,
    ...confirmProps
  } = props;
  const mergeProps = getProps(confirmProps);
  const isShow = useMemo(() => Boolean(activeValue), [activeValue]);
  const handleClose = () => {
    if (onCancel) onCancel();
    if (resetActiveValue) resetActiveValue(null);
  };

  const handleConfirm = (val) => {
    onConfirm(val);
    if (isCloseOnConfirm) handleClose();
  };

  return (
    <ConfirmDialog
      isOpen={isShow}
      onCancel={handleClose}
      confirmValue={activeValue}
      onConfirm={handleConfirm}
      {...mergeProps}
    />
  );
}
