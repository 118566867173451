import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Page from "elements/Page";
import CreateButton from "elements/Button/CreateButton";
import useAccount from "contexts/accountContext";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import AddNewPartnerConnection from "./AddNewPartnerConnection";
import useQueryPartners from "./useQueryPartners";
import { keysToCamel } from "../../../utils";
import { useConnectionsMutations } from "./useConnectionsMutations";
import RoleBasedGuard from "../../../components/Guard/RoleBasedGuard";
import { actions, features, targets } from "../../../constants/roles";
import useRole from "../../../components/Guard/useRole";
import PartnerConnectionsDataGrid from "./PartnerConnectionsDataGrid";
import { useMemoizedCallback } from "../../../hooks";

export default function PartnerConnections() {
  const { t } = useTranslation();
  const { accountId } = useAccount();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState(""); // Partner name (displayed in the autocomplete after selection or typing)
  const [partnerValue, setPartnerValue] = useState(null); // Partner object
  const [connectionParameters, setConnectionParameters] = useState([]);
  const { checkPermission } = useRole();
  const { role } = useRole();
  const roleName = role?.role;

  const canAccessPartnerConnections = checkPermission(
    features.partnerConnections,
    actions.view,
    targets.pages
  );

  useEffect(() => {
    if (partnerValue?.connectionParameters?.length) {
      setConnectionParameters(partnerValue?.connectionParameters);
    } else {
      setConnectionParameters([]);
    }
  }, [partnerValue]);

  const { data: partners, isLoading: isLoadingPartners } = useQueryPartners({
    accountId,
    queryOptions: {
      select: (data) => keysToCamel(data),
      enabled: isOpenDialog,
    },
  });

  const { mutate: addPartnerConnection, isLoading: isAddingPartnerConnection } =
    useConnectionsMutations({
      accountId,
    });

  const clear = () => {
    setIsOpenDialog(false);
    setSelectedPartner("");
    setPartnerValue(null);
  };

  const onConfirm = (values) => {
    const parameters = Object.entries(values).map(([key, value]) => ({
      // Using field name to create the field id. Potential problem in case they don't match in the future
      field_id: key.toLowerCase().replaceAll(" ", "_"),
      field_name: key,
      field_value: value,
    }));
    addPartnerConnection(
      {
        accountId,
        partnerId: partnerValue.partnerId,
        connectionParameters: parameters,
      },
      {
        onSettled: () => {
          clear();
        },
      }
    );
  };
  const { activeAccount } = useAccount();
  const CustomToolbar = useMemoizedCallback(() => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport
        csvOptions={{
          fileName: `${activeAccount.clientAccountName}_partner_connections`,
        }}
      />
    </GridToolbarContainer>
  ));

  // Checking role just for the page, since the permissions for viewing available partners
  // and for creating new connection params are the same
  return (
    <RoleBasedGuard
      feature={features.partnerConnections}
      action={actions.view}
      target={targets.pages}
      sx={{
        px: 3,
        py: 2,
      }}
    >
      <Page
        title={t("Partner Connections")}
        containerProps={{ sx: { mt: 3, mb: 3, height: "80%" } }}
        actions={
          <CreateButton
            id="addPartnerConnections"
            title={t("Add New Partner Connection")}
            tip={t("Add new partner connection wizard")}
            onClick={() => setIsOpenDialog(true)}
          />
        }
      >
        <PartnerConnectionsDataGrid
          accountId={accountId}
          canAccess={canAccessPartnerConnections}
          roleName={roleName}
          components={{ Toolbar: CustomToolbar }}
        />
        <AddNewPartnerConnection
          title={t("Add Partner Connection")}
          confirmText={t("Create New Connection")}
          isOpen={isOpenDialog}
          onCancel={clear}
          onConfirm={onConfirm}
          searchOptions={partners}
          isLoading={isLoadingPartners}
          searchValue={selectedPartner}
          partnerValue={partnerValue}
          roleName={roleName}
          connectionParameters={connectionParameters}
          confirmButtonProps={{
            disabled: isAddingPartnerConnection || !partnerValue,
          }}
          autocompleteProps={{
            getOptionLabel: (option) => option.name,
          }}
          handleInputChange={(newInputValue) => {
            setSelectedPartner(newInputValue);
          }}
          handleSelectChange={(newInputValue) => {
            setPartnerValue(newInputValue);
          }}
        />
      </Page>
    </RoleBasedGuard>
  );
}
