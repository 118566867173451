import { useTranslation } from "react-i18next";
import { Box, Divider, Typography, Paper } from "@mui/material";
import numbro from "numbro";
import Page from "elements/Page";
import { useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import GroupsIcon from "@mui/icons-material/Groups";
import BluButton from "elements/Button/BluButton";
import { BOTH } from "constants/general";
import ScaleSegmentTable from "./ScaleSegmentTable";
import ScalingForm from "./ScalingForm";

// Size and seed segment should come from some previous step in this process, or from the segment API
const seedSegmentName = "THC_LilysChocolate_WeightLoss-ChocolateBuyer";
const size = 14000000;

// Parts of this initial data should be updated after scaling the segment with the slider, with
// the data received from API.
const initialData = [
  {
    id: Date.now() + Math.random().toString(),
    probability: [75, 100],
    audience_count: 7543295,
    incremental_audience: 1.54,
  },
  {
    id: Date.now() + Math.random().toString(),
    probability: [60, 65],
    audience_count: 20352379,
    incremental_audience: 1.74,
  },
  {
    id: Date.now() + Math.random().toString(),
    probability: [50, 60],
    audience_count: 11927298,
    incremental_audience: 1.89,
  },
];

export default function AudienceScaling() {
  const { t } = useTranslation();
  const [tableData, setTableData] = useState(initialData);
  const [selectedSegments, setSelectedSegments] = useState([]);
  const [openModalCreateAudience, setOpenModalCreateAudience] = useState(false); // modal toggle
  const [selectedOptionValue, setSelectedOptionValue] = useState(BOTH); // radio value
  const segmentIds = selectedSegments?.map((audience) => audience.id);
  const isButtonDisabled =
    selectedSegments.length < 1 || selectedSegments.length > 5;
  const isAddDisabled = tableData.length >= 5;
  const defaultRootName = `${seedSegmentName}-LAL_`;

  function handleAdd() {
    if (tableData.length >= 5) {
      return;
    }
    setTableData((prevState) => [
      ...prevState,
      {
        // Generating a random ID for mocking purpose
        id: Date.now() + Math.random().toString(),
        probability: [50, 100],
        audience_count: 7543295,
        incremental_audience: 1.54,
      },
    ]);
  }

  const handleCloseModal = () => {
    setOpenModalCreateAudience(false);
    setSelectedSegments([]);
    setSelectedOptionValue(BOTH);
  };

  // eslint-disable-next-line no-unused-vars
  const onConfirm = (values) => {
    // Use "values" to send the data to the API
  };

  return (
    <Page title={t("Audience Scaling")}>
      <Paper elevation={2}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            p: 1,
            m: 1,
            bgcolor: "background.paper",
            borderRadius: 1,
          }}
        >
          <Box p={3}>
            <Typography variant="h6" mb={1}>
              {t("Seed Segment:")}
            </Typography>
            <Typography variant="h5">{seedSegmentName}</Typography>
            <Typography variant="h6">{`Size: ${numbro(
              size
            ).format()} Individuals`}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              marginTop: "110px",
              width: "100%",
            }}
          >
            <BluButton
              onClick={() => setOpenModalCreateAudience(true)}
              color="primary"
              tip="Create audiences"
              disabled={isButtonDisabled}
            >
              {t("Create Audiences")}
              <GroupsIcon sx={{ marginLeft: "9px" }} />
            </BluButton>
          </Box>
        </Box>
        <Divider />
        <Box
          pt={5}
          pl={3}
          pb={2}
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6" mb={1}>
            {t("Scale")}
          </Typography>
          <BluButton
            onClick={() => handleAdd()}
            color="primary"
            tip="Add slider"
            isIcon
            disabled={isAddDisabled}
            icon={
              <AddCircleIcon
                disabled
                sx={{
                  width: "30px",
                  height: "30px",
                }}
              />
            }
            sx={{
              marginRight: "20px",
            }}
          />
        </Box>
        <ScaleSegmentTable
          segmentIds={segmentIds}
          setSelectedAudiences={setSelectedSegments}
          tableData={tableData}
          setTableData={setTableData}
        />
      </Paper>
      <ScalingForm
        selectedOptionValue={selectedOptionValue}
        setSelectedOptionValue={setSelectedOptionValue}
        selectedSegments={selectedSegments}
        defaultRootName={defaultRootName}
        openModalCreateAudience={openModalCreateAudience}
        onConfirm={onConfirm}
        handleCloseModal={handleCloseModal}
      />
    </Page>
  );
}
