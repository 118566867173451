import { useTranslation } from "react-i18next";
import { Box, TextField, Typography } from "@mui/material";
import Add from "@mui/icons-material/Add";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import BluButton from "../../../elements/Button/BluButton";

export default function LogoUploader({
  onSave,
  isUploading,
  selectedImage,
  onAddClick,
  onCancelClick,
  inputFileRef,
  onImageChange,
}) {
  const { t } = useTranslation();

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            width: "258px",
            mr: 4,
          }}
        >
          <Typography variant="h6">Add a Logo</Typography>
          {selectedImage && (
            <img
              style={{
                maxWidth: "100%",
                maxHeight: "97px",
              }}
              src={URL.createObjectURL(selectedImage)}
              alt={t("Company logo")}
            />
          )}
        </Box>
        <Box sx={{ flex: 1 }}>
          <Box>
            <BluButton
              onClick={onAddClick}
              title="Add"
              size="small"
              aria-label="add"
              icon={<Add />}
              sx={{
                mr: 2,
              }}
            />
            <BluButton
              onClick={onCancelClick}
              title="Cancel"
              size="small"
              aria-label="cancel"
              icon={<CloseOutlined />}
              disabled={!selectedImage}
            />
          </Box>
          {selectedImage && (
            <>
              <TextField
                autoFocus
                sx={{ mt: 3.5 }}
                value={selectedImage?.name}
                size="small"
                label={t("Selected Image")}
                fullWidth
                disabled
              />
              <BluButton
                title={t("Save")}
                size="medium"
                onClick={() => onSave()}
                fullWidth
                disabled={isUploading}
                sx={{
                  mt: 2,
                }}
              />
            </>
          )}
        </Box>
      </Box>
      <input
        style={{ display: "none" }}
        ref={inputFileRef}
        accept="image/*"
        type="file"
        onChange={onImageChange}
      />
    </>
  );
}
