import { useMemo, useState } from "react";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import ConfirmDialog from "../../../../elements/Dialog/ConfirmDialog";
import { EDIT } from "../../../../constants/general";

export default function CreateEditDialog({
  value: valueProp,
  activeFolder,
  handleAction,
  action,
  open,
  isActionProcessing = false,
  ...other
}) {
  const { t } = useTranslation();
  const [value, setValue] = useState(valueProp);
  const onClose = () => {
    handleAction(action, false);
  };
  const onConfirm = () => {
    handleAction(action, value, activeFolder);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const { folderDisplay, title } = useMemo(
    () => ({
      folderDisplay:
        action === EDIT
          ? `${t("Edit this folder")}: ${activeFolder?.name}`
          : t("Enter a folder name"),
      title: action === EDIT ? t("Edit Folder") : t("Add a New Folder"),
    }),
    [action, activeFolder?.name]
  );

  // minimal validation - not processing (saving) has value and not same as initial; add additional as needed
  const saveEnabled = useMemo(
    () => !isActionProcessing && value && value?.length && value !== valueProp,
    [value, valueProp, isActionProcessing]
  );

  return (
    <ConfirmDialog
      content={folderDisplay}
      title={title}
      isOpen={open}
      confirmValue={activeFolder}
      confirmDisabled={!saveEnabled}
      onConfirm={onConfirm}
      onCancel={onClose}
      confirmText={t("Save")}
      confirmColor="primary"
      {...other}
    >
      <TextField
        autoFocus
        sx={{ mt: 2 }}
        value={value}
        onChange={handleChange}
        id="folder_name"
        size="small"
        label={t("Folder Name")}
        fullWidth
      />
    </ConfirmDialog>
  );
}
