import { useMemo, useCallback } from "react";
import { DialogContentText, DialogContent } from "@mui/material";
import BaseDialog from "./BaseDialog";
import DialogHeader from "./DialogHeader";
import DialogActionsFooter from "./DialogActionsFooter";

/* CONFIRM OPTIONS = {
    isOpen,
    title:   string or node for header
    content: string or node
    onConfirm: on cofirm callback passed with confirmValue
    confirmValue: value to store for confimation
    onCancel: cancel callback
    confirmText: confirm button text
    cancelText: cancel button text
    confirmColor: theme palette color sets header, buttons
    isConfirmOnly: only show confirm button
    mustConfirm: must close explicitly (no click away)
    confirmDisabled: disable confirm button for conditional confirm
    allowClose: close button in header
    titleProps: header props
    contentProps: dialog content props
    confirmButtonProps: action button props
    cancelButtonProps: cancel button props
    dialogProps: dialog props
}; */

// Confirm Dialog for general confirmation - handles confirm value callback
export default function ConfirmDialog({
  isOpen = false,
  title,
  content,
  onConfirm,
  confirmValue = null,
  onCancel,
  confirmText = "OK",
  cancelText = "Cancel",
  confirmColor = "primary",
  isConfirmOnly = false,
  mustConfirm = false,
  confirmDisabled = false,
  allowClose = true,
  titleProps = {},
  contentProps = {},
  confirmButtonProps = {},
  cancelButtonProps = {},
  dialogProps = {},
  children,
}) {
  const dialogOpts = useMemo(
    () => ({
      disableBackdropClick: mustConfirm,
      disableEscapeKeyDown: mustConfirm,
      ...dialogProps,
    }),
    [mustConfirm, dialogProps]
  );

  const hasTitle = useMemo(() => Boolean(title), [title]);

  const handleConfirm = useCallback(
    () => onConfirm(confirmValue),
    [onConfirm, confirmValue]
  );

  return (
    <BaseDialog
      open={isOpen}
      onClose={onCancel}
      fullWidth
      {...dialogOpts}
      data-testid="baseDialog"
    >
      {hasTitle && (
        <DialogHeader
          onClose={allowClose && !mustConfirm ? onCancel : null}
          statusColor={confirmColor}
          {...titleProps}
        >
          {title}
        </DialogHeader>
      )}
      <DialogContent
        sx={{
          "&.MuiDialogContent-root": {
            pt: 2.5,
          },
        }}
        {...contentProps}
      >
        {typeof content === "string" ? (
          <DialogContentText sx={{ color: "text.primary" }}>
            {content}
          </DialogContentText>
        ) : (
          content
        )}
        {children && children}
      </DialogContent>
      <DialogActionsFooter
        onAction={handleConfirm}
        onCancel={onCancel}
        actionText={confirmText}
        cancelText={cancelText}
        actionColor={confirmColor}
        actionDisabled={confirmDisabled}
        actionButtonProps={confirmButtonProps}
        cancelButtonProps={cancelButtonProps}
        isActionOnly={isConfirmOnly}
      />
    </BaseDialog>
  );
}
