import { createSlice } from "@reduxjs/toolkit";
import { keysToCamel } from "../utils";

export const OVERLAP_ANALYSIS = "overlap_analysis";

const initialState = {
  data: [],
  error: [],
};

function filterData(state, key, action) {
  state[key] = state[key].filter(
    (data) => data.compareSegmentId !== action.payload
  );
}

const slice = createSlice({
  name: OVERLAP_ANALYSIS,
  initialState,
  reducers: {
    addOverlapData(state, action) {
      state.data = [...state.data, keysToCamel(action.payload)];
    },
    removeOverlapDataById(state, action) {
      filterData(state, "data", action);
      filterData(state, "error", action);
    },
    cleanOverlapData(state) {
      state.data = [];
      state.error = [];
    },
    addOverlapDataError(state, action) {
      state.error = [...state.error, keysToCamel(action.payload)];
    },
  },
});

export const { reducer } = slice;

export const addOverlapData = (data) => async (dispatch) => {
  dispatch(slice.actions.addOverlapData(data));
};

export const removeOverlapDataById = (id) => async (dispatch) => {
  dispatch(slice.actions.removeOverlapDataById(id));
};

export const cleanOverlapData = () => async (dispatch) => {
  dispatch(slice.actions.cleanOverlapData());
};

export const addOverlapDataError = (error) => async (dispatch) => {
  dispatch(slice.actions.addOverlapDataError(error));
};
