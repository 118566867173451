import React from "react";
import {
  pickBy,
  isEmpty,
  isObject,
  isPlainObject,
  isArray,
  camelCase,
} from "lodash";

import parseJSON from "date-fns/parseJSON";
import { isValid, format } from "date-fns";

// Base Utilities

// date
export const tableDateDisplayFormat = "MMM d, yyyy";

export function convertJSONDateForTypographyDisplay(
  dateString,
  dateFormat = tableDateDisplayFormat,
  empty = "---"
) {
  return isValid(new Date(dateString))
    ? format(parseJSON(new Date(dateString)), dateFormat)
    : empty;
}

export function convertJSONDateForTableDisplay(dateString, empty = "---") {
  return dateString ? new Date(dateString) : empty;
}

export const zeroPad = (num, places) => String(num).padStart(places, "0");

export function DisplayProps({ props, title = "Props" }) {
  return (
    <div style={{ margin: "1rem 0" }}>
      <pre
        style={{
          // background: '#f6f8fa',
          fontSize: ".65rem",
          padding: "1rem",
        }}
      >
        <strong>{title}</strong> = {JSON.stringify(props, null, 2)}
      </pre>
    </div>
  );
}

export const genId = ((prefix = "id_") => {
  let count = 0;
  // eslint-disable-next-line no-plusplus
  return () => prefix + (++count).toString();
})();

export const getInitials = (name = "") =>
  name
    .replace(/\s+/, " ")
    .split(" ")
    .slice(0, 2)
    .map((v) => v && v[0].toUpperCase())
    .join("");

export function wait(delay = 0) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((res) => setTimeout(res, delay));
}

// join with base separator and ending separator; handles single and multiples
// IE for array display data: 'name 1'  or 'name 1 and name 2' or 'name 1, name2, name 3 and name 4'
// customJoin(parties, ', ', ' and ');
export function customJoin(arr, s1, s2 = ", ") {
  return arr
    .slice(0, -1)
    .join(s1)
    .concat(arr.length > 1 ? s2 : "", arr.slice(-1));
}

export function cleanParams(params, emptyString = "") {
  return pickBy(params, (value) =>
    isObject(value)
      ? !isEmpty(value)
      : value !== undefined && value !== null && value !== emptyString
  );
}

// check array any item includes value
export function arrayHasKey(arr, key, valueToCheck) {
  return arr.some((value) => value[key] === valueToCheck);
}
// check array all items includes value
export function arrayhasAllKey(arr, key, valueToCheck) {
  return arr.every((value) => value[key] === valueToCheck);
}

/* export function cleanParams(obj) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
      Reflect.deleteProperty(obj, key);
    }
  });
}

export function cleanData(obj) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === "object") {
      cleanData(obj[key]);
    } else if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
      Reflect.deleteProperty(obj, key);
    }
  });
} */

// gets true only if all keys in
export function hasKeys(obj, keys) {
  return keys.every((key) => Object.keys(obj).includes(key));
}

// gets true if any of keys in
export function hasSomeKeys(obj, keys) {
  return keys.some((key) => Object.keys(obj).includes(key));
}

export function getFromKeys(obj, keys) {
  // keys.reduce((a, b) => ((a[b] = obj[b]), a), {});
  return keys.reduce((a, b) => Object.assign(a, { [b]: obj[b] }), {});
}

// get diff frm 2 objects - leftover
export function getDiff(a, b) {
  return Object.entries(b).reduce(
    (c, [k, v]) => Object.assign(c, a[k] ? {} : { [k]: v }),
    {}
  );
}
// object = removeProperties(object, 'a', 'b') // result => { c: 3 }
// Or  const propsToRemove = ['a', 'b']
// object = removeProperties(object, ...propsToRemove) // result => { c: 3 }
export function removeProperties(object, ...keys) {
  return Object.entries(object).reduce(
    (prev, [key, value]) => ({
      ...prev,
      ...(!keys.includes(key) && { [key]: value }),
    }),
    {}
  );
}

// copy to clipboard: returns ret as promise - DOES NOT WORK unless localHost or HTTPS
export async function writeToClipboard(txt) {
  const ret = { success: false, msg: "Unable to copy to clipboard", val: txt };
  try {
    await navigator.clipboard.writeText(txt);
    ret.success = true;
    ret.msg = "Copied to clipboard successfully!";
    return ret;
  } catch (err) {
    // console.error(err);
    ret.error = err;
    // console.log("write to clipboard error", ret);
    return ret;
  }
}

// export file save (.txt, etc)
export function exportFile(content, filename, contentType = "text/plain") {
  const a = document.createElement("a");
  const file = new Blob([content], { type: contentType });

  a.href = URL.createObjectURL(file);
  a.download = filename;
  a.click();
  a.remove();
  URL.revokeObjectURL(a.href);
}

// get type and filename from response
export function getFileInfo(response) {
  const contentType = response.headers["content-type"] || response.data.type; // depends if blob?
  const contentDisposition = response.headers["content-disposition"];
  let fileName = "unknown";
  if (contentDisposition) {
    const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
    if (fileNameMatch.length === 2) {
      // eslint-disable-next-line prefer-destructuring
      fileName = fileNameMatch[1];
    }
  }
  return { fileName, contentType };
}

/**
 * Sorting an array of objects based on the key and direction
 * Exported function: sortArrayOfObjects
 * data: Array<Object>
 * key: String. Property value to be compared
 * direction: String. (ASC | DESC)
 * * */
export const ASC = "ASC";
export const DESC = "DESC";
// Helper fnc
function ascending(data, key) {
  return data?.sort((a, b) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  });
}
// Helper fnc
function descending(data, key) {
  return data?.sort((a, b) => {
    if (a[key] < b[key]) {
      return 1;
    }
    if (a[key] > b[key]) {
      return -1;
    }
    return 0;
  });
}

export function sortArrayOfObjects(data, key, direction) {
  if (direction.toUpperCase() === ASC) {
    return ascending(data, key);
  }
  if (direction.toUpperCase() === DESC) {
    return descending(data, key);
  }
  throw new Error(`Unsupported sorting type: ${direction}. Use ASC or DESC`);
}

export function keysToCamel(o) {
  if (isPlainObject(o)) {
    const n = {};

    Object.keys(o).forEach((k) => {
      n[camelCase(k)] = keysToCamel(o[k]);
    });

    return n;
  }
  if (isArray(o)) {
    return o.map((i) => keysToCamel(i));
  }

  return o;
}

/**
 * Receives an object, and 2 key targets. The first is the parent key (firstKeyTarget)
 * and the second (secondKeyTarget) is the key existent within the parent key
 * If secondKeyTarget is found, it returns true, otherwise, it returns false
 * obj: Object
 * firstKeyTarget: String
 * secondKeyTarget: String
 * */
export function keyExists(obj, firstKeyTarget, secondKeyTarget) {
  if (!obj || typeof obj !== "object" || Array.isArray(obj)) {
    return false;
  }
  if (Object.hasOwn(obj, firstKeyTarget)) {
    if (obj[firstKeyTarget][secondKeyTarget]) {
      return true;
    }
  }
  return false;
}

/**
 * This function receives a segment name (string) and returns an updated name (string) with (Copy) or (Copy+1).
 * If there is no "(Copy)" in the last part of the segment name, it will concatenate (Copy), i.e., "Any Name(Copy)".
 * If there is "(Copy)" in the string, it will add one, i.e., "Any Name(Copy)" to "Any Name(Copy1)", "Another Name(Copy4)" to "Another Name(Copy5)" and so on.
 * It's expecting "(Copy) | (Copy+number)" to be the last part of the segment name, so it does NOT cover a scenario where the "(Copy) | (Copy+number)" is in the middle of the string, e.g., "Some (Copy) name".
 * It does NOT cover any variants from what is being described here or from what we have as criteria.
 * */
export function updateAudienceName(name) {
  if (name?.includes("Copy")) {
    // Getting index from last occurrence
    const indexLastOccurrence = name.lastIndexOf("Copy");
    // Slicing to get 'Copy', converting to string and replacing 'Copy' with "", so it will remain just the number part
    const currentCopyNumber = Number(
      name.split("").slice(indexLastOccurrence, -1).join("").replace("Copy", "")
    );
    // Replacing old '(Copy) | (Copy+number)' with updated number
    return `${name.slice(0, indexLastOccurrence - 1)}(Copy${
      currentCopyNumber + 1
    })`;
  }

  return `${name}(Copy)`;
}

/**
 * It compares v1 against v2. If v1 occurs before, then it returns a negative number, otherwise,
 * it'll return a positive number. Returns 0 (zero) if they are equivalent.
 * */
export const compareAlphanumericStrings = (v1, v2, locale = undefined) =>
  v1.localeCompare(v2, locale, {
    numeric: true,
    sensitivity: "base",
  });

// refreshed_at can be "Processing", "", or a Date
export function handleDataColumn(refreshedAt) {
  if (isValid(new Date(refreshedAt))) {
    return convertJSONDateForTableDisplay(refreshedAt);
  }
  return refreshedAt;
}
