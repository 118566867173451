import { AccountWrapper } from "contexts/accountContext";
import BluDataGrid from "components/DataGrid/BluDataGrid";
import useQueryAudiences from "queries/useQueryAudiences";
import { ACCOUNT } from "constants/general";
import { useMemoizedCallback } from "hooks";

export default function AudiencesDataGrid({
  children,
  tableState,
  tableChange,
  setSelected,
  selectedIds,
  tableColumns,
  getTableChange,
  selectedAudiences,
  ...rest
}) {
  const { tableData, pageTotalSize, isValid, isLoading, isRefetching, error } =
    useQueryAudiences(tableState, "AUDIENCES");

  const tableDispatch = useMemoizedCallback(
    (type, newState, isFromTable = false) => {
      const ret = isFromTable ? newState : getTableChange(type, newState);
      tableChange(ret);
    }
  );

  const getRowOnSelection = useMemoizedCallback((ids) => {
    const selectedIDs = new Set(ids);
    const selectedRowData = tableData.filter((row) =>
      selectedIDs.has(row.id.toString())
    );
    setSelected(selectedRowData);
  });

  const onAccountChange = useMemoizedCallback((act) => {
    const actId = act ? act.id : null;
    tableDispatch(ACCOUNT, actId);
    setSelected([]);
  });

  const onSearchChange = (val, type) => {
    const searchValue = val.trim();
    if (searchValue.length >= 3 || val.length === 0) {
      tableDispatch(type, val);
    }
  };

  return (
    <AccountWrapper onAccountChange={onAccountChange}>
      {() => (
        <>
          {children({ tableState, onSearchChange })}
          <BluDataGrid
            tableState={tableState}
            tableDispatch={tableDispatch}
            tableData={tableData}
            isValid={isValid}
            error={error}
            isLoading={isLoading || isRefetching}
            columns={tableColumns}
            pageTotalSize={pageTotalSize}
            onSelectionModelChange={(ids) => getRowOnSelection(ids)}
            selectionModel={selectedIds}
            {...rest}
          />
        </>
      )}
    </AccountWrapper>
  );
}
