import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import TextFieldFormik from "components/TextFieldFormik";

export default function DuplicateAudience({
  selectedAudienceName,
  audienceName,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
}) {
  const { t } = useTranslation();

  return (
    <>
      <Typography>{t("Would you like to duplicate this audience?")}</Typography>
      <Typography color="primary">{selectedAudienceName}</Typography>
      <Typography
        sx={{
          mt: 3,
          mb: 1,
        }}
      >
        {t("The new audience will be:")}
      </Typography>
      <TextFieldFormik
        value={audienceName}
        label={t("New Audience Name (Edit)")}
        name="audienceName"
        size="small"
        autoFocus
        fullWidth
        form={{
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
        }}
      />
    </>
  );
}
