import Grid from "@mui/material/Grid";
import DetailsList from "./DetailsList";
import Header from "./Header";

export default function Details({ columnSpacing, rowSpacing, data }) {
  return (
    <Grid container rowSpacing={rowSpacing}>
      <Header columnSpacing={columnSpacing} />
      <DetailsList columnSpacing={columnSpacing} data={data} />
    </Grid>
  );
}
