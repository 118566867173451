import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateAudienceName } from "utils";
import DuplicateAudience from "./index";
import { useAudiencesDuplicate } from "../../useSegmentsMutations";

export default function DuplicateAudienceConfig({
  setSelectedAudiences,
  selectedAudience,
  setOpenDialog,
  setActionName,
}) {
  const { t } = useTranslation();

  const { mutate, isLoading } = useAudiencesDuplicate({
    key: "AUDIENCES",
  });

  function clear() {
    setOpenDialog(false);
    setSelectedAudiences([]);
    setActionName("");
  }

  const formik = useFormik({
    initialValues: {
      audienceName: updateAudienceName(selectedAudience?.name),
    },
    validationSchema: Yup.object({
      audienceName: Yup.string()
        .trim()
        .required("New audience name is required"),
    }),
    onSubmit: async () => {
      const { audienceName } = formik.values;
      mutate(
        { audienceId: selectedAudience?.id, audienceName },
        {
          onSettled: () => {
            clear();
          },
        }
      );
    },
    enableReinitialize: true,
  });

  const {
    handleSubmit,
    errors,
    values,
    touched,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = formik;

  function onClose() {
    clear();
    resetForm();
  }

  return {
    Component: DuplicateAudience,
    title: t("Duplicate"),
    confirmText: t("Save"),
    onConfirm: handleSubmit,
    onClose,
    isLoading,
    isConfirmDisabled: !!errors.audienceName,
    props: {
      selectedAudienceName: selectedAudience?.name,
      audienceName: values.audienceName,
      errors,
      touched,
      setFieldValue,
      setFieldTouched,
    },
  };
}
