import { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Typography, Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Collapse from "@mui/material/Collapse";
import Stack from "@mui/material/Stack";
import { v4 as uuidv4 } from "uuid";
import CreateButton from "elements/Button/CreateButton";
import BluButton from "elements/Button/BluButton";
import ConfirmDialog from "elements/Dialog/ConfirmDialog";
import { useMemoizedCallback } from "hooks";
import useAccount, { AccountWrapper } from "contexts/accountContext";
import { actions, features, targets, pages } from "constants/roles";
import { useDispatch, useSelector } from "react-redux";
import { updateCalcShow, updateDataShow } from "slices/audienceBuilder";
import DataSourcesPanel from "./DataSources";
import CalculationsPanel from "./CalculationsPanel";
import BuilderStatus from "./BuilderStatus";
import BuilderItem from "./BuilderItem";
import { addToDataItems, checkItemsMax } from "./builderModel";
import { getBtnDisabledSx } from "./helpers";
import { useBuilderCreatMutation } from "./builderQueries";
import useBuilderStatus from "./BuilderStatus/useBuilderStatus";
import useBuilders from "./useBuilder_temp";
import useQuerySegment from "../../../queries/useQuerySegment";
import { useActionAlerter } from "../../../components/Alert";
import RoleBasedGuard from "../../../components/Guard/RoleBasedGuard";
import useRole from "../../../components/Guard/useRole";
import { useMutationCalculations } from "./useMutationCalculations";
import { updateCalcID } from "../../../slices/segmentCalculations";

const isDebug = false;

// base on screen size - between lg and xl menu shows - use between/else lg
// todo small/tablet?
const dataPanelWidthLg = 600;
const dataPanelWidthBetween = 500;
const calcPanelWidthLg = 600;
const calcPanelWidthBetween = 500;
const heightBuffer = 64;

const segmentProcessContent =
  "The segment data is being processed and should be available in about one minute. Please refresh your browser at that time.";

export default function AudienceBuilder() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { segmentId } = useParams();
  const [dataShow, setDataShow] = useState(true);
  const [calcShow, setCalcShow] = useState(false);
  const [isCalculating, setIsCalculating] = useState(false);
  const [isConfirmShow, setIsConfirmShow] = useState(false);
  const [isBuilderCreating, setIsBuilderCreating] = useState(false);
  const { mutate: createCalculationMutate } = useMutationCalculations();
  const { start, complete } = useActionAlerter(
    "Data pre-populated with success"
  );
  const selectedSegmentID = useSelector(
    (state) => state.segmentCalculations.segmentID
  );
  const dataShowRedux = useSelector((state) => state.audienceBuilder.dataShow);
  const calcShowRedux = useSelector((state) => state.audienceBuilder.calcShow);
  const builderState = useSelector(
    (state) => state.audienceBuilder.builderState
  );
  const isBuilderTouched = useSelector(
    (state) => state.audienceBuilder.isBuilderTouched
  );
  const builderName = useSelector(
    (state) => state.audienceBuilder.builderModel.builderName
  );
  const uuid = uuidv4();

  useEffect(() => {
    setCalcShow(calcShowRedux);
  }, [calcShowRedux]);

  useEffect(() => {
    setDataShow(dataShowRedux);
  }, [dataShowRedux]);

  const {
    builderStatus,
    prevBuilderStatus,
    changeBuilderStatus,
    changeBuilderMode,
  } = useBuilderStatus("ready");

  const { mutate: createBuilderMutate, isLoading: isCreateBuilderLoading } =
    useBuilderCreatMutation();

  // pass initial account
  const { accountId, isAccountActive } = useAccount();

  const {
    builderItemsDispatch,
    activeBuilderItem,
    builderItems,
    builderStart,
    setBuilderName,
    isBuilderValid,
    getBuilderSaveData,
  } = useBuilders({ accountId });

  const { checkPermission } = useRole();

  const canAccessCreateFrom = checkPermission(
    features.segments,
    actions.createFrom
  );

  const {
    data: segment,
    error: errorQuerySegment,
    isSuccess: isSuccessQuerySegment,
  } = useQuerySegment({
    segmentId,
    queryProps: {
      enabled: !!segmentId && isAccountActive && canAccessCreateFrom,
    },
  });

  const LGtoXL = useMediaQuery(
    (theme) => theme.breakpoints.between("lg", "xl"),
    {
      noSsr: true,
    }
  );

  const { dataPanelWidth, calcPanelWidth } = useMemo(
    () => ({
      dataPanelWidth: LGtoXL ? dataPanelWidthBetween : dataPanelWidthLg,
      calcPanelWidth: LGtoXL ? calcPanelWidthBetween : calcPanelWidthLg,
    }),
    [LGtoXL]
  );

  const builderItemsMax = useMemo(
    () => checkItemsMax(builderItems, false),
    [builderItems]
  );

  // uses activeBuilderItem - check valid and display message?
  // checks for duplicates, max, is loading and active
  const handleBuilderItemTransfer = useMemoizedCallback((params) => {
    const { dataItems } = activeBuilderItem;
    if (!activeBuilderItem || isCreateBuilderLoading) return;
    // returns {success, error, items} error: "max or "duplicate
    const { success, error, items } = addToDataItems(dataItems, params);
    if (success) {
      builderItemsDispatch("update", activeBuilderItem, {
        dataItems: items,
      });
    } else if (isDebug) {
      console.log("TRANSFER ERROR", error);
    }
  });

  const handleDataChange = () => {
    dispatch(updateCalcShow(false));
    dispatch(updateDataShow(!dataShow));
  };

  const handleCalcClose = () => {
    dispatch(updateCalcShow(false));
    dispatch(updateDataShow(true));
  };

  const handleCalcOpen = () => {
    dispatch(updateCalcShow(true));
    dispatch(updateDataShow(false));
  };

  const buildCalcSendData = () => {
    const sendData = {};
    sendData.calculate_items = [];
    sendData.calculate_items = builderItems.map((item) => {
      const mapItems = {
        ...(item.exclude && { exclude: true }),
        booleanOperator: item.itemsOperator,
        audienceIds: item.dataItems.map((i) => i.dataId),
      };
      return mapItems;
    });
    dispatch(updateCalcID(uuid));

    sendData.request_id = uuid;
    return sendData;
  };

  const handleCalcClick = useMemoizedCallback(() => {
    setIsCalculating(true);
    const calcSendData = buildCalcSendData();
    createCalculationMutate(calcSendData, {
      onSuccess: () => {
        handleCalcOpen();
      },
    });
  });
  const onAccountChange = useMemoizedCallback((act) => {
    const actId = act ? act.id : null;
    if (actId) {
      builderStart({ accountId: actId }, true);
      dispatch(updateCalcShow(false));
      dispatch(updateDataShow(false));
    }
  });

  const handleBuilderCreate = useMemoizedCallback(() => {
    // check is valid error etc; disable editing
    setIsBuilderCreating(true); // creating mode
    dispatch(updateDataShow(false));
    changeBuilderMode("create");
    createBuilderMutate(getBuilderSaveData, {
      onSettled: (data, err) => {
        if (isDebug) console.log("ON CREATE", data, err);
        const isSuccess = !!(!err && data);

        if (isSuccess) {
          // confirm, redirect, start over? clone existing?
          setIsConfirmShow(true);
        } else {
          changeBuilderStatus("error");
        }
      },
    });
  });

  useEffect(() => {
    if (segmentId && isAccountActive && canAccessCreateFrom) {
      start();
    }
    if (isSuccessQuerySegment) {
      builderStart(segment?.segment_data, false);
      complete(true);
    }
    if (errorQuerySegment) {
      complete(false, errorQuerySegment?.message);
    }
  }, [
    isAccountActive,
    isSuccessQuerySegment,
    segmentId,
    errorQuerySegment,
    canAccessCreateFrom,
  ]);

  useEffect(() => {
    if (isDebug) {
      console.log("BUILDER CHANGE", isBuilderValid, builderState);
    }
  }, [isBuilderValid, builderState]);

  // toggle between modes when touched reset
  useEffect(() => {
    changeBuilderMode(isBuilderTouched ? "start" : "ready");
    setIsBuilderCreating(false);
  }, [isBuilderTouched]);

  // handle validation changes when in start mode
  useEffect(() => {
    if (builderStatus.mode === "start") {
      changeBuilderStatus(isBuilderValid ? "success" : "warning");
    }
  }, [isBuilderValid, builderStatus.mode]);

  // handle reset to start after error - but not until tries to edit
  useEffect(() => {
    if (builderStatus.mode === "create") {
      if (
        isBuilderCreating &&
        builderStatus.status === "error" &&
        prevBuilderStatus.isInitial !== true
      ) {
        // console.log("CHANGE Error", builderStatus, prevBuilderStatus);
        // create error state active, turn off create mode
        setIsBuilderCreating(false);
      } else if (!isBuilderCreating && prevBuilderStatus.status === "error") {
        // error state active but can create: change mode to start
        changeBuilderMode("start");
      }
    }
  }, [isBuilderCreating, builderStatus, builderState]);

  // This page is used for 2 features. The builder page and create from
  // segmentId is the URL param used in create from
  const feature = segmentId ? features.segments : pages.builder;
  const action = segmentId ? actions.createFrom : actions.view;
  const target = segmentId ? targets.features : targets.pages;
  return (
    <RoleBasedGuard
      feature={feature}
      action={action}
      target={target}
      sx={{
        px: 3,
        py: 2,
      }}
    >
      <AccountWrapper onAccountChange={onAccountChange}>
        {() => (
          <Box>
            <Box
              sx={{
                height: `calc(100vh - ${heightBuffer}px)`,
                overflow: "hidden",
              }}
            >
              <Box sx={{ display: "flex", height: "100%" }}>
                <Collapse
                  orientation="horizontal"
                  in={dataShow}
                  timeout={{
                    enter: 500,
                    exit: 200,
                  }}
                >
                  <Box
                    sx={{
                      width: dataPanelWidth,
                      height: "100%",
                      paddingBottom: 0.5,
                    }}
                  >
                    <DataSourcesPanel
                      panelWidth={dataPanelWidth}
                      onClose={handleDataChange}
                      handleBuilderItemTransfer={handleBuilderItemTransfer}
                      isActive={dataShow}
                    />
                  </Box>
                </Collapse>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    px: 3,
                    py: 2,
                    flexGrow: 1,
                    width: `calc(100% - ${dataPanelWidth}px)`,
                    // marginLeft: dataShow ? `${dataPanelWidth}px` : 0,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 0,
                    }}
                  >
                    <Typography
                      variant="h4"
                      component="h1"
                      color="text.primary"
                    >
                      {t("Audience Builder")}
                    </Typography>
                    <Stack direction="row" spacing={2} sx={{ mr: 6 }}>
                      <BluButton
                        title="Add/Edit Data"
                        disabled={isCreateBuilderLoading}
                        color="primary"
                        size="small"
                        sx={getBtnDisabledSx("primary")}
                        onClick={() => handleDataChange()}
                      />
                      <BluButton
                        title="Calculate"
                        disabled={
                          !isBuilderValid ||
                          isCreateBuilderLoading ||
                          isCalculating
                        }
                        color="primary"
                        size="small"
                        sx={getBtnDisabledSx("primary")}
                        onClick={() => handleCalcClick()}
                      />
                      <CreateButton
                        disabled={!isBuilderValid || isCreateBuilderLoading}
                        color="secondary"
                        btnSize="small"
                        sx={getBtnDisabledSx("secondary")}
                        onClick={() => handleBuilderCreate()}
                      />
                    </Stack>
                  </Box>
                  <BuilderStatus
                    builderItemsMax={builderItemsMax}
                    count={builderItems.length}
                    builderStatus={builderStatus}
                  />
                  <Box
                    sx={{
                      flexGrow: 1,
                      overflowY: "auto",
                      p: 0,
                    }}
                  >
                    <Stack
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="stretch"
                      spacing={2}
                    >
                      {builderItems.map((abi) => (
                        // todo consolidate props
                        <BuilderItem
                          key={abi.id}
                          item={abi}
                          selected={activeBuilderItem?.id === abi.id}
                          builderItemsDispatch={builderItemsDispatch}
                          builderName={builderName}
                          isBuilderTouched={isBuilderTouched}
                          isBuilderValid={isBuilderValid}
                          builderItemsMax={builderItemsMax}
                          isBuilderDisabled={isCreateBuilderLoading}
                          setBuilderName={setBuilderName}
                        />
                      ))}
                    </Stack>
                  </Box>
                </Box>
                <Collapse
                  orientation="horizontal"
                  in={calcShow}
                  timeout={{
                    enter: 500,
                    exit: 200,
                  }}
                  sx={{
                    zIndex: 1,
                  }}
                >
                  <Box
                    sx={{
                      width: calcPanelWidth,
                      height: "100%",
                      paddingBottom: 0.5,
                    }}
                  >
                    <CalculationsPanel
                      audienceName={builderName}
                      panelWidth={calcPanelWidth}
                      onClose={handleCalcClose}
                      isActive={calcShow}
                      selectedSegmentID={selectedSegmentID}
                      setIsCalculating={setIsCalculating}
                      resetCalc={isCalculating}
                    />
                  </Box>
                </Collapse>
              </Box>
            </Box>
            <ConfirmDialog
              title="New Audience Created"
              content={segmentProcessContent}
              isOpen={isConfirmShow}
              onCancel={() => setIsConfirmShow(false)}
              onConfirm={() => setIsConfirmShow(false)}
              isConfirmOnly
            />
          </Box>
        )}
      </AccountWrapper>
    </RoleBasedGuard>
  );
}
