import AddIcon from "@mui/icons-material/Add";
import AddBoxIcon from "@mui/icons-material/AddBox";
import BluButton from "./BluButton";

export default function CreateButton(props) {
  const {
    title = "Create",
    isIcon = false,
    btnSize = "medium",
    ...otherProps
  } = props;

  return (
    <BluButton
      title={title}
      size={btnSize}
      icon={isIcon ? <AddBoxIcon fontSize={btnSize} /> : <AddIcon />}
      isIcon={isIcon}
      sx={{ alignItems: "stretch" }}
      {...otherProps}
    />
  );
}
