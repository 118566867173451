import { createSlice } from "@reduxjs/toolkit";
import { keysToCamel } from "../utils";

export const ROLE = "role";

const initialState = {
  data: null,
};

const slice = createSlice({
  name: ROLE,
  initialState,
  reducers: {
    setRole(state, action) {
      state.data = keysToCamel(action.payload);
    },
  },
});

export const { reducer } = slice;

export const setRole = (data) => async (dispatch) => {
  dispatch(slice.actions.setRole(data));
};
