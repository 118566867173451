import { Grid, Typography } from "@mui/material";
import SelectField from "elements/Field/SelectField";
import { useTranslation } from "react-i18next";

const dummyPartners = [
  {
    id: 1,
    value: "partner a",
    label: "Partner A",
  },
  {
    id: 2,
    value: "partner b",
    label: "Partner B",
  },
  {
    id: 3,
    value: "partner c",
    label: "Partner C",
  },
];

export default function DropdownFilters({
  columnSpacing,
  selectedPartner,
  onSelectPartner,
}) {
  const { t } = useTranslation();

  return (
    <Grid container columnSpacing={columnSpacing}>
      <Grid item xs={6}>
        <SelectField
          selectDisplay={t("Partners")}
          options={dummyPartners}
          value={selectedPartner}
          loading={false}
          textFieldProps={{
            fullWidth: true,
            size: "small",
          }}
          onSelectChange={onSelectPartner}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography>Est. Matched Individual</Typography>
      </Grid>
    </Grid>
  );
}
