/* eslint-disable import/prefer-default-export */
import toast from "react-hot-toast";

// Decorator for toast for use with extended Toaster - Mui ALerts
// base functions return id that can be used to update/dismiss toast
const alerter = {};

alerter.success = (msg, opts = {}) => toast.success(msg, opts);

alerter.error = (msg, title = null, opts = {}) =>
  toast.error(msg, { title, ...opts });

alerter.warning = (msg, title = null, opts = {}) =>
  toast.error(msg, { title, isWarning: true, ...opts });

alerter.info = (msg, title = null, opts = {}) => toast(msg, { title, ...opts });

alerter.loading = (msg, opts = {}) => toast.loading(msg, opts);

alerter.dismiss = (id) => toast.dismiss(id);

alerter.getToast = () => toast;

export { alerter };
