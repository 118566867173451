/* eslint-disable no-console */
import { Auth } from "aws-amplify";
import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";

export default {
  // We'll have a login with user and password in the future
  // signIn: async (username, password) => {
  //   try {
  //     // const { user } = await Auth.signIn({
  //     //   username,
  //     //   password,
  //     // });
  //
  //     const user = {
  //       name: "Smarty Tester",
  //       displayName: "Smarty",
  //       org: "Cool App, inc.",
  //     };
  //     // console.log("User: ", user);
  //     return user;
  //   } catch (err) {
  //     // We'll probably need some tool to report errors in FE, like Dynatrace
  //     console.error("User Api", err?.message);
  //     throw err;
  //   }
  // },
  federatedSignIn: async () => {
    try {
      await Auth.federatedSignIn({
        provider: process.env.REACT_APP_PROVIDER_NAME,
      });
    } catch (err) {
      // We'll probably need some tool to report errors in FE, like Dynatrace
      console.error("federatedSignIn Api", err?.message);
      throw err;
    }
  },
  logout: async (username) => {
    /**
     * It's using this cognito library instead of the method Auth.SignOut() from aws-amplify, because
     * aws-amplify is actually login out the user from everywhere, and it's a requirement to log out
     * just from this app.
     * We are not sure yet whether it's the expected behavior in this amplify lib or just a bug.
     */
    const userPool = new CognitoUserPool({
      UserPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
      ClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
    });
    const userData = {
      Username: username,
      Pool: userPool,
    };

    try {
      const cognitoUser = new CognitoUser(userData);
      cognitoUser.signOut();
    } catch (err) {
      // We'll probably need some tool to report errors in FE, like Dynatrace
      console.error("logout Api", err?.message);
      throw err;
    }
  },
};
