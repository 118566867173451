import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import EditAudience from "./index";
import { useAudiencesEditName } from "../../useSegmentsMutations";

export default function EditAudienceConfig({
  setSelectedAudiences,
  selectedAudience,
  setOpenDialog,
  setActionName,
}) {
  const { t } = useTranslation();
  const [audienceName, setAudienceName] = useState("");

  const { mutate: editAudience, isLoading: isLoadingEditAudience } =
    useAudiencesEditName({
      key: "AUDIENCES",
      audienceId: selectedAudience?.id,
    });

  useEffect(() => {
    if (selectedAudience) {
      setAudienceName(selectedAudience?.name);
    }
  }, [selectedAudience]);

  function onChange(name) {
    setAudienceName(name);
  }

  function clear() {
    setOpenDialog(false);
    setSelectedAudiences([]);
    setActionName("");
  }

  function onConfirm() {
    editAudience(
      { audienceId: selectedAudience?.id, audienceName },
      {
        onSettled: () => {
          clear();
        },
      }
    );
  }

  function onClose() {
    clear();
  }

  return {
    Component: EditAudience,
    title: t("Edit audience"),
    contentTitle: t("Please select a new name for this audience"),
    confirmText: t("Save"),
    isConfirmDisabled: !audienceName,
    onConfirm,
    onClose,
    isLoading: isLoadingEditAudience,
    props: {
      audienceName,
      onChange,
    },
  };
}
