/* eslint-disable no-console */
import { useState, useEffect, useMemo } from "react";
import { useMemoizedCallback } from "hooks";
import { useDispatch, useSelector } from "react-redux";
import {
  initBuilderState,
  insertBuilderItem,
  removeBuilderItem,
  updateBuilderName,
  updateBuilderItems,
  updateAccountId,
} from "slices/audienceBuilder";
import { primaryBuilderItem, validateBuilder } from "./builderModel";

const isDebug = false;

// REDUCER - ADJUST for real use, change on account TBD; LOAD EXISTING BUILDER

// Basically tried to copy the same format as the old one. Not 100% if its identical functionality.
export default function useBuilders(initialBuilder) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateAccountId(initialBuilder));
    console.log(initialBuilder);
  }, [initialBuilder?.accountId]);

  // Get redux state of BuilderItems
  const builderItems = useSelector(
    (state) => state.audienceBuilder.builderModel.builderItems
  );

  // Get redux state of BuilderState
  const getBuilderSaveData = useSelector(
    (state) => state.audienceBuilder.builderModel
  );

  // Validates if the build is valid?
  const isBuilderValid = useMemo(
    () => validateBuilder(getBuilderSaveData),
    [getBuilderSaveData]
  );
  const [activeBuilderItem, setActiveBuilderItem] = useState({});
  // Sets active builder item to last item. Idk if active builder means
  // the builder that is selected.
  useEffect(() => {
    const lastBuilderItem = builderItems.at(-1);
    if (lastBuilderItem !== activeBuilderItem) {
      setActiveBuilderItem(lastBuilderItem);
    }
  }, [builderItems]);
  const builderStart = useMemoizedCallback((startBuilder, isNew = true) => {
    dispatch(initBuilderState(isNew ? {} : startBuilder));
    setActiveBuilderItem(
      isNew ? primaryBuilderItem : startBuilder.builderItem[0]
    );
  });

  const onHandleBuilderItemInsert = useMemoizedCallback((item) => {
    const nextIdx = item.idx + 1;
    dispatch(insertBuilderItem(nextIdx));
  });

  const onHandleBuilderItemRemove = useMemoizedCallback((item) => {
    const removeId = item.id;
    dispatch(removeBuilderItem(removeId));
  });
  const onHandleBuilderItemUpdate = useMemoizedCallback((item, values) => {
    const updateIdx = item.idx;
    dispatch(updateBuilderItems({ idx: updateIdx, vals: values }));
  });

  const onHandleActiveBuilderItem = useMemoizedCallback((item) => {
    setActiveBuilderItem(item);
  });

  // get builder state; todo: convert for save for BE
  // const getBuilderSaveData = useMemoizedCallback(() => state?.builderState);

  const builderItemsDispatch = useMemoizedCallback((action, item, params) => {
    switch (action) {
      case "insert":
        onHandleBuilderItemInsert(item);
        break;
      case "remove":
        onHandleBuilderItemRemove(item);
        break;
      case "activate":
        onHandleActiveBuilderItem(item);
        break;
      case "update":
        onHandleBuilderItemUpdate(item, params);
        break;
      default:
    }
    if (isDebug) console.log("USE BUILDER DISPATCH", action, item);
  });

  return {
    builderItemsDispatch,
    activeBuilderItem,
    builderItems,
    builderStart,
    setBuilderName: (changeName) => dispatch(updateBuilderName(changeName)),
    isBuilderValid,
    getBuilderSaveData,
  };
}
