import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ReactQueryDevtools } from "react-query/devtools";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Provider as ReduxProvider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import Amplify from "aws-amplify";
import { SettingsConsumer, SettingsProvider } from "./contexts/settingsContext";
import App from "./pages/App";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import createTheme from "./theme";
import awsConfig from "./awsExports";
import "./i18n";

Amplify.configure(awsConfig);

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <SettingsProvider>
        <SettingsConsumer>
          {({ settings }) => (
            <ThemeProvider
              theme={createTheme({
                mode: settings.theme,
                isDefaultTheme: settings.isDefaultTheme,
              })}
            >
              <CssBaseline />
              <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </ThemeProvider>
          )}
        </SettingsConsumer>
      </SettingsProvider>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
