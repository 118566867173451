import { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { ListItemButton, ListItemText, ListItemIcon, Box } from "@mui/material";
import useActivePathMatch from "../../hooks/useActivePathMatch";

const sxListItem = (_theme, paddingLeft, active) => ({
  color: "neutral.300",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 1,
  pl: `${paddingLeft}px`,
  pr: 1,
  textAlign: "left",
  textTransform: "none",
  width: "100%",
  ...(active && {
    backgroundColor: "rgba(255,255,255, 0.08)",
    color: "info.main",
  }),
  "&:hover": {
    backgroundColor: "rgba(255,255,255, 0.08)",
  },
  "& .MuiListItemIcon-root": {
    color: active ? "info.main" : "neutral.400",
    mr: 1,
    ml: -0.5,
    minWidth: "20px",
  },
  "& .MuiSvgIcon-root": {
    fontSize: 20,
  },
  "& .MuiListItemText-root": {
    mt: 0.5,
    mb: 0,
    fontSize: 14,
  },
});

// fixes React render warning (inline)
const CustomLink = forwardRef(({ to, ...props }, ref) => (
  <RouterLink to={to} ref={ref} {...props} role={undefined} />
));

// TBD List Items - branch versus leaf with collapsed options
// get active nav by route
// problem is routes can be partials /dashboard/audiences to /dashboard
function ListItemLink(props) {
  const {
    icon,
    primary,
    to,
    depth = 0,
    isDefault,
    secondary,
    visible = true,
  } = props;

  // get active as partial or extended path
  const active = useActivePathMatch(to, isDefault);

  let paddingLeft = 24;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  /* const renderLink = useMemo(
    () =>
      forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />
      )),
    [to]
  ); */

  return (
    <Box component="li" sx={{ px: 2, mb: 0.5 }}>
      <ListItemButton
        sx={(theme) => sxListItem(theme, paddingLeft, active)}
        to={to}
        component={CustomLink}
        disabled={!visible}
      >
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText
          primary={primary}
          primaryTypographyProps={{ fontSize: 14 }}
        />
        {secondary && secondary}
      </ListItemButton>
    </Box>
  );
}

export default ListItemLink;
