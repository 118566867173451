import { useQuery } from "react-query";
import accountsApi from "../api/accounts";
import QUERY_KEYS from "../constants/queryKeys";

const defaultOptions = {
  retry: false,
  staleTime: Infinity,
};

export default function useQueryAccounts(queryProps = {}) {
  const accounts = useQuery([QUERY_KEYS.ACCOUNTS], () => accountsApi.get(), {
    ...defaultOptions,
    ...queryProps,
  });

  return {
    ...accounts,
    data: accounts?.data || [],
  };
}
