import axios from "../axios";

const context = "/accounts";

export default {
  get: async () => {
    try {
      const response = await axios.get(`${context}`);
      return response?.data?.results;
    } catch (e) {
      const errorMessages = { error: e?.message };
      throw errorMessages;
    }
  },
};
