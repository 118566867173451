import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { keyExists } from "utils";
import { targets } from "constants/roles";

/**
 * This component is checking whether the user has permission or not to access a feature. It's searching
 * across the JSON returned from the API, and displaying a message in case there is no access.
 *
 * Use it to hide content in pages and sections. For small features we have the hook useRole.js
 *
 * role: My role name: Admin, Power User, General User, Data Scientist and View Only.
 * features: Every feature individually (folders, audiences, etc).
 * sections: Small portions of the UI as sidebar. There is no reason to check for every single
 * feature if the user has access to all of them. We can just return it in this "sections" prop to check if we can render everything.
 * pages: Entire page: Audiences, Models, Builder, etc. If it's true, the user has access to it.
 * Using CRUD operations for each functionality (create, read, update, delete) and custom ones (duplicate, move, run, etc)
 *
 * Example:
 *
 * const responseFromApi = {
 *   role: "admin",
 *   features: {
 *     uploadLogo: {
 *       create: true,
 *       read: true,
 *       update: true,
 *     },
 *     clientAccounts: {
 *       read: true,
 *     },
 *  },
 *  sections: {
 *    someSectionName: {
 *      view: true,
 *    }
 *  },
 *  pages: {
 *    builder: {
 *      view: true,
 *    }
 *  }
 * * */

export default function RoleBasedGuard({
  feature,
  action,
  target = targets.features,
  fallbackMessage = "You don't have permission to access this section",
  sx,
  children,
}) {
  const { t } = useTranslation();
  const [canView, setCanView] = useState(false);
  const noAccess = t(fallbackMessage);
  const role = useSelector((state) => state.role.data);

  useEffect(() => {
    if (role) {
      const hasPermission = keyExists(role[target], feature, action);
      if (hasPermission) {
        setCanView(true);
      } else {
        setCanView(false);
      }
    }
  }, [role]);

  if (!canView) {
    return <Box sx={sx}>{noAccess}</Box>;
  }

  return children;
}
