import * as Yup from "yup";
import TextFieldFormik from "components/TextFieldFormik";
import ConfirmDialog from "elements/Dialog/ConfirmDialog";
import FormLabel from "@mui/material/FormLabel";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { roleNames } from "../../../constants/roles";

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
        float: "right",
      }}
    >
      <GridToolbarQuickFilter />
    </Box>
  );
}

const columns = [
  {
    field: "partnerId",
    headerName: "ID",
    width: 90,
    editable: true,
  },
  {
    field: "partnerName",
    headerName: "Name",
    width: 150,
    editable: true,
  },
];

let rows = [];

/*
 * Function to build the object for initial values and validation schema
 * Initial values: { Some Field Name: "", Another Field Name: ""}
 * Validation Schema: Yup.object().shape({ Some Field Name: Yup constraints, Another Field Name: Yup constraints})
 * Field names are dynamic and returned from the API
 * */
function createObjectFromArrayOfObjects(array, callback) {
  return Object.assign(
    {},
    ...array.map((param) => {
      const { fieldName, required } = param;
      return {
        [fieldName]: callback(fieldName, required),
      };
    })
  );
}

export default function AddNewPartnerConnection(props) {
  const { t } = useTranslation();
  const {
    searchOptions,
    handleInputChange,
    handleSelectChange,
    partnerValue,
    connectionParameters,
    onConfirm,
    roleName,
  } = props;

  const hidePoweredBy = roleName === (roleNames.ADMIN && roleName.POWER_USER);

  const handleRowClick = (params) => {
    const returnObj = searchOptions.filter(
      (i) => i.partnerId === params.row.partnerId
    )[0];
    handleInputChange(params.row.name);
    handleSelectChange(returnObj);
  };

  const initialValues = createObjectFromArrayOfObjects(
    connectionParameters,
    () => ""
  );

  if (searchOptions) {
    rows = searchOptions;
  }

  const validationSchema = Yup.object().shape(
    createObjectFromArrayOfObjects(
      connectionParameters,
      (fieldName, required) =>
        required
          ? Yup.string().trim().required(`${fieldName} is required`)
          : Yup.string().trim().notRequired()
    )
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => onConfirm(values)}
      enableReinitialize
    >
      {({
        errors,
        values,
        touched,
        submitForm,
        setFieldValue,
        setFieldTouched,
      }) => (
        <ConfirmDialog {...props} onConfirm={submitForm}>
          {!partnerValue && (
            <>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Select a partner below:
              </FormLabel>
              <Box sx={{ height: 400, width: "100%", marginTop: "20px" }}>
                <DataGrid
                  onRowClick={handleRowClick}
                  rows={rows}
                  columns={columns}
                  columnVisibilityModel={{ poweredBy: hidePoweredBy }}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  getRowId={() => Math.random()}
                  components={{ Toolbar: QuickSearchToolbar }}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  disableExportFilter
                />
              </Box>
            </>
          )}
          {partnerValue && (
            <div>
              <div style={{ fontWeight: "bold" }}>{partnerValue?.name}</div>
              {hidePoweredBy && <div>Powered By:{partnerValue?.poweredBy}</div>}
            </div>
          )}
          <Stack spacing={2} mt={4}>
            {Object.entries(values).map(([name, value]) => (
              <TextFieldFormik
                value={value}
                label={t(name)}
                id={name}
                name={name}
                fullWidth
                key={name}
                form={{
                  errors,
                  touched,
                  setFieldValue,
                  setFieldTouched,
                }}
              />
            ))}
          </Stack>
        </ConfirmDialog>
      )}
    </Formik>
  );
}
