import { useQuery } from "react-query";
import customizationApi from "api/accounts/customization";
import QUERY_KEYS from "constants/queryKeys";

const defaultOptions = {
  retry: false,
  enabled: false,
};

export default function useSignedUploadUrlQuery(accountId, fileName) {
  const uploadUrl = useQuery(
    [QUERY_KEYS.ADMIN_CUSTOMIZATION, { accountId, fileName }],
    () => customizationApi.getSignedUploadUrl(accountId, fileName),
    defaultOptions
  );

  return {
    ...uploadUrl,
  };
}
