import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { alerter } from "./alerter";
import { genId } from "../../utils";
import { useMemoizedCallback } from "../../hooks";

// Alerter Hook for typical crud operation
// React Query mutation or promise: start processing then complete with success or failure
// uses default translated actions/status
// adding abilty to reset for reuse across different actions
// TODO possibly translate actions by domain here: Create Folders
export function useActionAlerter(action, id = null) {
  const { t } = useTranslation();
  const retId = useRef(id || genId());
  const retAction = useRef(t(action) || t("common.action.submission"));

  const start = useMemoizedCallback((msg) => {
    const retMsg = t(msg) || `${t("common.status.processing")}...`;
    return alerter.loading(retMsg, { id: retId.current });
  });

  const complete = useMemoizedCallback((success, msg) => {
    const tMsg = t(msg);
    if (success === undefined) {
      return alerter.dismiss(retId);
    }
    const outcome = retAction.current;
    if (success) {
      return alerter.success(outcome || tMsg, { id: retId.current });
    }
    return alerter.error(tMsg || outcome, tMsg && outcome, {
      id: retId.current,
    });
  });

  const reset = useMemoizedCallback((newAction, newId) => {
    retId.current = newId || genId();
    retAction.current = newAction || t("common.action.submission");
  });

  return {
    start,
    complete,
    reset,
    retId: retId.current,
  };
}

export default alerter;
