/**
 * Notifications will follow the structure below:
 * {
 *     "domain": domain_name, e.g., overlap_analysis, notifications,
 *     "notification": {
 *         "id" : random id from notification,
 *         "type": "success | error | warning | info,
 *         "title" : title of the notification,
 *         "description": description of the notification, used to display more details about error | success | etc.
 *         "context": used to differentiate between kinds of notifications. Used only in the domain notifications
 *         "read": true | false,  used specifically for domain notifications
 *         "created_at" : The date this notification was created,
 *         "metadata" : {
 *            // Details about the notification, used for specific cases
 *         }
 *     }
 * }
 * */

import { useEffect, useRef } from "react";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  addOverlapData,
  addOverlapDataError,
  OVERLAP_ANALYSIS,
} from "slices/overlapAnalysis";
import {
  addSegmentCalculations,
  SEGMENT_CALCULATIONS,
} from "slices/segmentCalculations";
import { addNotification, NOTIFICATIONS } from "slices/notifications";
import { format } from "date-fns";
import { useActionAlerter } from "../../components/Alert";
import { useMemoizedCallback } from "../../hooks";
import QUERY_KEYS, { getSegmentsListKey } from "../../constants/queryKeys";
import useAccount from "../../contexts/accountContext";

const SUCCESS = "success";
const ERROR = "error";
const CREATE_SEGMENT = "create_segment";
const DISTRIBUTIONS = "distributions";

export default function useWebSocket({ username }) {
  const { accountId } = useAccount();
  const queryClient = useQueryClient();
  const websocket = useRef(null);
  const dispatch = useDispatch();
  const calcSelector = useSelector((state) => state.segmentCalculations);
  const { complete } = useActionAlerter();
  const webSocketUrl = `${process.env.REACT_APP_WS_URL}?user=${username}`;

  // For APIs, build the queryKey based on the response and invalidate the cache,
  // so it'll update the data

  // if (data.domain === 'notifications') {
  // const queryKey = [...data.entity, data.id].filter(Boolean);
  // queryClient.invalidateQueries(queryKey);
  // }

  // Avoiding to abstract this handleResponse function since it looks like a temporary solution. We'll probably
  // need an API for notifications and this overlap here still a workaround
  const handleResponse = useMemoizedCallback((data) => {
    const { type, metadata, title, context } = data.notification;
    if (data.domain === OVERLAP_ANALYSIS) {
      if (type === SUCCESS) {
        dispatch(addOverlapData(metadata));
      }
      if (type === ERROR) {
        complete(false, title);
        dispatch(addOverlapDataError(metadata));
      }
    }
    if (data.domain === SEGMENT_CALCULATIONS) {
      const segmentId = calcSelector?.segmentID;

      // snowplow does 3 queries in case of error.
      // this is returning false positives to the websocket.
      // need to suppress reporting calculation GET errors for now.

      if (type === SUCCESS && segmentId === metadata?.request_id) {
        // websocket returning up to three calculation results due to snowflake query setup.
        // need hack to ensure we're only showing the correct calculation and notification
        dispatch(addSegmentCalculations(metadata));
        dispatch(
          addNotification({
            title: "Calculation Complete",
            description: `Your calculations are now ready.`,
            createdAt: format(Date.now(), "MMM d, yyyy"),
            read: false,
          })
        );
      }

      if (type === ERROR && segmentId === metadata?.request_id) {
        // websocket returning up to three calculation results due to snowflake query setup.
        // need hack to ensure we're only showing the correct calculation and notification
        dispatch(addSegmentCalculations(metadata));
        dispatch(
          addNotification({
            title: "Calculation Error",
            description: `There was an error in calculating your data. Please try again later.`,
            createdAt: format(Date.now(), "MMM d, yyyy"),
            read: false,
          })
        );
      }
    }

    if (data.domain === NOTIFICATIONS) {
      if (context === CREATE_SEGMENT) {
        dispatch(addNotification(data.notification));
        const queryKey = getSegmentsListKey(null, "AUDIENCES");
        queryClient.invalidateQueries(queryKey, { refetchInactive: true }); // Invalidating grid

        // segment_id is available only in success
        if (type === SUCCESS) {
          queryClient.invalidateQueries(
            [QUERY_KEYS.SEGMENT_DETAILS, { id: metadata.segment_id }],
            { refetchInactive: true }
          ); // Invalidating single segment
        }
      }
      if (context === DISTRIBUTIONS) {
        dispatch(addNotification(data.notification));
        queryClient.invalidateQueries(
          [QUERY_KEYS.DISTRIBUTIONS, { accountId }],
          {
            refetchInactive: true,
          }
        );
      }
    }
  });

  const initWebSocket = useMemoizedCallback(() => {
    websocket.current = new WebSocket(webSocketUrl);
    websocket.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      handleResponse(data);
    };

    websocket.current.onopen = () => {
      // eslint-disable-next-line no-console
      console.info("WS Connected");
    };
  });

  useEffect(() => {
    if (username) {
      initWebSocket();
    }

    return () => {
      websocket.current?.close();
    };
  }, [queryClient, username]);
}
