import { useTranslation } from "react-i18next";
import SectionCard from "elements/Cards/SectionCard";
import Segment from "./Segment";

export default function SelectedSegment({ segment }) {
  const { t } = useTranslation();

  return (
    <SectionCard
      title={t("Selected Segment")}
      headerSxProps={{
        backgroundColor: "primary.main",
        color: "white",
      }}
      dividerSxProps={{
        opacity: 0,
      }}
      sx={{
        mb: 4,
      }}
    >
      <Segment segment={segment} />
    </SectionCard>
  );
}
