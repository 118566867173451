import { combineReducers } from "@reduxjs/toolkit";
import { reducer as authenticationReducer } from "../slices/authentication";
import { reducer as userReducer } from "../slices/user";
import { reducer as overlapAnalysisReducer } from "../slices/overlapAnalysis";
import { reducer as segmentCalculationsReducer } from "../slices/segmentCalculations";
import { reducer as roleReducer } from "../slices/role";
import { reducer as notificationsReducer } from "../slices/notifications";
import { reducer as audienceBuilderReducer } from "../slices/audienceBuilder";

const appReducer = combineReducers({
  authentication: authenticationReducer,
  user: userReducer,
  overlapAnalysis: overlapAnalysisReducer,
  segmentCalculations: segmentCalculationsReducer,
  role: roleReducer,
  notifications: notificationsReducer,
  audienceBuilder: audienceBuilderReducer,
});

const rootReducer = (state, action) => {
  // Cleaning every state after logout
  if (action.type === "authentication/logout") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
