import EditIcon from "@mui/icons-material/Edit";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import BluButton from "./BluButton";

export default function EditButton(props) {
  const {
    title = "Edit",
    isIcon = false,
    btnSize = "medium",
    ...otherProps
  } = props;

  return (
    <BluButton
      title={title}
      color="secondary"
      size={btnSize}
      icon={isIcon ? <EditIcon fontSize={btnSize} /> : <EditOutlinedIcon />}
      isIcon={isIcon}
      sx={{ alignItems: "stretch" }}
      {...otherProps}
    />
  );
}
