import { Box, Card, Typography, Divider, CardActions } from "@mui/material";
// import SeverityPill from "../SeverityPill";

export default function StatCard({ children, title, actions, sx = {} }) {
  return (
    <Card
      sx={{
        height: "100%",
        ...sx,
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          px: 3,
          py: 2,
        }}
      >
        <Typography
          color="textSecondary"
          component="h2"
          gutterBottom
          variant="overline"
        >
          {title}
        </Typography>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {children}
        </Box>
      </Box>
      {actions && (
        <>
          <Divider />
          <CardActions>{actions}</CardActions>
        </>
      )}
    </Card>
  );
}

/* <StatCard title="Recent Audiences">
      <Typography sx={{ mr: 1 }} variant="h5">
        854,355
      </Typography>
      <SeverityPill color="success">+25%</SeverityPill>
    </StatCard>

    <StatCard title="Distribution Status">
      <Typography sx={{ mr: 1 }} variant="h5">
        74%
      </Typography>
      <Box sx={{ flexGrow: 1 }}>
        <LinearProgress color="primary" value={74} variant="determinate" />
      </Box>
    </StatCard> */
