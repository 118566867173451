import axios from "../axios";
import { wait } from "../../utils";

const context = "/segments";

const isDebug = false;
export default {
  get: async ({ id = "", pageSize = 0, ...rest }) => {
    const params = id
      ? rest
      : {
          page_size: pageSize,
          ...rest,
        };

    try {
      const response = await axios.get(`${context}/${id}`, {
        params,
      });
      if (isDebug) await wait(3000);
      return response?.data;
    } catch (e) {
      throw e?.response?.data;
    }
  },
  create: async (builder) => {
    try {
      const response = await axios.post(`${context}`, {
        ...builder,
      });
      return response?.data;
    } catch (e) {
      throw e?.response?.data;
    }
  },
  edit: async ({ audienceId, audienceName }) => {
    try {
      const response = await axios.put(`${context}/${audienceId}`, {
        name: audienceName,
      });
      return response?.data;
    } catch (e) {
      throw e?.response?.data;
    }
  },
  delete: async ({ segmentId }) => {
    try {
      const response = await axios.delete(`${context}/${segmentId}`);
      return response?.data;
    } catch (e) {
      throw e?.response?.data;
    }
  },
  duplicate: async ({ audienceId, audienceName }) => {
    try {
      const response = await axios.post(`${context}/duplicate`, {
        id: audienceId,
        name: audienceName,
      });
      return response?.data;
    } catch (e) {
      throw e?.response?.data;
    }
  },
  // Copy across different client accounts
  copy: async ({ segmentId, segmentName, accountId }) => {
    try {
      const response = await axios.post(`${context}/copy`, {
        id: segmentId,
        name: segmentName,
        account: accountId,
      });
      return response?.data;
    } catch (e) {
      throw e?.response?.data;
    }
  },
  refresh: async ({ segmentId }) => {
    try {
      const response = await axios.put(`${context}/refresh`, {
        segment_id: segmentId,
      });
      return response?.data;
    } catch (e) {
      throw e?.response?.data;
    }
  },
};
