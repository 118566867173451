import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import Page from "elements/Page";
import CreateButton from "elements/Button/CreateButton";
import useAccount from "contexts/accountContext";
import { actions, features, targets, pages } from "constants/roles";
import RoleBasedGuard from "components/Guard/RoleBasedGuard";
import useRole from "components/Guard/useRole";
import CreateDistribution from "./Create";
import DistributedAudiencesDataGrid from "./DistributedAudiencesDataGrid";

export default function DistributedAudiences() {
  const { t } = useTranslation();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const { accountId } = useAccount();
  const { checkPermission } = useRole();
  const canCreateDistribution = checkPermission(
    features.distribution,
    actions.create
  );
  const canDownloadDistribution = checkPermission(
    features.distribution,
    actions.download
  );

  return (
    <RoleBasedGuard
      feature={pages.distribution}
      action={actions.view}
      target={targets.pages}
      sx={{
        px: 3,
        py: 2,
      }}
    >
      <Page
        title={t("Distributed Audiences")}
        containerProps={{ sx: { mt: 3, mb: 3, height: "80%" } }}
        actions={
          canCreateDistribution && (
            <CreateButton
              title={t("Start New Distribution")}
              tip={t("Start a new distribution wizard")}
              id="distribution_start"
              // disabled={!isAccountActive}
              onClick={() => setIsOpenDialog(true)}
            />
          )
        }
      >
        <Box sx={{ padding: 0 }}>
          <DistributedAudiencesDataGrid
            accountId={accountId}
            canDownloadDistribution={canDownloadDistribution}
          />
        </Box>
        <CreateDistribution
          isActive={isOpenDialog}
          onClose={() => setIsOpenDialog(false)}
        />
      </Page>
    </RoleBasedGuard>
  );
}
