import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import SectionCard from "elements/Cards/SectionCard";
import { useMemoizedCallback } from "hooks";
import SearchAutocomplete from "components/SearchAutocomplete";
import { useDispatch, useSelector } from "react-redux";
import useQueryAudiences from "queries/useQueryAudiences";
import Segment from "./Segment";
import { useOverlapAnalysisMutations } from "./useOverlapAnalysisMutations";
import {
  cleanOverlapData,
  removeOverlapDataById,
} from "../../slices/overlapAnalysis";

export default function ComparisonSegments({ mainSegmentId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedSegments, setSelectedSegments] = useState([]);
  const overlapAnalysis = useSelector((state) => state.overlapAnalysis);
  const { mutate: mutateOverlapData } = useOverlapAnalysisMutations();

  // Cleaning up overlap data when the component is unmounted
  useEffect(
    () => () => {
      dispatch(cleanOverlapData());
    },
    []
  );

  const onRemoveSegment = (segmentToRemove) => {
    const updatedSegments = selectedSegments.filter(
      (seg) => seg.id !== segmentToRemove.id
    );

    setSelectedSegments(updatedSegments);
    dispatch(removeOverlapDataById(segmentToRemove.id));
  };

  const handleSelectedSegments = useMemoizedCallback((segment) => {
    setSelectedSegments((prev) => [...prev, segment]);
  });

  const handleOverlapData = useMemoizedCallback((data) => {
    const segmentsWithOverlap = selectedSegments.map((segmentWithOverlap) => {
      if (segmentWithOverlap?.id === data?.overlap?.compareSegmentId) {
        return {
          ...segmentWithOverlap,
          overlap: data.overlap,
        };
      }
      return segmentWithOverlap;
    });

    setSelectedSegments(segmentsWithOverlap);
  });

  return (
    <SectionCard
      title={t("Comparison Segments")}
      headerSxProps={{
        backgroundColor: "primary.dark",
        color: "white",
      }}
      dividerSxProps={{
        opacity: 0,
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridGap: "24px",
        }}
      >
        <SearchAutocomplete
          selectedData={selectedSegments}
          onDataSelect={handleSelectedSegments}
          onClearSegments={() => setSelectedSegments([])}
          queryFunction={useQueryAudiences}
          searchDisplay="Search Audiences"
          dataPropName="tableData"
          queryKey="SEARCH_OVERLAP_ANALYSIS"
        />
        {selectedSegments.map((segment) => {
          const hasError = overlapAnalysis?.error.filter(
            (data) => data.compareSegmentId === segment.id
          )[0];
          return (
            <Box key={segment?.id}>
              <Segment
                segment={segment}
                onRemove={onRemoveSegment}
                mainSegmentId={mainSegmentId}
                showOverlap
                handleOverlapData={handleOverlapData}
                overlapData={overlapAnalysis.data}
                hasError={!!hasError}
                mutateOverlapData={mutateOverlapData}
              />
            </Box>
          );
        })}
      </Box>
    </SectionCard>
  );
}
