/* eslint-disable no-console */
import { useState, useMemo, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import DialogHeader from "elements/Dialog/DialogHeader";
import { useMemoizedCallback } from "hooks";
import SegmentsList from "./SegmentsList";
import TaxonomiesList from "./TaxonomiesList";
import { TAXONOMY_TYPE, SEGMENT_TYPE } from "../builderModel";

// calc panel height minus header height

const heightBuffer = 60;
// todo use reducer etc
export default function DataSourcesPanel(props) {
  const {
    panelWidth,
    onClose,
    isActive,
    handleBuilderItemTransfer,
    paperProps = {},
  } = props;
  const { t } = useTranslation();
  const [selectedDataItem, setSelectedDataItem] = useState(null);
  // switch selected between lists
  const { selectedSegment, selectedTaxonomy } = useMemo(
    () => ({
      selectedSegment:
        selectedDataItem?.type === SEGMENT_TYPE ? selectedDataItem.item : null,
      selectedTaxonomy:
        selectedDataItem?.type === TAXONOMY_TYPE ? selectedDataItem.item : null,
    }),
    [selectedDataItem]
  );

  useEffect(() => {
    if (!isActive) setSelectedDataItem(null);
  }, [isActive]);

  const onHandleSelectDataItem = useMemoizedCallback((item, type) => {
    // console.log("Builder Data Item Select", item, type);
    const ret = item ? { item, type } : null;
    setSelectedDataItem(ret);
  });
  return (
    <Paper
      elevation={2}
      square
      sx={{ width: panelWidth, height: "100%", margin: "0" }}
      {...paperProps}
    >
      <DialogHeader
        statusColor="primary"
        onClose={onClose}
        sxProps={{ height: `${heightBuffer}.px`, pl: "24px" }}
      >
        {t("Custom Segments and Taxonomies")}
      </DialogHeader>
      <Box
        sx={{
          px: 2.5,
          display: "flex",
          flexDirection: "column",
          height: `calc(100% - ${heightBuffer}px)`,
        }}
      >
        <Box sx={{ flex: 1, overflowY: "hidden" }}>
          <TaxonomiesList
            dataType={TAXONOMY_TYPE}
            isActive={isActive}
            handleSelect={onHandleSelectDataItem}
            handleTransfer={handleBuilderItemTransfer}
            selectedTaxonomy={selectedTaxonomy}
          />
        </Box>
        <Divider />
        <Box sx={{ flex: 1, overflowY: "hidden" }}>
          <SegmentsList
            dataType={SEGMENT_TYPE}
            isActive={isActive}
            handleSelect={onHandleSelectDataItem}
            handleTransfer={handleBuilderItemTransfer}
            selectedSegment={selectedSegment}
          />
        </Box>
      </Box>
    </Paper>
  );
}
