/* eslint-disable no-console */
import {
  createContext,
  useEffect,
  useCallback,
  useContext,
  useMemo,
  useReducer,
  useRef,
} from "react";

import { useFirstRender } from "../hooks";

const ACTIVATE = "ACTIVATE";
const RESET = "RESET";
const CLEAR = "CLEAR";

// Leave it here, so we can mock this account while developing
// const initialState = {
//   activeAccount: {
//     accountKey: "CBS",
//     clientAccountName: "CBS Corp - CBS Network",
//     id: 2,
//     logoUrl: "data/2/cbs-network.png",
//   },
//   isAccountActive: true,
//   lastActive: null,
// };

// active account context with logo, etc
const initialState = {
  activeAccount: null,
  isAccountActive: false,
  lastActive: null,
};

function getInitState(xtra = {}) {
  return { ...initialState, ...xtra };
}

function accountReducer(state, action) {
  const { type, account } = action;
  const current = state.activeAccount;
  const active = !!state.lastActive;
  switch (type) {
    case ACTIVATE:
      return {
        ...state,
        activeAccount: account,
        isAccountActive: true,
        lastActive: current,
      };
    case RESET:
      return {
        ...state,
        activeAccount: state.lastActive,
        isAccountActive: active,
        lastActive: current,
      };
    case CLEAR:
      return getInitState();
    default:
      throw new Error(`Unsupported action type: ${action.type}`);
  }
}

export const AccountContext = createContext();

export function AccountProvider(props) {
  const { children } = props;
  const [state, dispatch] = useReducer(accountReducer, getInitState());
  const value = useMemo(() => [state, dispatch], [state]);

  return (
    <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
  );
}

export default function useAccount(onAccountChange = null) {
  const isFirst = useFirstRender();
  const context = useContext(AccountContext);
  const [state, dispatch] = context;
  const changeCallbackRef = useRef(onAccountChange);
  const accountId = useMemo(
    () => (state.isAccountActive ? state.activeAccount?.id : null),
    [state.isAccountActive, state.activeAccount]
  );

  useEffect(() => {
    if (!isFirst && changeCallbackRef.current) {
      changeCallbackRef.current(state.activeAccount);
    }
  }, [state.activeAccount]);

  const clearAccount = useCallback(() => dispatch({ type: CLEAR }), []);
  const resetAccount = useCallback(() => dispatch({ type: RESET }), []);
  const activateAccount = useCallback((account) => {
    localStorage.setItem("account", JSON.stringify(account));
    dispatch({ type: ACTIVATE, account });
  }, []);

  return {
    clearAccount,
    resetAccount,
    activateAccount,
    accountId,
    ...state,
  };
}

// Account render props wrapper passing child props - with optional on change applied from parent
export function AccountWrapper({ children, onAccountChange }) {
  const { activeAccount, isAccountActive, accountId } =
    useAccount(onAccountChange);
  return children({
    activeAccount,
    isAccountActive,
    accountId,
  });
}
