import { useQuery } from "react-query";
import QUERY_KEYS from "constants/queryKeys";
import apiDistributions from "api/distributions";

const queryOptions = {
  // keepPreviousData: true,
  retry: false,
  staleTime: Infinity,
};

export default function useQueryDistributions({ accountId }) {
  return useQuery(
    [QUERY_KEYS.DISTRIBUTIONS, { accountId }],
    () => apiDistributions.get({ accountId }),
    { ...queryOptions }
  );
}
