import { useTranslation } from "react-i18next";
import { Box, Popover as PopoverMUI, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  markAllAsRead,
  removeNotificationById,
  markAsReadById,
} from "slices/notifications";
import NoNotifications from "./NoNotifications";
import List from "./List";
import MarkAllAsRead from "./MarkAllAsRead";
// import useNotificationsQuery from "../useNotificationsQuery";

export default function Popover(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { anchorEl, onClose, open, notifications, ...other } = props;
  // const { data } = useNotificationsQuery();

  return (
    <PopoverMUI
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 480, marginTop: 4 } }}
      transitionDuration={0}
      {...other}
    >
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: "primary.main",
          color: "primary.contrastText",
          display: "flex",
          justifyContent: "space-between",
          px: 3,
          py: 2.5,
        }}
      >
        <Typography color="inherit" variant="h6">
          {t("Notifications")}
        </Typography>
        <MarkAllAsRead handleMarkAllAsRead={() => dispatch(markAllAsRead())} />
      </Box>
      {notifications.data.length === 0 ? (
        <NoNotifications />
      ) : (
        <List
          notifications={notifications.data}
          handleRemoveOne={(id) => dispatch(removeNotificationById(id))}
          handleRead={(id) => dispatch(markAsReadById(id))}
        />
      )}
    </PopoverMUI>
  );
}
