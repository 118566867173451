import { createSlice } from "@reduxjs/toolkit";
import { keysToCamel } from "../utils";

export const SEGMENT_CALCULATIONS = "calculate_segment";

const initialState = {
  data: [],
  segmentID: "",
};

function filterData(state, key, action) {
  state[key] = state[key].filter(
    (data) => data.compareSegmentId !== action.payload
  );
}

const slice = createSlice({
  name: SEGMENT_CALCULATIONS,
  initialState,
  reducers: {
    addSegmentCalculations(state, action) {
      state.data = keysToCamel(action.payload);
    },
    removeSegmentCalculationsById(state, action) {
      filterData(state, "data", action);
      filterData(state, "error", action);
    },
    cleanSegmentCalculations(state) {
      state.data = [];
      state.error = [];
    },
    addSegmentCalculationsError(state, action) {
      state.error = [...state.error, keysToCamel(action.payload)];
    },
    updateCalcID(state, action) {
      state.segmentID = action.payload;
    },
  },
});

export const { reducer } = slice;

export const addSegmentCalculations = (data) => async (dispatch) => {
  dispatch(slice.actions.addSegmentCalculations(data));
};

export const removeSegmentCalculationsById = (id) => async (dispatch) => {
  dispatch(slice.actions.removeSegmentCalculationsById(id));
};

export const cleanSegmentCalculations = () => async (dispatch) => {
  dispatch(slice.actions.cleanSegmentCalculations());
};

export const addSegmentCalculationsError = (error) => async (dispatch) => {
  dispatch(slice.actions.addSegmentCalculationsError(error));
};

export const updateCalcID = (id) => async (dispatch) => {
  dispatch(slice.actions.updateCalcID(id));
};
