import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Box, Card } from "@mui/material";
import { initialTableState } from "components/AudiencesDataGrid/helpers";
import Page from "../../../elements/Page";
import CreateButton from "../../../elements/Button/CreateButton";
import SegmentsTable from "./SegmentsTable";
import Actions, { actionsNames } from "./Actions";
import ConfirmDialog from "../../../elements/Dialog/ConfirmDialog";
import useAccount from "../../../contexts/accountContext";
import ContentDialog from "./ContentDialog";
import useTableState from "../../../components/DataGrid/useTableState";
import useCustomizationStorage from "../../../components/DataGrid/useCustomizationStorage";
import useRole from "../../../components/Guard/useRole";
import { actions, pages, targets } from "../../../constants/roles";
import { useSegmentsRefresh } from "./useSegmentsMutations";
import { useActionAlerter } from "../../../components/Alert";

export default function Audiences() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { start, complete } = useActionAlerter();
  const [selectedAudiences, setSelectedAudiences] = useState([]);
  const segmentIds = selectedAudiences.map((audience) => audience.id);
  const [actionName, setActionName] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const { accountId } = useAccount();
  const { checkPermission } = useRole();
  const canAccessBuilder = checkPermission(
    pages.builder,
    actions.view,
    targets.pages
  );

  const [storage, setStorage] = useCustomizationStorage(
    "AudiencesBluDataGrid",
    {
      columnVisibilityModel: null,
      pageSize: initialTableState.pageSize,
      density: "standard",
    }
  );

  const { tableState, tableChange } = useTableState({
    ...initialTableState,
    account: accountId,
    pageSize: storage.pageSize,
  });

  const { mutate: refreshSegments } = useSegmentsRefresh({
    key: "AUDIENCES",
  });

  // Cleaning out grid selected rows when changing associated accounts (client accounts)
  useEffect(() => {
    if (selectedAudiences.length > 0) {
      setSelectedAudiences([]);
    }
  }, [accountId]);

  function handleRefreshSegments() {
    if (selectedAudiences.length > 1) {
      start();
      complete(false, "Only 1 Audience may be refreshed");
    } else {
      refreshSegments(
        { segmentId: segmentIds[0] },
        {
          onSettled: () => setSelectedAudiences([]),
        }
      );
    }
  }

  function onClickAction(clickedAction) {
    const {
      DUPLICATE,
      EDIT_NAME,
      CREATE_FROM,
      DELETE,
      ADD_TO_FOLDER,
      OVERLAP_ANALYSIS,
      COPY_ACROSS_ACCOUNTS,
      REFRESH,
    } = actionsNames;

    switch (clickedAction) {
      case OVERLAP_ANALYSIS:
        navigate(`/overlap-analysis/${selectedAudiences[0].id}`);
        break;
      case CREATE_FROM:
        navigate(
          `/audience-management/audience-builder/${selectedAudiences[0].id}`
        );
        break;
      case ADD_TO_FOLDER:
      case DELETE:
      case EDIT_NAME:
      case DUPLICATE:
      case COPY_ACROSS_ACCOUNTS:
        setActionName(clickedAction);
        setOpenDialog(true);
        break;
      case REFRESH:
        handleRefreshSegments();
        break;
      default:
        break;
    }
  }

  return (
    <Page
      title={t("Audiences")}
      actions={
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Actions
            selectedAudiences={selectedAudiences.length}
            selectedAccount={accountId}
            onClick={(clickedAction) => onClickAction(clickedAction)}
          />
          {canAccessBuilder && (
            <CreateButton
              component={RouterLink}
              to="/audience-management/audience-builder"
              tip={t("Create Audience")}
              disabled={!accountId}
            />
          )}
        </Box>
      }
    >
      <Card>
        <Box
          sx={{
            p: 3,
          }}
        >
          <SegmentsTable
            setSelectedAudiences={setSelectedAudiences}
            tableState={tableState}
            tableChange={tableChange}
            storage={storage}
            setStorage={setStorage}
            segmentIds={segmentIds}
          />
        </Box>
      </Card>
      <ContentDialog
        actionName={actionName}
        setSelectedAudiences={setSelectedAudiences}
        selectedAudiences={selectedAudiences}
        tableState={tableState}
        setOpenDialog={setOpenDialog}
        setActionName={setActionName}
        segmentIds={segmentIds}
      >
        {({
          contentTitle,
          title,
          confirmText,
          onConfirm,
          onClose,
          isLoading,
          Component,
          props,
          isConfirmDisabled,
        }) => (
          <ConfirmDialog
            content={contentTitle}
            title={title}
            isOpen={openDialog}
            onConfirm={onConfirm}
            onCancel={onClose}
            confirmText={confirmText}
            confirmColor="primary"
            confirmDisabled={isLoading || isConfirmDisabled}
          >
            <Box
              sx={{
                mt: 3,
              }}
            >
              {Component && <Component {...props} />}
            </Box>
          </ConfirmDialog>
        )}
      </ContentDialog>
    </Page>
  );
}
