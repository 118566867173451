import { Stack } from "@mui/material";
import BluButton from "../../elements/Button/BluButton";
import { wait } from "../../utils";
import alerter, { useActionAlerter } from "../../components/Alert";

export default function Toasts() {
  const { start, complete } = useActionAlerter("Edit Audience");

  const actionAlerter = async () => {
    start();
    await wait(5000);
    const ret = Math.random() > 0.5;
    complete(ret, "Specific Error message relating to problem");
  };

  return (
    <Stack spacing={2} direction="row">
      <BluButton
        title="Success"
        color="success"
        onClick={() => alerter.success("Toast Success Message!")}
      />
      <BluButton
        title="Error"
        color="error"
        onClick={() =>
          alerter.error(
            "Report an error that occured -  Long text and duration. Report an error that occured -  LongtextanddurationReportanerrorthatoccuredLongtextandduration ",
            "Toast Error with Title",
            {
              duration: 8000,
            }
          )
        }
      />
      <BluButton
        title="Warning"
        color="warning"
        onClick={() => alerter.warning("Toast Warning Option on Error")}
      />
      <BluButton
        title="Info"
        color="info"
        onClick={() =>
          alerter.info("Toast Info Message in custom component", "With Title")
        }
      />
      <BluButton
        title="Loading"
        color="info"
        onClick={() => alerter.loading("Loading ...", { duration: 8000 })}
      />
      <BluButton
        title="Processing Action"
        color="secondary"
        onClick={() => actionAlerter()}
      />
    </Stack>
  );
}
