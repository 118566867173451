import { Breadcrumbs as BreadcrumbsMUI, Link } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export default function Breadcrumbs({ breadcrumbs, onClick }) {
  const handleClick = (event, breadcrumb) => {
    event.preventDefault();
    onClick(breadcrumb);
  };

  const breadcrumbsItems = breadcrumbs.map((breadcrumb) => (
    <Link
      underline="hover"
      key={breadcrumb.id}
      color="inherit"
      variant="caption"
      href="/"
      onClick={(event) => handleClick(event, breadcrumb)}
    >
      {breadcrumb.name}
    </Link>
  ));

  return (
    <BreadcrumbsMUI
      sx={{
        mr: "5px",
        padding: "0 3px 3px",
        overflowX: "auto",
        ".MuiBreadcrumbs-separator": {
          margin: 0,
        },
        ".MuiBreadcrumbs-ol": {
          display: "grid",
          gridTemplateColumns: "1fr",
          gridAutoFlow: "column",
          justifyItems: "end",
        },
        ".MuiBreadcrumbs-li": {
          mb: "2px",
          a: {
            whiteSpace: "nowrap",
          },
        },
      }}
      maxItems={3}
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {breadcrumbsItems}
    </BreadcrumbsMUI>
  );
}
