import { ACCOUNT, CLEAR, FOLDER, SEARCH, STATUS } from "constants/general";

export const initialTableState = {
  // sortField: "created_at", //these do not sort properly until get BE support
  // sortOrder: "desc",
  page: 0,
  search: "",
  folder: null,
  status: 999, // Initial value. This id represents option "All" returned from BE
  pageSize: 20,
  account: null,
};

export function getTableChange(action, newState) {
  let ret = {};
  switch (action) {
    case ACCOUNT:
      ret = { ...initialTableState, account: newState };
      break;
    case FOLDER:
      ret = { folder: newState, page: 0 };
      break;
    case SEARCH:
    case CLEAR:
      ret = { search: newState, page: 0 };
      break;
    case STATUS:
      ret = { status: newState, page: 0 };
      break;
    default:
  }
  return ret;
}

// convert within folders Query - normalize with selects
export const convertFolders = (data) =>
  data.map((item) => ({ value: item.id, label: item.name, ...item }));
