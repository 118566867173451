import { Box } from "@mui/material";
import BluButton from "elements/Button/BluButton";
import { useState } from "react";
import DropdownFilters from "./DropdownFilters";
import PartnerList from "./PartnerList";

export default function TopPartners({ columnSpacing, rowSpacing, onDownload }) {
  const [selectedPartner, setSelectedPartner] = useState("");
  const [selectedPrecisionLevel, setSelectedPrecisionLevel] = useState("");
  const onSelectPartner = (value) => {
    setSelectedPartner(value);
  };
  const onSelectPrecisionLevel = (value) => {
    setSelectedPrecisionLevel(value);
  };
  return (
    <div>
      <DropdownFilters
        columnSpacing={columnSpacing}
        selectedPartner={selectedPartner}
        selectedPrecisionLevel={selectedPrecisionLevel}
        onSelectPartner={onSelectPartner}
        onSelectPrecisionLevel={onSelectPrecisionLevel}
      />
      <PartnerList columnSpacing={columnSpacing} rowSpacing={rowSpacing} />
      <Box mt="30px">
        <BluButton
          onClick={onDownload}
          title="Download"
          fullWidth
          sx={{
            mb: "12px",
          }}
        />
      </Box>
    </div>
  );
}
