import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import HelpIcon from "@mui/icons-material/Help";
import { Box, Slider as SliderMUI, Typography } from "@mui/material";
import BluButton from "elements/Button/BluButton";

function Slider({ params, handleChangeProbability }) {
  return (
    <SliderMUI
      disableSwap
      min={50}
      max={100}
      step={5}
      size="small"
      value={params.row.probability}
      onChange={(event, value) => handleChangeProbability(value, params.row.id)}
      valueLabelDisplay="on"
    />
  );
}

const defaults = {
  type: "string",
  align: "left",
  headerAlign: "left",
  sortable: false,
};

export const getScaleSegmentColumns = ({
  handleRemove = () => null,
  handleChangeProbability = () => null,
}) => [
  {
    ...defaults,
    field: "probability",
    headerName: "Probability %",
    flex: 1,
    renderCell: (params) => (
      <Box display="flex" flexDirection="column" width="100%">
        <Slider
          params={params}
          handleChangeProbability={handleChangeProbability}
        />
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body2">50%</Typography>
          <Typography variant="body2">100%</Typography>
        </Box>
      </Box>
    ),
  },
  {
    ...defaults,
    field: "audience_count",
    headerName: "Audience Count",
    type: "number",
    width: 150,
  },
  {
    ...defaults,
    field: "range",
    headerName: "Range",
    width: 150,
    valueGetter: (params) =>
      `${params.row.probability[0]}-${params.row.probability[1]}%`,
  },
  {
    ...defaults,
    field: "incremental_audience",
    renderHeader: () => (
      <Typography display="flex" alignItems="center">
        Incremental Audience
        <HelpIcon
          sx={{
            color: "text.secondary",
            ml: 1,
            fontSize: "20px",
          }}
        />
      </Typography>
    ),
    type: "number",
    width: 250,
  },
  {
    ...defaults,
    field: "remove_row",
    headerName: "",
    align: "center",
    width: 80,
    renderCell: (params) => (
      <BluButton
        onClick={() => handleRemove(params.row)}
        color="primary"
        tip="Remove slider"
        isIcon
        icon={
          <RemoveCircleIcon
            sx={{
              width: "30px",
              height: "30px",
            }}
          />
        }
      />
    ),
  },
];
