import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import useAccount from "contexts/accountContext";
import SimpleList from "elements/List/SimpleList";
import { CATEGORY } from "constants/general";
import SearchAutocomplete from "components/SearchAutocomplete";
import { getTaxonomies, searchTaxonomies } from "./useTaxonomiesQuery";
import { SegmentItem, CategoryItem } from "./ListItems";
import Breadcrumbs from "../components/Breadcrumbs";
import { useMemoizedCallback } from "../../../../hooks";
import QUERY_KEYS from "../../../../constants/queryKeys";

const ROOT_BREADCRUMB = {
  id: "",
  name: "Providers",
};

const paddingLeft = "29px";

const addIndexes = (payload) => {
  const { categories, attributes, breadcrumbs } = payload;
  // Don't invert categories with attributes, because as per UI requirements, attributes come last
  const data = [...categories, ...attributes].map((item, index) => ({
    idx: index, // Adding our own unique index, necessary for the FE
    ...item,
  }));

  return {
    taxonomies: data,
    breadcrumbs,
  };
};

export default function TaxonomiesList({
  selectedTaxonomy,
  dataType,
  isActive,
  handleSelect,
  handleTransfer,
}) {
  const { t } = useTranslation();
  const [categoryId, setCategoryId] = useState("");
  const { data, isLoading, isRefetching } = getTaxonomies(categoryId, {
    enabled: isActive,
    keepPreviousData: true,
    select: addIndexes,
  });
  const hideContent = isLoading || isRefetching;
  const breadcrumbs = data?.breadcrumbs
    ? [ROOT_BREADCRUMB, ...data.breadcrumbs]
    : [ROOT_BREADCRUMB];

  const onAccountChange = () => {
    setCategoryId("");
  };

  useAccount(onAccountChange);

  const onHandleBuilderTransfer = (item, meta) => {
    const params = {
      data: item,
      meta: { ...meta, breadcrumbs },
      dataType,
    };
    handleTransfer(params);
  };

  const onHandleSelect = (item) => {
    if (item.type === CATEGORY) {
      setCategoryId(item.id);
    }
    handleSelect(item, dataType);
  };

  const onClickBreadcrumb = (breadcrumb) => {
    setCategoryId(breadcrumb.id);
  };

  const onDataSelect = useMemoizedCallback((taxonomy) => {
    setCategoryId(taxonomy?.category_id);
    handleSelect(taxonomy, dataType);
  });

  const formatterDropdown = (taxonomies) =>
    taxonomies.map((taxonomy) => ({
      ...taxonomy,
      dropdown: {
        name: taxonomy.name,
        detail: taxonomy.path_description,
      },
    }));

  return (
    <Box
      sx={{
        pt: 0,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        position: "relative",
      }}
    >
      <SearchAutocomplete
        onDataSelect={onDataSelect}
        queryFunction={searchTaxonomies}
        queryDataFormatter={formatterDropdown}
        searchDisplay="Search Segments"
        showRenderOption
        dataPropName="data"
        queryKey={QUERY_KEYS.SEARCH_TAXONOMIES}
      />
      <CardHeader
        sx={{
          display: "grid",
          gridTemplateColumns: "111px calc(100% - 111px)",
          px: "4px",
          py: "14px",
          "& .MuiCardHeader-action": {
            margin: 0,
          },
        }}
        title={<Typography variant="h6">{t("Data Library")}</Typography>}
        action={
          <Breadcrumbs breadcrumbs={breadcrumbs} onClick={onClickBreadcrumb} />
        }
      />
      <SimpleList
        isLoading={hideContent}
        emptyMessage={t("No Segments/Taxonomies to Display")}
      >
        {data?.taxonomies?.map((item) => {
          const isCategory = item.type === CATEGORY;
          const isSelected = selectedTaxonomy?.id === item.id;
          const cssDisplayValue = hideContent ? "none" : "inherit";
          // change to id
          return isCategory ? (
            <Box
              key={item.idx}
              sx={{
                display: cssDisplayValue,
              }}
            >
              <CategoryItem onSelectCategory={onHandleSelect} item={item} />
            </Box>
          ) : (
            <Box
              key={item.idx}
              sx={{
                display: cssDisplayValue,
              }}
            >
              <SegmentItem
                isSelected={isSelected}
                item={item}
                dataType={dataType}
                onHandleBuilderTransfer={onHandleBuilderTransfer}
                onHandleBuilderSelect={onHandleSelect}
                sxProps={{ paddingLeft }}
              />
            </Box>
          );
        })}
      </SimpleList>
    </Box>
  );
}
