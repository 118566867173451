import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Page from "elements/Page";
import { Typography } from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBack";
import BluButton from "elements/Button/BluButton";
import useQuerySegment from "queries/useQuerySegment";
import { START } from "constants/general";
import RoleBasedGuard from "components/Guard/RoleBasedGuard";
import SelectedSegment from "./SelectedSegment";
import ComparisonSegments from "./ComparisonSegments";
import useRole from "../../components/Guard/useRole";
import { actions, features, targets } from "../../constants/roles";

export default function OverlapAnalysis() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { segmentId } = useParams();
  const { checkPermission } = useRole();
  const canRunOverlap = checkPermission(features.overlapAnalysis, actions.run);

  const {
    data: segment,
    isLoading,
    isError,
  } = useQuerySegment({
    segmentId,
    queryProps: {
      enabled: canRunOverlap,
    },
  });
  const showComponent = !isLoading && !isError;

  return (
    <Page
      title={t("Overlap Analysis ")}
      actions={
        <BluButton
          title={t("Back to Dashboard")}
          size="medium"
          icon={<ArrowBack />}
          iconPosition={START}
          onClick={() => navigate("/audience-management")}
        />
      }
    >
      <RoleBasedGuard
        feature={features.overlapAnalysis}
        action={actions.view}
        target={targets.pages}
      >
        {isError && <Typography>{t("Invalid segment")}</Typography>}
        {showComponent && (
          <>
            <SelectedSegment segment={segment} />
            <ComparisonSegments mainSegmentId={segment?.id} />
          </>
        )}
      </RoleBasedGuard>
    </Page>
  );
}
