import { Routes, Route } from "react-router-dom";
import MainLayout from "layout/MainLayout";
import {
  Login,
  StyleGuide,
  Admin,
  General,
  Customization,
  Distribution,
  DistributedAudiences,
  PartnerConnections,
  DataScienceOutlet,
  DataScience,
  AudienceScaling,
  Models,
  OverlapAnalysis,
  PersonalDashboard,
  ModelsOutlet,
} from "pages";
import AudienceManagement from "./audience-management";

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="/" element={<MainLayout />}>
        <Route path="personal-dashboard" element={<PersonalDashboard />} />
        <Route path="audience-management/*" element={<AudienceManagement />} />
        <Route path="data-science" element={<DataScienceOutlet />}>
          <Route index element={<DataScience />} />
          <Route path="data-science" element={<DataScience />} />
          <Route path="models" element={<ModelsOutlet />}>
            <Route index element={<Models />} />
            <Route
              path="audience-scaling/:segmentId"
              element={<AudienceScaling />}
            />
          </Route>
        </Route>
        <Route path="distribution" element={<Distribution />}>
          <Route index element={<PartnerConnections />} />
          <Route path="partners" element={<PartnerConnections />} />
          <Route
            path="distributed-audiences"
            element={<DistributedAudiences />}
          />
        </Route>
        <Route path="style-guide" element={<StyleGuide />} />
        <Route path="admin" element={<Admin />}>
          <Route index element={<General />} />
          <Route path="general" element={<General />} />
          <Route path="customization" element={<Customization />} />
        </Route>
        <Route path="overlap-analysis" element={<OverlapAnalysis />}>
          <Route path=":segmentId" element={<OverlapAnalysis />} />
        </Route>
      </Route>
    </Routes>
  );
}
