import BarChartIcon from "@mui/icons-material/BarChart";
import ViewInArIcon from "@mui/icons-material/ViewInAr";

export default function insightsMenuItems(t, isAccountActive) {
  return {
    title: t("Data Science"),
    id: "data_science",
    items: [
      {
        title: t("Data Science"),
        id: "data_science.data_science",
        path: "/data-science/data-science",
        isDefault: true,
        icon: <BarChartIcon />,
        visible: isAccountActive,
      },
      {
        title: t("Models"),
        id: "data_science.models",
        path: "/data-science/models",
        icon: <ViewInArIcon />,
        visible: isAccountActive,
      },
    ],
  };
}
