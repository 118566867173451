import { SvgIcon } from "@mui/material";

// Blue logo - override viewBox and color
function BluLogo(props) {
  return (
    <SvgIcon {...props}>
      <path d="M31.1762 17.133L31.1153 17.0833C27.2279 14.2798 23.4301 12.8568 19.8473 12.8568H19.0806C16.9581 12.8355 14.8456 13.1469 12.8214 13.7795C9.16687 15.0109 6.37226 16.8739 4.42678 19.3331V0H0.5V48.9965H4.44111V42.5592C4.83881 43.0382 5.27233 43.5244 5.73093 44.0177L5.78826 44.0709C9.64338 47.3392 13.6705 48.9965 17.7513 48.9965H18.5216C21.1278 49.0285 23.7187 48.597 26.171 47.7225C30.5528 45.8772 33.6018 43.127 35.2355 39.5605C36.5469 37.3107 37.2133 34.4505 37.2133 31.0437C37.2133 28.0558 36.7797 25.5823 35.9306 23.698C35.0958 21.4446 33.4943 19.2409 31.1762 17.133ZM31.4664 38.2475C28.4783 42.7863 24.3903 44.99 18.9695 44.99C17.3765 44.988 15.7908 44.7756 14.2545 44.3583C7.80539 41.9133 4.6525 37.662 4.6525 31.3667V30.1637C4.6525 27.1934 5.72735 24.3261 7.82689 21.6469C8.24689 21.3196 8.60997 20.9264 8.90174 20.4829C11.9651 18.0734 15.3437 16.8562 18.9695 16.8562C20.8019 16.8843 22.6173 17.2091 24.3437 17.8179H24.3903C25.8234 18.1727 27.6614 19.397 29.8828 21.3949C32.0767 24.0972 33.2759 27.459 33.2829 30.9266C33.2285 33.4696 32.6082 35.9693 31.4664 38.2475Z" />
      <path d="M43.7915 0H39.8647V48.9965H43.7915V0Z" />
      <path d="M79.9923 30.4831C79.9923 35.2632 78.2761 39.1454 74.8903 42.0234C71.8413 43.9964 68.8568 44.9936 66.0121 44.9936H65.1164C63.324 44.9287 61.555 44.5689 59.8819 43.929C56.7146 42.7047 54.4216 40.7955 53.0745 38.2511L53.0243 38.1695C51.7739 36.4484 51.1397 33.1127 51.1397 28.2545V12.7433H47.0947V30.4866C47.1394 31.9355 47.2927 33.379 47.5533 34.8054V34.848C48.4311 38.6379 49.8463 41.4272 51.7524 43.1447C55.0594 47.0483 59.8926 49 66.116 49H66.7824C68.342 48.9808 69.8877 48.7075 71.3577 48.1909C74.9727 47.0305 77.8641 45.1639 79.9815 42.6373V49H83.7972V12.6333H79.9923V30.4831Z" />
      <path d="M94.012 34.1631C92.531 34.1631 91.0833 34.5981 89.8519 35.413C88.6205 36.228 87.6607 37.3863 87.0939 38.7415C86.5272 40.0968 86.3789 41.588 86.6678 43.0267C86.9567 44.4654 87.6699 45.787 88.7171 46.8242C89.7644 47.8615 91.0986 48.5678 92.5512 48.854C94.0037 49.1402 95.5093 48.9933 96.8776 48.432C98.2459 47.8706 99.4154 46.92 100.238 45.7003C101.061 44.4806 101.5 43.0467 101.5 41.5798C101.5 39.6128 100.711 37.7263 99.3069 36.3354C97.9027 34.9445 95.998 34.1631 94.012 34.1631ZM94.012 46.0902C93.1114 46.0902 92.231 45.8256 91.4821 45.33C90.7332 44.8344 90.1496 44.13 89.8049 43.3058C89.4602 42.4817 89.3701 41.5748 89.5458 40.6999C89.7215 39.825 90.1552 39.0213 90.792 38.3905C91.4289 37.7597 92.2403 37.3301 93.1237 37.1561C94.007 36.9821 94.9226 37.0714 95.7547 37.4128C96.5868 37.7542 97.298 38.3323 97.7984 39.074C98.2988 39.8157 98.5658 40.6877 98.5658 41.5798C98.5649 42.7757 98.0848 43.9224 97.231 44.7681C96.3772 45.6137 95.2195 46.0892 94.012 46.0902Z" />
    </SvgIcon>
  );
}

export default BluLogo;
