import { Typography, Box } from "@mui/material";
import SeverityPill from "elements/SeverityPill";

export default function StatusDisplay(props) {
  const { status, statusTxt, count = 0, builderItemsMax } = props;
  const pillDisplay = count > 1 ? "Items" : "Item";
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Typography
        color={`${status}.main`}
        sx={{ mr: 2 }}
        // component="h3"
        // gutterBottom
        variant="overline"
      >
        {statusTxt}
      </Typography>
      <SeverityPill color={status}>{`${count} ${pillDisplay}`}</SeverityPill>
      {builderItemsMax && (
        <Typography
          position="absolute"
          top={-10}
          right={-22}
          variant="caption"
          component="div"
        >
          MAX
        </Typography>
      )}
    </Box>
  );
}
