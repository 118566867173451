import { useTranslation } from "react-i18next";
import SelectActionsField from "elements/Field/SelectActionsField";
import EditButton from "elements/Button/EditButton";
import DeleteButton from "elements/Button/DeleteButton";
import { DELETE, EDIT } from "constants/general";

export default function SelectFolders({
  options,
  value,
  loading,
  textFieldProps,
  onSelectChange,
  setActiveFolder,
}) {
  const { t } = useTranslation();

  const folderActions = [
    {
      id: 1,
      ComponentName: EditButton,
      tip: t("Edit Folder"),
      onClick: (option) =>
        setActiveFolder((previous) => ({
          ...previous,
          activeAction: EDIT,
          [EDIT]: option,
        })),
    },
    {
      id: 2,
      ComponentName: DeleteButton,
      tip: t("Delete Folder"),
      onClick: (option) =>
        setActiveFolder((previous) => ({
          ...previous,
          activeAction: DELETE,
          [DELETE]: option,
        })),
    },
  ];

  return (
    <SelectActionsField
      selectDisplay={t("Folders")}
      options={options}
      actions={folderActions}
      value={value}
      loading={loading}
      textFieldProps={textFieldProps}
      onSelectChange={onSelectChange}
    />
  );
}
