import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Divider, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Page from "elements/Page";
import SelectField from "elements/Field/SelectField";
import useTableState from "components/DataGrid/useTableState";
import {
  getTableChange,
  initialTableState,
} from "components/AudiencesDataGrid/helpers";
import useAccount from "contexts/accountContext";
import SearchField from "elements/Field/SearchField";
import { useMemoizedCallback } from "hooks";
import useCustomizationStorage from "components/DataGrid/useCustomizationStorage";
import DataScienceTable from "./DataScienceTable";

const modelTypes = [
  {
    id: 1,
    value: "prescriptive",
    label: "Prescriptive - LAL",
  },
];

export default function DataScience() {
  const { t } = useTranslation();
  const [selectedModelType, setSelectedModelType] = useState("prescriptive");
  const [selectedAudiences, setSelectedAudiences] = useState([]);
  const segmentIds = selectedAudiences.map((audience) => audience.id);
  const { accountId, isAccountActive } = useAccount();
  const navigate = useNavigate();

  const [storage, setStorage] = useCustomizationStorage("DataScienceDataGrid", {
    columnVisibilityModel: null,
    pageSize: initialTableState.pageSize,
    density: "standard",
  });

  const { tableState, tableChange } = useTableState({
    ...initialTableState,
    account: accountId,
    pageSize: storage.pageSize,
    model_type: "lal", // Keeping this value here because so far we have only one option (look alike)
  });

  const tableDispatch = useMemoizedCallback(
    (type, newState, isFromTable = false) => {
      const ret = isFromTable ? newState : getTableChange(type, newState);
      tableChange(ret);
    }
  );

  useEffect(() => {
    if (!isAccountActive) {
      navigate("/", { replace: true });
    }
  }, [isAccountActive]);

  const onSearchChange = (searchValue, type) => {
    if (searchValue.length === 0 || searchValue.trim().length >= 3) {
      tableDispatch(type, searchValue);
    }
  };

  return (
    <Page title={t("Open Data Science")}>
      <SelectField
        selectDisplay={t("Model Type")}
        options={modelTypes}
        value={selectedModelType}
        loading={false}
        textFieldProps={{
          fullWidth: true,
          size: "small",
        }}
        onSelectChange={(value) => setSelectedModelType(value)}
      />
      <Typography variant="h6" mt={4}>
        {t("Seed Segment")}
      </Typography>
      <Divider sx={{ mt: 1, mb: 3 }} />
      <SearchField
        searchDisplay={t("Search Audiences")}
        textFieldProps={{
          fullWidth: true,
          size: "small",
          sx: {
            mb: 2,
          },
        }}
        onSearchChange={onSearchChange}
        debounceTimeout={300}
        triggerValue={tableState?.search}
      />
      <DataScienceTable
        setSelectedAudiences={setSelectedAudiences}
        segmentIds={segmentIds}
        tableState={tableState}
        tableChange={tableChange}
        storage={storage}
        setStorage={setStorage}
        tableDispatch={tableDispatch}
      />
    </Page>
  );
}
