import { useState } from "react";
import { Typography, Divider, Box, Stack } from "@mui/material";
import ChipLoader from "elements/ChipLoader";
import { useTranslation } from "react-i18next";
import InfoDrawer from "elements/Dialog/InfoDrawer";
import useQuerySegment from "queries/useQuerySegment";
import { keysToCamel } from "utils";
import numbro from "numbro";
import { useFoldersSegmentsDelete } from "../useFoldersSegmentsMutations";
import useRole from "../../../../components/Guard/useRole";
import { actions, features } from "../../../../constants/roles";
import Breadcrumbs, { formatBreadcrumbsWithOperator } from "./Breadcrumbs";
import RecordsAndIndividuals from "../../AudienceBuilder/CalculationsPanel/RecordsAndIndividuals";
import TopPartners from "../../AudienceBuilder/CalculationsPanel/TopPartners";
import useDownload from "../../../../hooks/useDownload";
import useAccount from "../../../../contexts/accountContext";

const columnSpacing = 3;
const rowSpacing = 3;
function Info({
  title,
  emptyMessage = `No ${title || "data"} available...`,
  content = "",
  show = true,
  hideEmptyMessage = false,
  children,
}) {
  const textContent = Boolean(show) && content;
  const childrenContent = Boolean(show) && children;
  const hideAll = !show && hideEmptyMessage;
  return hideAll ? null : (
    <div>
      <Typography variant="body1">{title}</Typography>
      <Divider sx={{ mb: "14px" }} />
      <Typography variant="body2">{textContent}</Typography>
      {childrenContent}
      <Typography variant="body2" color="text.secondary">
        {hideEmptyMessage || textContent || childrenContent
          ? null
          : emptyMessage}
      </Typography>
    </div>
  );
}

export default function SegmentDrawer({ segmentId, ...rest }) {
  const { t } = useTranslation();
  const { checkPermission } = useRole();
  const [currentFolderDeleting, setCurrentFolderDeleting] = useState({
    id: 0,
  });

  const {
    data: {
      source = "",
      dataSource = "",
      folders = [],
      segmentData: makeUp = {},
      households = 0,
      individuals = 0,
    } = {},
    isLoading,
  } = useQuerySegment({
    segmentId,
    queryProps: {
      select: (data) => keysToCamel(data),
    },
  });

  const { mutate: deleteSegmentsFromFolder, isLoading: isLoadingDeleteFolder } =
    useFoldersSegmentsDelete(segmentId);

  function handleDeleteFolder({ id }) {
    setCurrentFolderDeleting({ id });
    deleteSegmentsFromFolder(
      { segmentId, folderId: id },
      {
        onSettled: () => {
          setCurrentFolderDeleting({ id: 0 });
        },
      }
    );
  }

  const canRemoveSegmentFromFolder = checkPermission(
    features.segmentsFolders,
    actions.delete
  );

  // calc data hard coded for testing while we wait for backend api to be ready to send save/send data
  const calculationData = makeUp?.calculation_data || {
    distinctBluIds: "23497303",
    distinctBluIdsByEmail: "21768858",
    distinctBluIdsByIps: "20230183",
    distinctBluIdsByMaids: "13676118",
    distinctHh: "16636664",
    emailsCount: "55926799",
    ipsCount: "68113803",
    maidsCount: "59014576",
    requestId: "46b2cccb-439c-4ddf-9b8a-c413d9bfa131",
  };
  const { activeAccount } = useAccount();
  const calculationsDate = new Date().toLocaleString();
  const { startDownload } = useDownload({
    fileName: `${activeAccount.clientAccountName}_calculation_${calculationsDate}.csv`,
  });
  const onDownload = () => {
    const downloadObj = {
      calcs: [
        {
          calc: "Individual Total",
          ind: `"${numbro(calculationData?.distinctBluIds || 0).format()}"`,
          rec: "-",
        },
        {
          calc: "Household Total",
          ind: `"${numbro(calculationData?.distinctHh || 0).format()}"`,
          rec: "-",
        },
        {
          calc: "Email",
          ind: `"${numbro(
            calculationData?.distinctBluIdsByEmail || 0
          ).format()}"`,
          rec: `"${numbro(calculationData?.emailsCount || 0).format()}"`,
        },
        {
          calc: "Maids",
          ind: `"${numbro(
            calculationData?.distinctBluIdsByMaids || 0
          ).format()}"`,
          rec: `"${numbro(calculationData?.maidsCount || 0).format()}"`,
        },
        {
          calc: "Ip Addresses",
          ind: `"${numbro(
            calculationData?.distinctBluIdsByIps || 0
          ).format()}"`,
          rec: `"${numbro(calculationData?.ipsCount || 0).format()}"`,
        },
        {
          calc: "________________",
          ind: `"________________"`,
          rec: `"________________________"`,
        },
        {
          calc: "theTradeDesk",
          ind: `"1,152,922"`,
          rec: `"-"`,
        },
        {
          calc: "theTradeDesk",
          ind: `"1,152,922"`,
          rec: `"-"`,
        },
        {
          calc: "theTradeDesk",
          ind: `"1,152,922"`,
          rec: `"-"`,
        },
      ],
    };
    startDownload(downloadObj);
  };

  return (
    <InfoDrawer isLoading={isLoading} spacing={3} p={3} {...rest}>
      <Info
        show={makeUp?.builderItems?.length > 0}
        title={t("Audience Make Up")}
        content={t("Provides make up of the segment and boolean logic")}
      />
      <Info
        show={makeUp?.builderItems?.length > 0}
        hideEmptyMessage
        title={t("Info")}
      >
        {makeUp?.builderItems?.map((builder) => {
          const startOperator = builder.exclude ? t("Exclude") : t("Include");

          return (
            <Box key={builder?.id}>
              {builder?.itemsOperator && (
                <Divider textAlign="center" sx={{ marginY: 2 }}>
                  <Typography variant="h6">{builder.itemsOperator}</Typography>
                </Divider>
              )}
              {builder?.dataItems.map(({ id, dataName, meta }) => (
                <Breadcrumbs
                  key={id}
                  id={id}
                  breadcrumbs={formatBreadcrumbsWithOperator(
                    dataName,
                    id === 1 ? startOperator : builder.itemsOperator,
                    meta?.breadcrumbs
                  )}
                />
              ))}
            </Box>
          );
        })}
      </Info>
      <Info title={t("Source")} content={source} />
      <Info
        show={individuals || households}
        title={t("Size")}
        emptyMessage={t("0 Individuals or Households")}
        content={`${numbro(individuals || households).format()} ${
          individuals ? t("Individuals") : t("Households")
        }`}
      />
      <Info title={t("Data Source")} content={dataSource} />
      <Info
        show={folders.length > 0}
        title={t("Associated Folders")}
        emptyMessage={t("No associated folders...")}
      >
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: "12px" }}>
          {folders.map((folder) => (
            <ChipLoader
              key={folder.id}
              label={folder.name}
              disabled={isLoadingDeleteFolder}
              isLoading={currentFolderDeleting.id === folder.id}
              {...(canRemoveSegmentFromFolder && {
                onDelete: () => handleDeleteFolder(folder),
              })}
            />
          ))}
        </Box>
      </Info>
      {calculationData && (
        <Info
          show
          title={t("Calculations")}
          emptyMessage={t("No calculations available...")}
        >
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: "12px" }}>
            <Stack spacing={3} divider={<Divider />}>
              <RecordsAndIndividuals
                columnSpacing={columnSpacing}
                rowSpacing={rowSpacing}
                data={calculationData}
              />
              <TopPartners
                columnSpacing={columnSpacing}
                rowSpacing={rowSpacing}
                onDownload={onDownload}
              />
            </Stack>
          </Box>
        </Info>
      )}
    </InfoDrawer>
  );
}
