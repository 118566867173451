const downloadFile = ({ data, fileName, fileType }) => {
  const blob = new Blob([data], { type: fileType });

  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

const exportToCsv = (data, fileName) => {
  // Headers for each column
  const headers = ["Calculation, Individual, Matched Records"];
  // Convert users data to a csv
  const calcCsv = data.calcs.reduce((acc, user) => {
    const { calc, ind, rec } = user;
    acc.push([calc, ind, rec].join(","));
    return acc;
  }, []);

  downloadFile({
    data: [...headers, ...calcCsv].join("\n"),
    fileName,
    fileType: "text/csv",
  });
};

export default function useDownload({ fileName = "blu-data.csv" }) {
  return {
    startDownload: (data) => exportToCsv(data, fileName),
  };
}
