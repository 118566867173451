/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import { useMutation, useQueryClient } from "react-query";
import { useActionAlerter } from "../../../components/Alert";
import QUERY_KEYS from "../../../constants/queryKeys";
import foldersApi from "../../../api/folders";

// Helper fnc
function mutateFolders(action, api) {
  const queryClient = useQueryClient();
  const { start, complete } = useActionAlerter(`${action} Folder`);

  return useMutation(api, {
    onMutate: async () => {
      start(); // start alerter
      await queryClient.cancelQueries([QUERY_KEYS.FOLDERS]); // cancel any active folder queries
    },
    onSettled: async (data, err) => {
      const isSuccess = !!(!err && data);
      complete(isSuccess, `Description: ${err?.message}`);
      if (isSuccess) {
        await queryClient.invalidateQueries([QUERY_KEYS.FOLDERS]); // reload the folders
      }
      console.log(`${action} Folder`, isSuccess, data);
    },
  });
}

export const useFoldersDelete = (selectedId) => {
  const queryClient = useQueryClient();
  const { start, complete } = useActionAlerter("Delete Folder");

  return useMutation(foldersApi.delete, {
    onMutate: async (folderData) => {
      start(); // start alerter
      await queryClient.cancelQueries([QUERY_KEYS.FOLDERS]); // cancel any active folder queries
      // return id of deleted for context use
      return { deleteId: folderData.id };
    },
    // can use onSettled versus onError, onSuccess
    onSettled: async (data, err, _variables, context) => {
      const isSuccess = !!(!err && data);
      complete(isSuccess, `Description: ${err?.message}`);
      // queryClient.invalidateQueries([tableKey])
      // check context deleteId versus selected; could also use variables
      console.log(
        "Delete Folder",
        isSuccess,
        data,
        context.deleteId === selectedId
      );
    },
  });
};

export const useFoldersCreate = () =>
  mutateFolders("Create", foldersApi.create);

export const useFoldersEdit = () => mutateFolders("Edit", foldersApi.edit);
