/* eslint-disable no-console */
import { forwardRef, useMemo, memo, useCallback } from "react";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { Virtuoso } from "react-virtuoso";
import { useTranslation } from "react-i18next";
import { useMemoizedCallback } from "hooks";
import useAccount, { AccountWrapper } from "contexts/accountContext";
import useTableState from "components/DataGrid/useTableState";
import SimpleList from "elements/List/SimpleList";
import BluButton from "elements/Button/BluButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchField from "elements/Field/SearchField";
import { useInfiniteSegmentsQuery } from "../builderQueries";
import { SegmentItem } from "./ListItems";
import { ListBreadcrumbs } from "../components/common";
import { initialListState, getListChange } from "../helpers";

const isDebug = false;

const isAutoLoad = true;

function SegmentBreadcrumbs(props) {
  const { results = 0, total = 0, handleNext } = props;
  const status = `${results.length} of ${total}`;
  const breadcrumbs = [
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Link
      underline="hover"
      key="1"
      variant="caption"
      color="inherit"
      component="button"
      // href="/"
      onClick={() => handleNext()}
    >
      Segments
    </Link>,
    <Typography key="2" fontWeight="500" variant="caption" color="text.primary">
      {status}
    </Typography>,
  ];
  return <ListBreadcrumbs>{breadcrumbs}</ListBreadcrumbs>;
}

function ListFooter({ context: { isLoadingMore, hasNext, loadNext } }) {
  const { t } = useTranslation();
  const displayTxt = useMemo(() => {
    let txt = hasNext ? t("LOAD MORE SEGMENTS") : t("All Segments Loaded");
    if (isLoadingMore) txt = t("LOADING SEGMENTS...");
    return txt;
  }, [hasNext, isLoadingMore]);

  return (
    <Divider variant="middle" textAlign="center" light>
      {hasNext ? (
        <BluButton
          onClick={() => loadNext()}
          color="primary"
          size="small"
          sx={{
            p: 0,
            "&.Mui-disabled": {
              color: "primary.main",
              opacity: 0.5,
            },
          }}
          title={displayTxt}
          disabled={isLoadingMore}
          variant="text"
          iconPosition="START"
          icon={<RefreshIcon fontSize="inherit" />}
        />
      ) : (
        <Typography
          color="primary.dark"
          variant="overline"
          sx={{ lineHeight: 1 }}
        >
          {displayTxt}
        </Typography>
      )}
    </Divider>
  );
}

// need to remove overflow etc
const SegList = forwardRef(
  ({ context: { isLoading }, children, style }, listRef) => {
    const { t } = useTranslation();
    return (
      <SimpleList
        isLoading={isLoading}
        emptyMessage={t("No Segments to Display")}
        isControlOverflow={false}
        listProps={{ ref: listRef, style: { padding: 0, ...style, margin: 0 } }}
      >
        {children}
      </SimpleList>
    );
  }
);

const SegmentRow = memo((props) => {
  const {
    segment,
    isSelected,
    dataType,
    onHandleBuilderTransfer,
    onHandleBuilderSelect,
  } = props;
  return (
    segment?.refreshed_at !== "Processing" && (
      <SegmentItem
        item={segment}
        isSelected={isSelected}
        dataType={dataType}
        onHandleBuilderTransfer={onHandleBuilderTransfer}
        onHandleBuilderSelect={onHandleBuilderSelect}
      />
    )
  );
});

export default function SegmentsList(props) {
  const { dataType, handleSelect, selectedSegment, handleTransfer, isActive } =
    props;
  const { t } = useTranslation();
  // get account for initial table state
  const { accountId, isAccountActive } = useAccount();

  const { tableState, tableChange } = useTableState({
    ...initialListState,
    account: accountId,
  });
  const {
    pagesData: results,
    pageTotalSize: totalResults,
    hasPages,
    isLoading,
    isRefetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    // error,
  } = useInfiniteSegmentsQuery(tableState, isAccountActive && isActive);

  const { isLoadingList, isLoadingMore } = useMemo(
    () => ({
      isLoadingList: isLoading || isRefetching,
      isLoadingMore: isLoading || isRefetching || isFetchingNextPage,
    }),
    [isRefetching, isLoading, isFetchingNextPage]
  );

  // query fetch next
  const fetchNext = useMemoizedCallback((override = {}) => {
    if (hasPages && hasNextPage && !isLoadingMore) {
      handleSelect(null); // clear selection
      fetchNextPage(override);
    }
    if (isDebug)
      console.log("SEGMENTS LIST STATE CHANGE", hasNextPage, results);
  });

  // end of list - next
  const onListEnd = useMemoizedCallback((idx) => {
    if (isDebug) console.log("List End", idx);
    // reload if data available,
    if (isAutoLoad && hasNextPage) {
      fetchNext();
    }
  });

  const computeItemKey = useCallback((_index, item) => item.id, [results]);

  const listDispatch = useMemoizedCallback(
    (type, newState, isFromList = false) => {
      const ret = isFromList ? newState : getListChange(type, newState);
      tableChange(ret);
      if (isDebug) console.log("SEGMENTS LIST STATE CHANGE", type, newState);
    }
  );
  // passed to wrapper
  // needs to reset page, folders search etc
  // account changes will reset builder - use lastActive and confirm?
  const onAccountChange = useMemoizedCallback((act) => {
    if (isDebug) console.log("SEGMENTS LIST ON ACCOUNT CHANGE", act);
    const actId = act ? act.id : null;
    if (actId) {
      listDispatch("account", actId);
    }
  });
  // use wrapped version so that table dispatch etc exposed
  // pass to list, filters, etc lastActive, activeAccount, isAccountActive
  // useAccount(onAccountChange);
  const onHandleBuilderSelect = useMemoizedCallback((item) => {
    // console.log("Builder Segment Select", item, meta);
    handleSelect(item, dataType);
  });

  const onHandleBuilderTransfer = useMemoizedCallback((item, meta) => {
    // console.log("Builder Segment Transfer", item, meta);
    const params = { data: item, meta, dataType };
    handleTransfer(params);
  });

  const itemContent = useCallback(
    (_, segment) => {
      const isSelected = selectedSegment?.id === segment.id;
      const rowProps = {
        dataType,
        onHandleBuilderSelect,
        onHandleBuilderTransfer,
        isSelected,
        segment,
      };
      return <SegmentRow {...rowProps} />;
    },
    [selectedSegment]
  );

  const onSearchChange = (val) => {
    const searchValue = val.trim();
    if (searchValue.length >= 3 || val.length === 0) {
      listDispatch("search", val);
    }
  };

  return (
    <AccountWrapper onAccountChange={onAccountChange}>
      {() => (
        <Box
          sx={{
            pt: 0,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            position: "relative",
          }}
        >
          <SearchField
            searchDisplay={t("Search Segments")}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              sx: {
                mt: 2,
              },
            }}
            onSearchChange={onSearchChange}
            debounceTimeout={300}
            triggerValue={tableState?.search}
          />
          <CardHeader
            sx={{
              px: "4px",
              py: "14px",
              "& .MuiCardHeader-action": { margin: 0, alignSelf: "center" },
            }}
            title={<Typography variant="h6">{t("Custom Segments")}</Typography>}
            action={
              <SegmentBreadcrumbs
                results={results}
                total={totalResults}
                handleNext={fetchNext}
              />
            }
          />
          <Virtuoso
            context={{
              isLoading: isLoadingList,
              isLoadingMore,
              hasNext: hasNextPage,
              loadNext: fetchNext,
            }}
            style={{ height: "100%" }}
            data={results}
            defaultItemHeight={24}
            fixedItemHeight
            endReached={onListEnd} // last idx this fires once on reach bottom and again if more data
            // atBottomStateChange={onListEnd} // bottomChange - this fires on all changes
            computeItemKey={computeItemKey}
            // followOutput
            itemContent={itemContent}
            components={{
              List: SegList,
              Footer: hasPages ? ListFooter : null,
            }}
          />
        </Box>
      )}
    </AccountWrapper>
  );
}
