import { Chip, CircularProgress } from "@mui/material";

export default function ChipLoader({
  label,
  isLoading = false,
  disabled = false,
  onDelete,
  loaderSize = 16,
}) {
  return (
    <Chip
      variant="outlined"
      color="primary"
      size="small"
      disabled={disabled || isLoading}
      deleteIcon={
        isLoading ? (
          <CircularProgress color="inherit" size={loaderSize} />
        ) : null
      }
      label={label}
      onDelete={onDelete}
    />
  );
}
