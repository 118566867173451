import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

// In case we have to do something before/after the request, we should use interceptors
// https://github.com/axios/axios#interceptors

export default instance;
