import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import RoleBasedGuard from "components/Guard/RoleBasedGuard";
import { useActionAlerter } from "components/Alert";
import SectionCard from "elements/Cards/SectionCard";
import useAccount from "contexts/accountContext";
import QUERY_KEYS from "constants/queryKeys";
import { actions, features } from "constants/roles";
import LogoUploader from "./LogoUploader";
import { useCustomizationMutations } from "./useCustomizationMutations";
import useSignedUploadUrlQuery from "./useSignedUploadUrlQuery";

function NoAccountSelected({ content }) {
  return <Box>{content}</Box>;
}

export default function Customization() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [selectedImage, setSelectedImage] = useState();
  const inputFileRef = useRef(null);
  const { accountId, activeAccount, activateAccount } = useAccount();
  const { start, complete } = useActionAlerter("Uploaded logo");
  const { mutate, isLoading: isUploadingImage } = useCustomizationMutations();
  const {
    data: uploadUrl,
    refetch: getSignedUploadUrl,
    isLoading: isGettingUploadUrl,
    isError,
    error,
  } = useSignedUploadUrlQuery(accountId, selectedImage?.name);

  function cleanImage() {
    setSelectedImage(null);
    inputFileRef.current.value = null;
  }

  // This function will be triggered when the file field change
  const onImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const onCancelClick = () => {
    cleanImage();
  };

  const onAddClick = () => {
    inputFileRef.current.click();
  };

  useEffect(() => {
    if (uploadUrl) {
      mutate(
        { uploadUrl, selectedImage },
        {
          onSettled: (data, err) => {
            queryClient.removeQueries([
              QUERY_KEYS.ADMIN_CUSTOMIZATION,
              { accountId, fileName: selectedImage?.name },
            ]);
            complete(!err, `Description: ${err?.message}`);
            activateAccount({
              ...activeAccount,
              logoUrl: `data/${accountId}/${selectedImage?.name}`,
            });
            cleanImage();
          },
        }
      );
    }
  }, [uploadUrl]);

  // Closing the toast in case there is an error while getting the URL
  useEffect(() => {
    if (isError) {
      complete(false, error?.error);
    }
  }, [isError]);

  const onSave = () => {
    start();
    getSignedUploadUrl();
  };

  return (
    <SectionCard sx={{ minHeight: "220px" }}>
      <RoleBasedGuard feature={features.clientLogo} action={actions.create}>
        {accountId ? (
          <LogoUploader
            onSave={onSave}
            selectedImage={selectedImage}
            onAddClick={onAddClick}
            onCancelClick={onCancelClick}
            inputFileRef={inputFileRef}
            onImageChange={onImageChange}
            isUploading={isGettingUploadUrl || isUploadingImage}
          />
        ) : (
          <NoAccountSelected
            content={t("You should select an account to upload a logo")}
          />
        )}
      </RoleBasedGuard>
    </SectionCard>
  );
}
