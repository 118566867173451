import { Box, Typography } from "@mui/material";
// import EqualizerIcon from "@mui/icons-material/Equalizer";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import BallotIcon from "@mui/icons-material/Ballot";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import Tooltip from "@mui/material/Tooltip";
import { Link as RouterLink } from "react-router-dom";
import BluButton from "elements/Button/BluButton";
import { convertJSONDateForTableDisplay, handleDataColumn } from "utils";

const columnDefaults = {
  type: "string",
  sortable: true,
  align: "left",
  headerAlign: "left",
};

export default function getTableColumns(
  handleInfo = null,
  defaults = columnDefaults
) {
  const columns = [
    {
      ...defaults,
      field: "name",
      headerName: "Audiences",
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>
          <Tooltip title={params.value}>
            <Typography
              variant="body1"
              componet="div"
              color="primary"
              lineHeight={1.2}
              sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
            >
              {params.value}
            </Typography>
          </Tooltip>
          <Typography color="textSecondary" variant="caption">
            {params.row.id}
          </Typography>
        </Box>
      ),
      minWidth: 140,
    },
    {
      ...defaults,
      field: "created_by",
      headerName: "Created by",
      hide: true,
      flex: 1,
      minWidth: 110,
      maxWidth: 300,
    },
    {
      ...defaults,
      field: "created_at",
      headerName: "Created date",
      type: "date",
      hide: true,
      valueGetter: (params) => convertJSONDateForTableDisplay(params.value),
      width: 135,
    },
    {
      ...defaults,
      field: "updated_at",
      headerName: "Updated",
      type: "date",
      valueGetter: (params) => convertJSONDateForTableDisplay(params.value),
      width: 105,
    },
    {
      ...defaults,
      field: "refreshed_at",
      headerName: "Refreshed date",
      valueGetter: (params) => handleDataColumn(params.value),
      width: 150,
    },
    {
      ...defaults,
      field: "source",
      headerName: "Source",
      width: 130,
    },
    {
      ...defaults,
      field: "individuals",
      headerName: "Individuals",
      type: "number",
      width: 110,
    },
    {
      ...defaults,
      field: "households",
      headerName: "Households",
      type: "number",
      width: 120,
    },
    /* {
      ...defaults,
      field: "insights",
      headerName: "Insights",
      headerAlign: "center",
      align: "center",
      sortable: false,
      width: 100,
      renderCell: (params) => (
        <BluButton
          component={RouterLink}
          to={`/insights/demographics?segment=${params?.row?.id}`}
          color="primary"
          tip="Go to Insights View"
          isIcon
          icon={<EqualizerIcon />}
        />
      ),
    }, */
    {
      ...defaults,
      field: "modeled",
      headerName: "Modeled",
      headerAlign: "center",
      align: "center",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        const status = params.value;
        if (status === "complete" || status === "processing") {
          return (
            <BluButton
              component={RouterLink}
              to="/data-science/models"
              tip="Go to Models Page"
              isIcon
              icon={<BallotIcon />}
              sx={{
                color: `${status === "complete" ? "primary" : "neutral.300"}`,
              }}
            />
          );
        }
        return "";
      },
    },
    {
      ...defaults,
      field: "distibution_status",
      headerName: "Distribution Status",
      headerAlign: "center",
      align: "center",
      sortable: false,
      width: 175,
      renderCell: (params) => {
        if (!params?.row?.distribution_status) return <span />;
        return (
          <Tooltip title={params?.row?.distribution_status}>
            <CloudDoneIcon
              sx={{
                color:
                  params?.row?.distribution_status === "processing"
                    ? "neutral.300"
                    : "primary.main",
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      ...defaults,
      field: "info",
      headerName: "Info",
      align: "center",
      headerAlign: "center",
      sortable: false,
      width: 60,
      renderCell: (params) => (
        <BluButton
          onClick={() => handleInfo(params.row)}
          color="inherit"
          tip="Details on this segment"
          isIcon
          icon={<MoreHorizIcon />}
        />
      ),
    },
  ];
  return columns;
}
