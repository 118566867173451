import { useMutation, useQueryClient } from "react-query";
import { useActionAlerter } from "components/Alert";
import QUERY_KEYS from "constants/queryKeys";
import apiDistributions from "api/distributions";

export function useMutationDistributions({ accountId }) {
  const queryClient = useQueryClient();
  const { start, complete } = useActionAlerter("Create Distribution");

  return useMutation(apiDistributions.post, {
    onMutate: async () => {
      start();

      await queryClient.cancelQueries([QUERY_KEYS.DISTRIBUTIONS], {
        accountId,
      });
    },
    onSuccess: async () => {
      complete(true);
      await queryClient.invalidateQueries([
        QUERY_KEYS.DISTRIBUTIONS,
        { accountId },
      ]);
    },
    onError: async (error = "failed to create distribution") => {
      complete(false, `Description: ${error}`);
    },
  });
}
