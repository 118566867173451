/* eslint-disable no-console */
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
// import Box from "@mui/material/Box";
import CreateButton from "elements/Button/CreateButton";

// Selects with optionsl  displayText:
/* <ListItemIcon>
    <ChevronRightIcon />
  </ListItemIcon>
  <ListItemText primary="Demographics" /> */

// displayText={<SegmentListTip segment={seg} />}

// Basic List Item not selected - selection
// pass meta for additional use
export function DefaultListItem(props) {
  const {
    item,
    onSelectItem,
    meta,
    displayText,
    sxProps = {},
    listBtnProps = {},
  } = props;

  const { id, name } = item;
  const Display = displayText || (
    <ListItemText
      id={id}
      primary={name}
      primaryTypographyProps={{ noWrap: true }}
    />
  );

  return (
    <ListItemButton
      disableGutters
      onClick={() => onSelectItem(item, meta)}
      sx={{
        padding: "0 6px",
        borderRadius: "2px",
        height: "24px",
        ...sxProps,
      }}
      {...listBtnProps}
    >
      {Display}
    </ListItemButton>
  );
}

// selected list item decorated with actions, and display (future drag zone)
// use displayText={<SegmentListTip segment={seg} />} for tip
// tbd item data; optional actions?
export function SelectedListItem(props) {
  const {
    item,
    onActionItem,
    meta,
    displayText,
    sxProps = {},
    listBtnProps = {},
  } = props;

  const { id, name } = item;

  const Display = displayText || (
    <ListItemText
      id={id}
      primary={name}
      primaryTypographyProps={{ noWrap: true }}
    />
  );

  return (
    <ListItem
      secondaryAction={
        <CreateButton
          btnSize="small"
          isIcon
          aria-label="transfer"
          onClick={() => onActionItem(item, meta)}
        />
      }
      disablePadding
      disableGutters
      sx={{ "& .MuiListItemButton-root": { paddingRight: 4 } }}
    >
      <ListItemButton
        selected
        disableGutters
        onDoubleClick={() => onActionItem(item, meta)}
        sx={{
          padding: "0 6px",
          borderRadius: "2px",
          height: "24px",
          ...sxProps,
        }}
        {...listBtnProps}
      >
        {Display}
      </ListItemButton>
    </ListItem>
  );
}
