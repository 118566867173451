/* eslint-disable */
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Typography, Divider, Stack } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import DialogHeader from "elements/Dialog/DialogHeader";
import useDownload from "hooks/useDownload";
import { useSelector } from "react-redux";
import numbro from "numbro";
import RecordsAndIndividuals from "./RecordsAndIndividuals";
import TopPartners from "./TopPartners";
import useAccount from "../../../../contexts/accountContext";

// calc panel height minus header height

const heightBuffer = 60;
const columnSpacing = 3;
const rowSpacing = 3;

const CALC_STATES = {
  NotLoaded: "notLoaded",
  NoData: "noData",
  Valid: "valid",
};

export default function CalculationsPanel({
  panelWidth,
  onClose,
  audienceName,
  selectedSegmentID,
  setIsCalculating,
  paperProps = {},
  resetCalc
}) {
  const { t } = useTranslation();
  const [calculationData, setCalculationData] = useState("");
  const [calculationDataStatus, setCalculationDataStatus] = useState(
    CALC_STATES.NotLoaded
  );

  const setCalcData = async (socketData) => {
    const calcData = socketData.data;
    // websocket returns async data late, so we need to ensure
    // that we only change the calc panel when the segment ids match
    if (selectedSegmentID === calcData?.requestId) {
      setCalculationData(calcData);
      setIsCalculating(false);
      if (calcData?.error) {
        setCalculationDataStatus(CALC_STATES.NoData);
      } else {
        setCalculationDataStatus(CALC_STATES.Valid);
      }
    }
  };

  const segmentCalculations = useSelector(
    (state) => state.segmentCalculations
  );
  const { activeAccount } = useAccount();
  const calculationsDate = new Date().toLocaleString();
  const { startDownload } = useDownload({
    fileName: `${activeAccount.clientAccountName}_calculation_${audienceName}_${calculationsDate}.csv`,
  });
  const onDownload = () => {
    const downloadObj = {
      calcs: [
        {
          calc: "Individual Total",
          ind: `"${numbro(calculationData?.distinctBluIds || 0).format()}"`,
          rec: "-",
        },
        {
          calc: "Household Total",
          ind: `"${numbro(calculationData?.distinctHh || 0).format()}"`,
          rec: "-",
        },
        {
          calc: "Email",
          ind: `"${numbro(
            calculationData?.distinctBluIdsByEmail || 0
          ).format()}"`,
          rec: `"${numbro(calculationData?.emailsCount || 0).format()}"`,
        },
        {
          calc: "Maids",
          ind: `"${numbro(calculationData?.distinctBluIdsByMaids || 0).format()}"`,
          rec: `"${numbro(calculationData?.maidsCount || 0).format()}"`,
        },
        {
          calc: "Ip Addresses",
          ind: `"${numbro(calculationData?.distinctBluIdsByIps || 0).format()}"`,
          rec: `"${numbro(calculationData?.ipsCount || 0).format()}"`,
        },
        {
          calc: "________________",
          ind: `"________________"`,
          rec: `"________________________"`,
        },
        {
          calc: "theTradeDesk",
          ind: `"1,152,922"`,
          rec: `"-"`,
        },
        {
          calc: "theTradeDesk",
          ind: `"1,152,922"`,
          rec: `"-"`,
        },
        {
          calc: "theTradeDesk",
          ind: `"1,152,922"`,
          rec: `"-"`,
        },
      ],
    };
    startDownload(downloadObj);
  };

  useEffect(() => {
    setCalcData(segmentCalculations);
  }, [segmentCalculations]);

  useEffect(() => {
    if(resetCalc) setCalculationDataStatus(CALC_STATES.NotLoaded);
  }, [resetCalc]);

  return (
    <Paper
      elevation={2}
      square
      sx={{ width: panelWidth, height: "100%", margin: "0" }}
      {...paperProps}
    >
      <DialogHeader
        statusColor="primary"
        onClose={onClose}
        sxProps={{ height: `${heightBuffer}.px`, pl: "24px" }}
      >
        {t("Calculations")}
      </DialogHeader>
      <Box
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          height: `calc(100% - ${heightBuffer}px)`,
        }}
      >
        <Box
          sx={{
            p: 2,
            flex: 1,
            overflowY: "auto",
            border: "1px solid",
            borderColor: "borderColors.secondary",
            borderRadius: "4px",
          }}
        >
          {(calculationDataStatus === CALC_STATES.Valid && calculationData) && (
            <Stack spacing={3} divider={<Divider />}>
              <RecordsAndIndividuals
                columnSpacing={columnSpacing}
                rowSpacing={rowSpacing}
                data={calculationData}
              />
              <TopPartners
                columnSpacing={columnSpacing}
                rowSpacing={rowSpacing}
                onDownload={onDownload}
              />
            </Stack>
          )}
          {calculationDataStatus === CALC_STATES.NoData && (
            <div>
              <Typography>No Calculation Data Available</Typography>
            </div>
          )}
          {calculationDataStatus === CALC_STATES.NotLoaded && (
            <>
              <div
                style={{
                  width: "100%",
                  textAlign: "left",
                  padding: "20px",
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  Your calculation data is loading.
                </Typography>
                <br />
                <Typography>
                  Do not close your browser or navigate away from this page
                  until your calculations are complete. You will receive a
                  notification when your calculations become available.
                </Typography>
              </div>
              <div
                style={{
                  width: "100%",
                  marginTop: "60px",
                  textAlign: "center",
                }}
              >
                <CircularProgress disableShrink />
              </div>{" "}
            </>
          )}
        </Box>
      </Box>
    </Paper>
  );
}
