/* eslint-disable no-console */
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  MenuList,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Collapse,
} from "@mui/material";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import OpenInNew from "@mui/icons-material/OpenInNew";
import useAccount from "contexts/accountContext";
import useQueryAccounts from "queries/useQueryAccounts";
import { useDispatch } from "react-redux";
import { keysToCamel } from "../../../../utils";
import useQueryRole from "../../Sidebar/useQueryRole";
import { setRole } from "../../../../slices/role";
import axios from "../../../../api/axios";

function AccountItem(props) {
  const { item, selected, handleMenuItemClick } = props;
  return (
    <MenuItem
      selected={
        selected && selected?.clientAccountName === item.clientAccountName
      }
      onClick={(event) => handleMenuItemClick(event, item)}
    >
      <ListItemText primary={item.clientAccountName} sx={{ pl: 2 }} />
      <ListItemIcon>
        <OpenInNew />
      </ListItemIcon>
    </MenuItem>
  );
}

export default function Accounts({ handleMenuClose }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { accountId, activeAccount, activateAccount } = useAccount();
  const { data: accounts } = useQueryAccounts({
    select: useCallback(
      (data) =>
        data.map(({ advertiser, brand, ...account }) =>
          keysToCamel({
            ...account,
            clientAccountName: `${advertiser} - ${brand}`,
          })
        ),
      []
    ),
  });

  const { data: role } = useQueryRole({
    accountId,
    queryProps: {
      enabled: !!accountId,
    },
  });

  useEffect(() => {
    if (role) {
      dispatch(setRole(role));
    }
  }, [role]);

  const onClick = (event) => {
    event.stopPropagation(); // prevents closing
    setIsOpen((prevState) => !prevState);
  };

  const handleAccountSelect = (_event, opt) => {
    if (activeAccount?.clientAccountName === opt.clientAccountName) {
      handleMenuClose();
      return;
    }
    // BE needs it in every request (except for roles and client accounts) to check for permissions
    // It's because the role is associated with the client account selected
    axios.defaults.headers.common.account_key = opt?.accountKey;
    activateAccount(opt);
    console.log("Select Account", opt);
    handleMenuClose();
  };

  return (
    <>
      <MenuItem onClick={onClick} divider={isOpen}>
        <ListItemIcon>
          <CompareArrowsIcon />
        </ListItemIcon>
        <ListItemText primary={t("Associated Accounts")} />
        {isOpen ? (
          <ExpandMore />
        ) : (
          <ExpandLess sx={{ transform: "rotate(90deg)" }} />
        )}
      </MenuItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <MenuList component="div" disablePadding>
          {accounts?.map((acc) => (
            <AccountItem
              key={acc.id}
              item={acc}
              handleMenuItemClick={handleAccountSelect}
              selected={activeAccount}
            />
          ))}
        </MenuList>
      </Collapse>
    </>
  );
}
