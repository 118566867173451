import numbro from "numbro";
import AppRoutes from "../../routes";
import useAmplify from "./useAmplify";
import AlertToaster from "../../components/Alert/AlertToaster";
import "./styles.css";

numbro.setDefaults({
  thousandSeparated: true,
});

function App() {
  useAmplify();
  return (
    <>
      <AppRoutes />
      <AlertToaster />
    </>
  );
}

export default App;
