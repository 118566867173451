import { useMemo } from "react";
import { differenceBy } from "lodash";
import Box from "@mui/material/Box";
import useTableState from "components/DataGrid/useTableState";
import useAccount, { AccountWrapper } from "contexts/accountContext";
import { useMemoizedCallback } from "hooks";
import useSearch from "components/SearchAutocomplete/useSearch";
import Autocomplete from "components/Autocomplete";
import Typography from "@mui/material/Typography";
import { initialTableState } from "components/AudiencesDataGrid/helpers";

const initialListState = {
  ...initialTableState,
  pageSize: 50,
};

const minSearchLength = 3;

// sample custom render renderOption
function CustomRow({ props, option }) {
  return (
    <li {...props}>
      <Box sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>
        <Typography
          variant="body1"
          componet="div"
          color="primary"
          lineHeight={1.2}
          sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
        >
          {option?.dropdown?.name}
        </Typography>
        <Typography color="textSecondary" variant="caption">
          {option?.dropdown?.detail}
        </Typography>
      </Box>
    </li>
  );
}

function DataSearch({
  onDataSelect,
  selectedData,
  onClearData,
  queryFunction,
  searchDisplay,
  queryDataFormatter,
  showRenderOption,
  dataPropName,
  queryKey,
  autocompleteProps,
}) {
  const { accountId } = useAccount();
  const hasQueryDataFormatter = typeof queryDataFormatter === "function";

  const { tableState, tableChange } = useTableState({
    ...initialListState,
    account: accountId,
  });

  const handleSearchChange = useMemoizedCallback((val, type) => {
    if (type === "search") {
      const ret = { search: val, page: 0 };
      tableChange(ret);
    } else {
      tableChange({ search: "" });
    }
  });

  const {
    searchText,
    isSearchValid,
    handleInputChange,
    handleSelectChange,
    resetSearch,
  } = useSearch(handleSearchChange, onDataSelect, 500, minSearchLength);

  const isSearchEnabled = useMemo(
    () => isSearchValid && tableState.search.length >= minSearchLength,
    [tableState.search, isSearchValid]
  );

  const {
    [dataPropName]: results,
    isLoading,
    isRefetching,
  } = queryFunction(tableState, queryKey, {
    enabled: isSearchEnabled,
    ...(hasQueryDataFormatter && { select: queryDataFormatter }),
  });

  const searchResultsOptions = useMemo(
    () =>
      selectedData?.length
        ? differenceBy(results, selectedData, "id")
        : results,
    [isSearchValid, results, selectedData]
  );

  const onAccountChange = useMemoizedCallback((act) => {
    const actId = act ? act.id : null;
    resetSearch(true, false);
    tableChange({ ...initialListState, account: actId });
    if (typeof onClearData === "function") {
      onClearData();
    }
  });

  return (
    <AccountWrapper onAccountChange={onAccountChange}>
      {() => (
        <Autocomplete
          isLoading={isLoading || isRefetching}
          handleInputChange={handleInputChange}
          handleSelectChange={handleSelectChange}
          searchValue={searchText}
          searchOptions={searchResultsOptions}
          searchDisplay={searchDisplay}
          autocompleteProps={{
            sx: { flex: 1 },
            selectOnFocus: true,
            noOptionsText: "No  Results",
            getOptionLabel: (option) =>
              typeof option === "string" ? option : option.name,
            renderOption: showRenderOption
              ? // eslint-disable-next-line react/no-unstable-nested-components
                (props, option) => (
                  <CustomRow key={option.id} props={props} option={option} />
                )
              : null,
            filterOptions: (x) => x, // needed for type as search!
            ...autocompleteProps,
          }}
        />
      )}
    </AccountWrapper>
  );
}

export default function SearchAutocomplete({
  selectedData,
  onDataSelect,
  onClearData,
  queryFunction,
  searchDisplay,
  queryDataFormatter,
  showRenderOption,
  dataPropName,
  queryKey,
  autocompleteProps,
}) {
  return (
    <Box
      sx={{
        mt: 2,
        pt: 0,
        display: "flex",
        flexDirection: "row",
      }}
    >
      <DataSearch
        selectedData={selectedData}
        onDataSelect={onDataSelect}
        onClearData={onClearData}
        queryFunction={queryFunction}
        searchDisplay={searchDisplay}
        queryDataFormatter={queryDataFormatter}
        showRenderOption={showRenderOption}
        dataPropName={dataPropName}
        queryKey={queryKey}
        autocompleteProps={autocompleteProps}
      />
    </Box>
  );
}
