import { TextField } from "@mui/material";
import { getIn } from "formik";

export default function TextFieldFormik({
  name,
  size = "small",
  form,
  ...props
}) {
  const { errors, touched, setFieldValue, setFieldTouched } = form;
  const msgError = getIn(errors, name);
  const hasError = Boolean(getIn(touched, name) && msgError !== undefined);
  return (
    <TextField
      error={hasError}
      helperText={hasError ? msgError : ""}
      size={size}
      onChange={(event) => setFieldValue(name, event.target.value)}
      onBlur={(event) => {
        const val = event.target.value;
        if (val) {
          setFieldValue(name, val);
        }
        setFieldTouched(name, true);
      }}
      {...props}
    />
  );
}
