import { useQuery } from "react-query";
import QUERY_KEYS from "constants/queryKeys";
import roleApi from "api/security/role";

const defaultOptions = {
  retry: false,
  staleTime: Infinity,
};

export default function useQueryRole({ accountId, queryProps = {} }) {
  return useQuery(
    [QUERY_KEYS.ROLE, { accountId }],
    () => roleApi.get({ accountId }),
    {
      ...defaultOptions,
      ...queryProps,
    }
  );
}
