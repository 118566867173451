import axios from "./axios";

const context = "/listdata";

export default {
  get: async () => {
    try {
      const response = await axios.get(context);
      return response?.data;
    } catch (e) {
      const errorMessages = { error: e?.message };
      throw errorMessages;
    }
  },
};
