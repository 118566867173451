import { Button, IconButton, Tooltip } from "@mui/material";
import { END, START } from "../../constants/general";

// base button with optional icon and tooltip
// loading variant?
export default function BluButton(props) {
  const {
    title,
    isIcon = false,
    icon,
    tip,
    tipProps = {},
    children,
    iconPosition = END,
    ...btnProps
  } = props;

  const Btn = isIcon ? (
    <IconButton color="primary" {...btnProps}>
      {icon || children}
    </IconButton>
  ) : (
    <Button
      color="primary"
      variant="contained"
      startIcon={iconPosition === START && icon}
      endIcon={iconPosition === END && icon}
      {...btnProps}
    >
      {title || children}
    </Button>
  );
  return tip ? (
    <Tooltip title={tip} arrow {...tipProps}>
      <span>{Btn}</span>
    </Tooltip>
  ) : (
    Btn
  );
}
