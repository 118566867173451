import { useMutation, useQueryClient } from "react-query";
import apiConnections from "api/connections";
import { useActionAlerter } from "components/Alert";
import QUERY_KEYS from "../../../constants/queryKeys";

export function useConnectionsMutations({ accountId }) {
  const queryClient = useQueryClient();
  const { start, complete } = useActionAlerter("Partner connection");

  return useMutation(apiConnections.post, {
    onMutate: async () => {
      start();
      await queryClient.cancelQueries([
        QUERY_KEYS.PARTNER_CONNECTIONS,
        { accountId },
      ]);
    },
    onSettled: async (data, error) => {
      const isSuccess = !!(!error && data);
      if (isSuccess) {
        complete(true);
        queryClient.invalidateQueries([
          QUERY_KEYS.PARTNER_CONNECTIONS,
          { accountId },
        ]);
      } else {
        complete(false, `Description: ${error}`);
      }
    },
  });
}

export function useConnectionsEditMutations({ accountId }) {
  const queryClient = useQueryClient();
  const { start, complete } = useActionAlerter("Partner connection edit saved");

  return useMutation(apiConnections.put, {
    onMutate: async () => {
      start();
      await queryClient.cancelQueries([
        QUERY_KEYS.PARTNER_CONNECTIONS,
        { accountId },
      ]);
    },
    onSuccess: async () => {
      complete(true);
      queryClient.invalidateQueries([
        QUERY_KEYS.PARTNER_CONNECTIONS,
        { accountId },
      ]);
    },
    onError: async (error) => {
      complete(false, `Description: ${error}`);
    },
  });
}
