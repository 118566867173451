import { DialogActions } from "@mui/material";
import BluButton from "../Button/BluButton";

// Dialog Actions for common use
export default function DialogActionsFooter({
  children,
  onAction,
  onCancel,
  actionText = "OK",
  cancelText = "Cancel",
  actionColor = "primary",
  isActionOnly = false,
  actionDisabled = false,
  actionFocused = true,
  actionButtonProps = {},
  cancelButtonProps = {},
  ...dialogActionsProps
}) {
  return (
    <DialogActions
      {...dialogActionsProps}
      sx={{
        pt: 1,
        pr: 3,
        pb: 2,
      }}
    >
      {children && children}
      {!isActionOnly && (
        <BluButton
          title={cancelText}
          onClick={onCancel}
          variant="outlined"
          color={actionColor}
          {...cancelButtonProps}
        />
      )}
      <BluButton
        title={actionText}
        variant="contained"
        disabled={actionDisabled}
        autoFocus={actionFocused}
        color={actionColor}
        onClick={onAction}
        {...actionButtonProps}
      />
    </DialogActions>
  );
}
