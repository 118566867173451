import axios from "../axios";

const context = "/security/role";

export default {
  get: async ({ accountId }) => {
    try {
      const response = await axios.get(context, {
        params: {
          account: accountId,
        },
      });
      return response?.data;
    } catch (e) {
      throw e?.response?.data;
    }
  },
};
