import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { logout } from "../../../slices/authentication";
import IdleDialog from "../../../elements/Dialog/ConfirmDialog";
import useIdleTimer from "./useIdleTimer";

function IdleDialogContent({ timer }) {
  const { t } = useTranslation();

  return (
    <>
      <Typography>
        {t(`You will get logged out. Do you want to stay?`)}
      </Typography>
      <Typography>
        {t("Time remaining:")} {timer} {t("seconds")}
      </Typography>
    </>
  );
}

export default function IdleModal({ isOpen, setIsOpenIdleModal }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const username = useSelector((state) => state.user.username);

  // eslint-disable-next-line no-use-before-define
  const { setIsIdle, timer } = useIdleTimer(onIdle, () =>
    dispatch(logout(username))
  );

  function onIdle() {
    setIsIdle(true);
    setIsOpenIdleModal(true);
  }

  function onStay() {
    setIsIdle(false);
    setIsOpenIdleModal(false);
  }

  return (
    <IdleDialog
      content={<IdleDialogContent timer={timer} />}
      title={t("You have been idle!")}
      isOpen={isOpen}
      onConfirm={() => dispatch(logout(username))}
      onCancel={() => onStay()}
      confirmText={t("Logout")}
      cancelText={t("Stay")}
      mustConfirm
    />
  );
}
