import { useTranslation } from "react-i18next";
import { useState } from "react";
import useAccount from "contexts/accountContext";
import { useQueryClient } from "react-query";
import AddToFolder from "./index";
import { setContentTitle } from "../setContentTitle";
import { useFoldersSegmentsCreate } from "../../useFoldersSegmentsMutations";
import QUERY_KEYS from "../../../../../constants/queryKeys";

export default function AddToFolderConfig({
  setSelectedAudiences,
  selectedAudiences,
  tableState,
  segmentIds,
  setOpenDialog,
  setActionName,
}) {
  const { t } = useTranslation();
  const { accountId } = useAccount();
  const queryClient = useQueryClient();
  const [selectedFolderId, setSelectedFolderId] = useState("");

  function onSelectChange(folderId) {
    setSelectedFolderId(folderId);
  }

  function clearSelectedAudiences() {
    setSelectedAudiences([]);
  }

  function clear() {
    setOpenDialog(false);
    setActionName("");
    setSelectedFolderId("");
  }

  // It's changing the folder id to the target folder (the place where we are adding the segment)
  // This way it will invalidate the cache there. We don't have to care with the current listing/filter
  // because this "add segment to folder" is actually duplicating segments in different places
  // The behavior should be different when removing a segment from a folder
  const {
    mutate: addSegmentsIntoFolder,
    isLoading: isLoadingSegmentsIntoFolder,
  } = useFoldersSegmentsCreate(
    {
      ...tableState,
      folder: selectedFolderId,
    },
    "AUDIENCES"
  );

  function onConfirm() {
    addSegmentsIntoFolder(
      { folderId: selectedFolderId, segmentIds, accountId },
      {
        onSettled: () => {
          clear();
          // Invalidating cache for every segment selected, in order to update the data within the drawer (folders)
          segmentIds.map(async (id) => {
            await queryClient.invalidateQueries([
              QUERY_KEYS.SEGMENT_DETAILS,
              { id },
            ]);
          });
        },
        onSuccess: () => clearSelectedAudiences(),
      }
    );
  }

  function onClose() {
    clear();
  }

  return {
    Component: AddToFolder,
    title: t("Add Files to Folder"),
    contentTitle: setContentTitle(
      selectedAudiences,
      `Move the audience "${selectedAudiences[0]?.name}" to a Folder?`,
      `Move these (${selectedAudiences.length}) Audiences to a Folder?`
    ),
    confirmText: t("Move"),
    onConfirm,
    isConfirmDisabled: !selectedFolderId,
    onClose,
    isLoading: isLoadingSegmentsIntoFolder,
    props: {
      selectedFolderId,
      onSelectChange,
    },
  };
}
