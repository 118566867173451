import { actionsNames } from "../Actions";
import AddToFolderConfig from "./AddToFolder/config";
import DeleteSegmentConfig from "./DeleteSegment/config";
import EditAudienceConfig from "./EditAudience/config";
import DuplicateAudienceConfig from "./DuplicateAudience/config";
import CopyAcrossAccountsConfig from "./CopyAcrossAccounts/config";

export default function ContentDialog({
  children,
  actionName,
  setSelectedAudiences,
  selectedAudiences,
  tableState,
  setOpenDialog,
  setActionName,
  segmentIds,
}) {
  const content = {
    [actionsNames.ADD_TO_FOLDER]: AddToFolderConfig({
      setSelectedAudiences,
      selectedAudiences,
      tableState,
      segmentIds,
      setOpenDialog,
      setActionName,
    }),
    [actionsNames.DELETE]: DeleteSegmentConfig({
      selectedAudiences,
      segmentId: segmentIds[0],
      setOpenDialog,
      setActionName,
    }),
    [actionsNames.EDIT_NAME]: EditAudienceConfig({
      setSelectedAudiences,
      selectedAudience: selectedAudiences[0],
      setOpenDialog,
      setActionName,
    }),
    [actionsNames.DUPLICATE]: DuplicateAudienceConfig({
      setSelectedAudiences,
      selectedAudience: selectedAudiences[0],
      setOpenDialog,
      setActionName,
    }),
    [actionsNames.COPY_ACROSS_ACCOUNTS]: CopyAcrossAccountsConfig({
      setSelectedAudiences,
      selectedAudience: selectedAudiences[0],
      setOpenDialog,
      setActionName,
    }),
  };

  return children({
    Component: content?.[actionName]?.Component || "",
    title: content?.[actionName]?.title || "",
    contentTitle: content?.[actionName]?.contentTitle || "",
    confirmText: content?.[actionName]?.confirmText || "",
    onConfirm: content?.[actionName]?.onConfirm,
    isConfirmDisabled: content?.[actionName]?.isConfirmDisabled,
    onClose: content?.[actionName]?.onClose,
    isLoading: content?.[actionName]?.isLoading,
    props: content?.[actionName]?.props,
  });
}
