import { useTranslation } from "react-i18next";
import { setContentTitle } from "../setContentTitle";
import { useSegmentsDelete } from "../../useSegmentsMutations";

export default function DeleteSegmentConfig({
  selectedAudiences,
  segmentId,
  setOpenDialog,
  setActionName,
}) {
  const { t } = useTranslation();

  const { mutate: deleteSegment, isLoading: isLoadingDeleteSegment } =
    useSegmentsDelete("AUDIENCES");

  function clear() {
    setOpenDialog(false);
    setActionName("");
  }

  function onConfirm() {
    deleteSegment(
      { segmentId },
      {
        onSettled: () => {
          clear();
        },
      }
    );
  }

  function onClose() {
    clear();
  }

  return {
    title: t("Delete audience"),
    contentTitle: setContentTitle(
      selectedAudiences,
      `Do you want to delete "${selectedAudiences[0]?.name}"?`,
      `Do you want to delete these (${selectedAudiences.length}) audiences?`
    ),
    confirmText: t("Delete"),
    onConfirm,
    onClose,
    isLoading: isLoadingDeleteSegment,
  };
}
