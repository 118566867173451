import { useState, useMemo, useCallback } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { convertFolders } from "components/AudiencesDataGrid/helpers";
import SelectField from "elements/Field/SelectField";
import SearchField from "elements/Field/SearchField";
import CreateButton from "elements/Button/CreateButton";
import DeleteDialog from "elements/Dialog/DeleteDialog";
import QUERY_KEYS from "constants/queryKeys";
import { DELETE, EDIT, CREATE, ALL, FOLDER, STATUS } from "constants/general";
import useRole from "components/Guard/useRole";
import useFoldersQuery from "../useFoldersQuery";
import {
  useFoldersCreate,
  useFoldersDelete,
  useFoldersEdit,
} from "../useFoldersMutations";
import CreateEditDialog from "./CreateEditDialog";
import SelectFolders from "./SelectFolders";
import useListDataQuery from "./useListDataQuery";
import { actions, features } from "../../../../constants/roles";

// normalize data back to BE specific properties
const convertFolderDataSave = (folderData, newName) => ({
  id: folderData?.id,
  account_id: folderData?.account_id,
  name: newName || folderData?.name,
});

const folderActionState = {
  activeAction: null,
  [DELETE]: null,
  [EDIT]: null,
  [CREATE]: null,
};

export default function ActionsHeader(props) {
  const { activeAccount, isAccountActive, tableDispatch, tableState } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { checkPermission } = useRole();

  const [activeFolder, setActiveFolder] = useState({
    ...folderActionState,
  });

  // test mutations
  const { mutate: deleteFolderMutate, isLoading: isDeleteProcessing } =
    useFoldersDelete(tableState.folder);
  const { mutate: editFolderMutate, isLoading: isEditProcessing } =
    useFoldersEdit();
  const { mutate: createFolderMutate, isLoading: isCreateProcessing } =
    useFoldersCreate();

  const {
    foldersData,
    isValid,
    isLoading: isLoadingFolders,
    isFetching,
  } = useFoldersQuery(
    {
      sort: "ASC",
      account: activeAccount?.id,
    },
    convertFolders
  );

  const { listSegmentsStatus } = useListDataQuery(convertFolders);

  // states for folder updating and creating modes
  const {
    isFolderEditingOrCreating,
    activeActionState,
    activeFolderNameValue,
  } = useMemo(() => {
    const action = activeFolder.activeAction;
    const active = action === EDIT || action === CREATE;
    const state = active ? activeFolder[action] : null;
    return {
      isFolderEditingOrCreating: active,
      activeActionState: state,
      activeFolderNameValue:
        active || action === DELETE ? activeFolder[action]?.name : "",
    };
  }, [activeFolder]);

  const onSearchChange = (searchValue, type) => {
    if (searchValue.length === 0 || searchValue.trim().length >= 3) {
      tableDispatch(type, searchValue);
    }
  };

  const changeFolderSelected = (value) => {
    const newFolderValue = value === ALL.value ? null : value;

    if (tableState?.folder !== newFolderValue)
      tableDispatch(FOLDER, newFolderValue);
  };

  // add folder with default create folder data
  const onFolderCreate = useCallback(
    () =>
      setActiveFolder((prev) => ({
        ...prev,
        activeAction: CREATE,
        [CREATE]: { name: "", account_id: activeAccount?.id, id: null },
      })),
    [activeAccount]
  );

  const onFolderActionsReset = () => {
    setActiveFolder({ ...folderActionState });
  };

  // mutate with secondary instance callbacks - component-specific side effects

  // DELETE callback from DeleteDialog - after complete close the modal and reset state
  const onConfirmFolderDelete = (opt) => {
    const convertData = convertFolderDataSave(opt);
    deleteFolderMutate(convertData, {
      onSettled: async () => {
        if (tableState.folder === opt.value) {
          changeFolderSelected(null);
        }
        onFolderActionsReset();
        await queryClient.invalidateQueries([QUERY_KEYS.FOLDERS]); // reload the folders
      },
    });
  };

  // EDIT/CREATE callback from FolderActionDialog confirm or cancel
  // after complete close the modal and reset state (ONLY IF SUCCESS)
  const onConfirmOrCancelFolderEdit = (action, val, folder) => {
    if (val === false) {
      onFolderActionsReset();
      return;
    }
    // adjust data for edit/create
    const convertData = convertFolderDataSave(folder, val);
    const mutator = action === EDIT ? editFolderMutate : createFolderMutate;
    mutator(convertData, {
      onSettled: (data, err) => {
        const isSuccess = !!(!err && data);
        if (isSuccess) onFolderActionsReset();
      },
    });
  };

  return (
    <>
      <Grid container spacing={{ xs: 1, md: 2 }} alignItems="center">
        <Grid item xs={12} md={5}>
          <SearchField
            searchDisplay={t("Search Audiences")}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              id: "search_audiences",
            }}
            onSearchChange={onSearchChange}
            debounceTimeout={300}
            triggerValue={tableState?.search}
          />
        </Grid>
        <Grid item xs={12} md={7} display="flex">
          <SelectField
            selectDisplay={t("Status")}
            options={listSegmentsStatus}
            value={listSegmentsStatus.length ? tableState?.status : ""}
            loading={!listSegmentsStatus.length}
            textFieldProps={{
              sx: { flexGrow: 1 },
              size: "small",
              id: "status_select",
            }}
            onSelectChange={(newValue) => tableDispatch(STATUS, newValue)}
          />
          {/* If the user can see folders, he can also edit and delete, so it's enough to check just one (read) */}
          {checkPermission(features.folder, actions.read) && (
            <SelectFolders
              options={isValid ? [ALL, ...foldersData] : []}
              value={isValid ? tableState?.folder || ALL.value : ""}
              loading={isLoadingFolders || isFetching}
              textFieldProps={{
                sx: { flexGrow: 2, mx: 2 },
                size: "small",
                id: "folders_select",
                disabled: !isAccountActive,
              }}
              onSelectChange={changeFolderSelected}
              setActiveFolder={setActiveFolder}
            />
          )}

          {checkPermission(features.folder, actions.create) && (
            <CreateButton
              title={t("Add New Folder")}
              tip={t("Add A New Folder")}
              id="add_btn_folder"
              disabled={!isAccountActive}
              onClick={() => onFolderCreate()}
            />
          )}
        </Grid>
      </Grid>
      <DeleteDialog
        content={`${t(
          "Are you sure you want to delete this folder"
        )}: ${activeFolderNameValue} ?`}
        title={t("Delete Folder")}
        activeValue={activeFolder[DELETE]}
        resetActiveValue={onFolderActionsReset}
        isCloseOnConfirm={false}
        confirmDisabled={isDeleteProcessing}
        onConfirm={onConfirmFolderDelete}
        confirmButtonProps={{
          disabled: isDeleteProcessing,
        }}
      />
      {isFolderEditingOrCreating && (
        <CreateEditDialog
          open={Boolean(activeActionState)}
          activeFolder={activeActionState}
          action={activeFolder.activeAction}
          value={activeFolderNameValue}
          isActionProcessing={isEditProcessing || isCreateProcessing}
          handleAction={onConfirmOrCancelFolderEdit}
        />
      )}
    </>
  );
}
