import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      common: {
        status: {
          success: "Success",
          error: "Error",
          complete: "Complete",
          loading: "Loading",
          processing: "Processing",
        },
        action: {
          create: "Create",
          edit: "Edit",
          delete: "Delete",
          save: "Save",
          submit: "Submit",
          submission: "Submission",
          cancel: "Cancel",
          reset: "Reset",
        },
      },
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
});

export default i18n;
