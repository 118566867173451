import { useMemo, useState } from "react";
import { GridToolbar } from "@mui/x-data-grid";
import BaseDataGrid from "components/DataGrid";
import useDataGridEditFields from "hooks/useDataGridEditFields";
import useQueryPartnerConnections from "../useQueryPartnerConnections";
import { getPartnerConnectionColumns } from "../tableColumns";
import { useConnectionsEditMutations } from "./useConnectionsMutations";
import { roleNames } from "../../../constants/roles";

export default function PartnerConnectionsDataGrid({
  accountId,
  canAccess,
  roleName,
  ...rest
}) {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const hidePoweredBy = roleName === (roleNames.ADMIN && roleName.POWER_USER);

  const {
    data: partnerConnections,
    isLoading: isLoadingPartnerConnections,
    isRefetching: isRefetchingPartnerConnections,
    error,
  } = useQueryPartnerConnections({
    accountId,
    queryProps: {
      enabled: canAccess,
    },
  });

  const { mutate: editConnection, isLoading: isEditingConnection } =
    useConnectionsEditMutations({
      accountId,
    });

  const updateFields = useDataGridEditFields({
    editableFields: [
      "nickname",
      "account_representative_name",
      "account_representative_email",
    ],
    mutate: editConnection,
  });

  const columns = useMemo(() => getPartnerConnectionColumns(), []);

  return (
    <BaseDataGrid
      tableData={partnerConnections || []}
      columns={columns}
      columnVisibilityModel={{ powered_by: hidePoweredBy }}
      loading={isLoadingPartnerConnections || isRefetchingPartnerConnections}
      error={error}
      rowsPerPageOptions={[10, 20, 50]}
      page={page}
      onPageChange={(newPage) => setPage(newPage)}
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      disableSelectionOnClick
      components={{ Toolbar: GridToolbar }}
      componentsProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
      experimentalFeatures={{
        newEditingApi: true,
      }}
      editMode="row"
      processRowUpdate={updateFields}
      isCellEditable={() => !isEditingConnection}
      {...rest}
    />
  );
}
