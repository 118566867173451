import { createSlice } from "@reduxjs/toolkit";

// Adding some attributes
// Roles/Admin etc TBD

const initialState = {
  username: "",
  attributes: {}, // firstName, lastName, email
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData(state, action) {
      state.username = action.payload.username;
      state.attributes = action.payload.attributes;
    },
  },
});

export const { reducer } = slice;

export const setUserData = (user) => async (dispatch) => {
  dispatch(slice.actions.setUserData(user));
};

export function selectUserData(state) {
  return { ...state.attributes };
}
