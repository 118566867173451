/* eslint-disable import/prefer-default-export */
import { useMutation, useQueryClient } from "react-query";
import { useActionAlerter } from "../../../components/Alert";
import QUERY_KEYS, { getSegmentsListKey } from "../../../constants/queryKeys";
import foldersSegmentsApi from "../../../api/folders/segments";
import { cleanParams } from "../../../utils";

export function useFoldersSegmentsCreate(tableState, key) {
  const params = cleanParams(tableState);
  const queryKey = getSegmentsListKey(params, key);
  const queryClient = useQueryClient();
  const { start, complete } = useActionAlerter("Added segment into folder");

  return useMutation(foldersSegmentsApi.create, {
    onMutate: async () => {
      start();
      await queryClient.cancelQueries(queryKey); // cancel any active segments queries
    },
    onSettled: async (data, err) => {
      const isSuccess = !!(!err && data);
      complete(isSuccess, `Description: ${err?.message}`);
      if (isSuccess) {
        await queryClient.invalidateQueries(queryKey); // reload the segments
      }
    },
  });
}

export function useFoldersSegmentsDelete(segmentId) {
  const queryClient = useQueryClient();
  const { start, complete } = useActionAlerter("Delete Segment from Folder");

  return useMutation(foldersSegmentsApi.delete, {
    onMutate: async () => {
      start();
    },
    onSettled: async (data, error) => {
      const isSuccess = !error;
      complete(isSuccess, `Description: ${error?.message}`);
      if (isSuccess) {
        await queryClient.invalidateQueries([
          QUERY_KEYS.SEGMENT_DETAILS,
          { id: segmentId },
        ]);
        await queryClient.invalidateQueries([
          getSegmentsListKey(null, "AUDIENCES"),
        ]);
      }
    },
  });
}
