import { Box, LinearProgress, Typography, Grid } from "@mui/material";
import SeverityPill from "../../elements/SeverityPill";
import StatCard from "../../elements/Cards/StatCard";

export function ActiveAudiences() {
  return (
    <StatCard title="Recent Audiences">
      <Typography sx={{ mr: 1 }} variant="h5">
        854,355
      </Typography>
      <SeverityPill color="success">+25%</SeverityPill>
    </StatCard>
  );
}

export function NewModels() {
  return (
    <StatCard title="New Models">
      <Typography sx={{ mr: 1 }} variant="h5">
        325
      </Typography>
      <SeverityPill color="error">-15%</SeverityPill>
    </StatCard>
  );
}
export function ActiveUsers() {
  return (
    <StatCard title="Active Users">
      <Typography sx={{ mr: 1 }} variant="h5">
        8
      </Typography>
      <SeverityPill color="primary">LIVE</SeverityPill>
    </StatCard>
  );
}

export function DistributionStatus() {
  return (
    <StatCard title="Distribution Status">
      <Typography sx={{ mr: 1 }} variant="h5">
        74%
      </Typography>
      <Box sx={{ flexGrow: 1 }}>
        <LinearProgress color="primary" value={74} variant="determinate" />
      </Box>
    </StatCard>
  );
}

export default function StatCards() {
  return (
    <Grid container spacing={3} alignItems="stretch">
      <Grid item xs={12} sm={6} lg={3}>
        <ActiveAudiences />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <NewModels />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <DistributionStatus />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <ActiveUsers />
      </Grid>
    </Grid>
  );
}
