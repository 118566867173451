import { useState } from "react";
import Switch from "@mui/material/Switch";
import useSettings from "../../../../hooks/useSettings";

export default function ThemeDefaultSwitch({ switchProps = {} }) {
  const { settings, saveSettings } = useSettings();
  const [defaultChecked, setDefaultChecked] = useState(
    settings?.isDefaultTheme
  );

  const handleChange = (event) => {
    const { checked } = event.target;
    setDefaultChecked(checked);
    saveSettings({
      ...settings,
      isDefaultTheme: checked,
    });
  };

  return (
    <Switch
      checked={defaultChecked}
      color="warning"
      onChange={handleChange}
      size="small"
      inputProps={{ "aria-label": "controlled" }}
      {...switchProps}
    />
  );
}
