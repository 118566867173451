import { convertJSONDateForTableDisplay } from "utils";

const defaults = {
  type: "string",
  sortable: true,
  align: "left",
  headerAlign: "left",
};

export const getModelsColumns = () => [
  {
    ...defaults,
    field: "id",
    headerName: "Model ID",
    width: 90,
  },
  {
    ...defaults,
    field: "seed_segment",
    headerName: "Seed Segment",
    minWidth: 150,
    flex: 1,
  },
  {
    ...defaults,
    field: "created_at",
    headerName: "Created Date",
    type: "date",
    valueGetter: (params) => convertJSONDateForTableDisplay(params.value),
    width: 120,
  },
  {
    ...defaults,
    field: "status",
    headerName: "Status",
    sortable: false,
    width: 90,
  },
];
