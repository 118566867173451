import { useQuery } from "react-query";
import QUERY_KEYS from "constants/queryKeys";
import segments from "../api/segments";
import { cleanParams } from "../utils";

const defaultOptions = {
  retry: false,
  staleTime: Infinity,
};

export default function useQuerySegment({
  segmentId: id,
  extraParams,
  queryProps = {},
}) {
  const params = extraParams ? { id, ...cleanParams(extraParams) } : { id };
  const key = [QUERY_KEYS.SEGMENT_DETAILS, { id }];

  const query = useQuery(key, () => segments.get(params), {
    ...defaultOptions,
    ...queryProps,
  });

  return {
    ...query,
  };
}
