import { Computer, Email, PhoneIphone } from "@mui/icons-material";
import numbro from "numbro";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import IconWithText from "../IconWithText";

function Row({
  icon,
  name,
  matchedIndividuals,
  matchedRecords,
  columnSpacing,
}) {
  return (
    <Grid columnSpacing={columnSpacing} container item>
      <Grid item xs={4}>
        <IconWithText text={name} Icon={icon} />
      </Grid>
      <Grid item xs={4} display="flex" alignItems="center">
        <Typography>{matchedIndividuals}</Typography>
      </Grid>
      <Grid item xs={4} display="flex" alignItems="center">
        <Typography>{matchedRecords}</Typography>
      </Grid>
    </Grid>
  );
}

export default function DetailsList({ columnSpacing, data }) {
  const { t } = useTranslation();

  return (
    <>
      <Row
        icon={Email}
        name={t("Email")}
        matchedIndividuals={numbro(data.distinctBluIdsByEmail).format()}
        matchedRecords={numbro(data.emailsCount).format()}
        columnSpacing={columnSpacing}
      />
      <Row
        icon={PhoneIphone}
        name={t("MAIDs")}
        matchedIndividuals={numbro(data.distinctBluIdsByMaids).format()}
        matchedRecords={numbro(data.maidsCount).format()}
        columnSpacing={columnSpacing}
      />
      <Row
        icon={Computer}
        name={t("IP Addresses")}
        matchedIndividuals={numbro(data.distinctBluIdsByIps).format()}
        matchedRecords={numbro(data.ipsCount).format()}
        columnSpacing={columnSpacing}
      />
    </>
  );
}
