import { Formik } from "formik";
import { FormControl, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { BOTH, COMBINE_AUDIENCE, UNIQUE_AUDIENCE } from "constants/general";
import ConfirmDialog from "elements/Dialog/ConfirmDialog";
import TextFieldFormik from "components/TextFieldFormik";
import RadioGroupScaling from "./RadioGroupScaling";

export default function ScalingForm({
  selectedSegments,
  setSelectedOptionValue,
  selectedOptionValue,
  onConfirm,
  openModalCreateAudience,
  handleCloseModal,
  defaultRootName,
}) {
  const { t } = useTranslation();

  const findMinAndMaxProbability = () => {
    const probabilities = selectedSegments.map(
      (segment) => segment.probability
    );
    const min = Math.min(...probabilities.map((p) => p[0]));
    const max = Math.max(...probabilities.map((p) => p[1]));
    return `${min}-${max}`;
  };

  const isBothSelected = selectedOptionValue === BOTH;
  const showUnique = isBothSelected || selectedOptionValue === UNIQUE_AUDIENCE;
  const showCombine =
    selectedSegments.length > 1 &&
    (isBothSelected || selectedOptionValue === COMBINE_AUDIENCE);

  const initialValues = Object.assign(
    { combined_selections: `${defaultRootName}${findMinAndMaxProbability()}` },
    ...selectedSegments.map((param, index) => {
      const { probability } = param;
      return {
        [`selection_${
          index + 1
        }`]: `${defaultRootName}${probability[0]}-${probability[1]}`,
      };
    })
  );

  const validationSchema = Yup.object().shape(
    Object.assign(
      {
        combined_selections: Yup.string()
          .trim()
          .required(`Combined selections is required`),
      },
      ...selectedSegments.map((param, index) => ({
        [`selection_${index + 1}`]: Yup.string()
          .trim()
          .required(`Selection ${index + 1} is required`),
      }))
    )
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => onConfirm(values)}
      enableReinitialize
    >
      {({
        errors,
        values,
        touched,
        submitForm,
        setFieldValue,
        setFieldTouched,
      }) => (
        <ConfirmDialog
          onConfirm={submitForm}
          content={selectedSegments.length > 1 ? "Would you like (a):" : ""}
          title="Create Audiences"
          isOpen={openModalCreateAudience}
          onCancel={() => handleCloseModal()}
          confirmText="Save"
          confirmColor="primary"
          // isLoading and isConfirmDisabled should come from React Query
          // confirmDisabled={isLoading || isConfirmDisabled}
        >
          <FormControl>
            {selectedSegments.length > 1 && (
              <RadioGroupScaling
                selectedOptionValue={selectedOptionValue}
                setSelectedOptionValue={setSelectedOptionValue}
              />
            )}
          </FormControl>
          <Typography variant="h6" mb={1} mt={2}>
            {t("Name your audience(s) ")}
          </Typography>
          <Stack spacing={2}>
            {showCombine && (
              <TextFieldFormik
                value={values.combined_selections}
                label="Combined Selections"
                id="combined_selections"
                name="combined_selections"
                fullWidth
                key="combined_selections"
                form={{
                  errors,
                  touched,
                  setFieldValue,
                  setFieldTouched,
                }}
              />
            )}
            {showUnique &&
              Object.entries(values).map(
                ([name, value], index) =>
                  name !== "combined_selections" && (
                    <TextFieldFormik
                      value={value}
                      label={`Selection ${index}`}
                      id={name}
                      name={name}
                      fullWidth
                      key={name}
                      form={{
                        errors,
                        touched,
                        setFieldValue,
                        setFieldTouched,
                      }}
                    />
                  )
              )}
          </Stack>
        </ConfirmDialog>
      )}
    </Formik>
  );
}
