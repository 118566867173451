/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
import { useMemo } from "react";
import { useMutation, useQueryClient, useInfiniteQuery } from "react-query";
import { useActionAlerter } from "components/Alert";
import { wait, cleanParams } from "utils";
import segments from "api/segments";
import { getSegmentsListKey } from "constants/queryKeys";

const isDebug = false;

const builderName = "BUILDER";

const queryOptions = {
  // keepPreviousData: true,
  retry: false,
  staleTime: Infinity,
};

const createBuilderApi = async (builder) => {
  await wait(5000);
  const success = Math.random() > 0.5;
  const errorMessages = "Error Creating Builder";
  if (!success) {
    throw new Error(errorMessages);
  }
  return builder;
};

const createApi = isDebug ? createBuilderApi : segments.create;

export function useBuilderCreatMutation() {
  const queryClient = useQueryClient();
  const { start, complete } = useActionAlerter("Create Audience Builder");
  const audiencesKey = getSegmentsListKey(null, "AUDIENCES");
  const builderKey = getSegmentsListKey(null, builderName);

  return useMutation(createApi, {
    onMutate: async () => {
      start();
      await queryClient.cancelQueries(audiencesKey); // cancel any active segments queries
      await queryClient.cancelQueries(builderKey);
    },
    onSettled: async (data, err) => {
      const isSuccess = !!(!err && data);
      complete(isSuccess, `Description: ${err?.message}`);
      if (isSuccess) {
        await queryClient.invalidateQueries(audiencesKey); // reload the segments
        await queryClient.invalidateQueries(builderKey); // reload the segments
      }
    },
  });
}

// infinite query version - merge tableState with pageParams
// get totals from all pages
// passes pageParam, QueryKey to fn
export function useInfiniteSegmentsQuery(tableState, isActive) {
  const params = cleanParams(tableState);
  const tKey = getSegmentsListKey(params, builderName);

  const infQuery = useInfiniteQuery(
    tKey,
    ({ pageParam = 0 }) => segments.get({ ...params, page: pageParam }),
    {
      enabled: isActive,
      getNextPageParam: (lastPage, pages) =>
        lastPage.page + 1 >= lastPage.total_pages
          ? undefined
          : lastPage.page + 1,
      ...queryOptions,
    }
  );

  const { isValid, hasPages, pageTotalSize, pagesData } = useMemo(() => {
    const valid =
      !infQuery.isLoading &&
      infQuery.isSuccess &&
      infQuery.data &&
      infQuery.data?.pages?.length;
    const allData = valid ? infQuery.data.pages.flatMap((d) => d.results) : [];
    return {
      isValid: valid,
      pageTotalSize: valid ? infQuery.data?.pages[0]?.total_results : 0,
      pagesData: allData,
      hasPages: valid && allData.length,
    };
  }, [infQuery.isLoading, infQuery.isSuccess, infQuery.data]);

  return {
    ...infQuery,
    isValid,
    hasPages,
    pageTotalSize,
    pagesData,
  };
}
