import { createTheme as createMuiTheme } from "@mui/material/styles";
import baseThemeOptions from "./baseThemeOptions";
import darkThemeOptions from "./darkThemeOptions";
// import lightThemeOptions from "./lightThemeOptions";
import horizonLightThemeOptions from "./horizonLightThemeOptions";

// todo remove default, dark etc
/* const theme = React.useMemo(
  () =>
    createTheme({
      palette: {
        mode,
      },
    }),
  [mode],
); */
// add to default to match custom themes
const neutral = {
  100: "#F3F4F6",
  200: "#E5E7EB",
  300: "#D1D5DB",
  400: "#9CA3AF",
  500: "#6B7280",
  600: "#4B5563",
  700: "#374151",
  800: "#1F2937",
  900: "#111827",
};

export const getDefaultTheme = (mode) =>
  createMuiTheme({
    palette: {
      mode,
      active: neutral[500],
      neutral,
    },
  });

const createTheme = (config) => {
  const theme = config.isDefaultTheme
    ? getDefaultTheme(config.mode)
    : createMuiTheme(
        baseThemeOptions,
        config.mode === "dark" ? darkThemeOptions : horizonLightThemeOptions
      );

  return theme;
};

export default createTheme;
