import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import AutocompleteMUI from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from "@mui/icons-material/Search";

// Autocomplete adjusted for searches: https://mui.com/material-ui/react-autocomplete/
// relies on props for state/change events
// typing for search - not filtering existing
// selection callback but not set in input - leave search (required controlled input value here)
// IMPORTANT provide: getOptionLabel: (option) => typeof option === "string" ? option : option.name,
//    required to handle both search text and options for list - can use renderOption for list options
// setting value to null - else will show selected if hidden value not cleared

export default function Autocomplete(props) {
  const {
    handleInputChange,
    handleSelectChange,
    searchOptions = [],
    searchDisplay = "Search",
    textFieldProps = {},
    autocompleteProps = {},
    isLoading = false,
    searchValue = "",
    value = null, // prevent hidden selection issues
  } = props;

  const { InputProps, ...textFieldPropsRest } = textFieldProps;

  const renderInput = (params) => (
    <TextField
      {...params}
      placeholder={searchDisplay}
      variant="outlined"
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <>
            {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
            {params.InputProps.endAdornment}
          </>
        ),
        ...InputProps,
      }}
      {...textFieldPropsRest}
    />
  );
  return (
    <AutocompleteMUI
      options={searchOptions}
      onInputChange={handleInputChange}
      inputValue={searchValue}
      value={value}
      size="small"
      fullWidth
      noOptionsText="No Results"
      forcePopupIcon={false}
      freeSolo={false}
      includeInputInList
      onChange={handleSelectChange}
      renderInput={(params) => renderInput(params)}
      {...autocompleteProps}
    />
  );
}
