import { useMemo, useState } from "react";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import BaseDataGrid from "components/DataGrid";
import useQueryDistributions from "./useQueryDistributions";
import { useMemoizedCallback } from "../../../hooks";
import { getDistributionColumns } from "../tableColumns";
import useAccount from "../../../contexts/accountContext";

export default function DistributeDataGrid({
  accountId,
  canDownloadDistribution,
}) {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const {
    data: distributedAudiences,
    isLoading,
    isRefetching,
    error,
  } = useQueryDistributions({ accountId });

  const { activeAccount } = useAccount();

  const CustomToolbar = useMemoizedCallback(() => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      {canDownloadDistribution && (
        <GridToolbarExport
          csvOptions={{
            fileName: `${activeAccount.clientAccountName}_distributed_audiences`,
          }}
        />
      )}
    </GridToolbarContainer>
  ));

  const columns = useMemo(() => getDistributionColumns(), []);

  return (
    <BaseDataGrid
      tableData={distributedAudiences}
      columns={columns}
      loading={isLoading || isRefetching}
      error={error}
      rowsPerPageOptions={[10, 20, 50]}
      page={page}
      onPageChange={(newPage) => setPage(newPage)}
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      disableSelectionOnClick
      getRowId={(row) => row.distribution_id}
      initialState={{
        sorting: {
          sortModel: [
            {
              field: "created_at",
              sort: "desc",
            },
          ],
        },
      }}
      components={{ Toolbar: CustomToolbar }}
      componentsProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
    />
  );
}
