import { AppBar, Toolbar, Box, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import SettingsIcon from "@mui/icons-material/Settings";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { roleNames } from "constants/roles";
import { Menu as MenuIcon } from "../../../assets/icons/menu";
import BluButton from "../../../elements/Button/BluButton";
import UserProfile from "./Profile";
import useAccount from "../../../contexts/accountContext";
import Notifications from "./Notifications";
import BluLogo from "../../../assets/BluLogo";

const MainAppBarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow:
    "0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1)",
}));

function Header(props) {
  const { t } = useTranslation();
  const { onOpenSidebar, user, isPersonalDashboard, ...other } = props;
  const { activeAccount, isAccountActive } = useAccount();
  const isDevelopment = process.env.NODE_ENV === "development";
  const roleName = useSelector((state) => state.role.data?.role);
  const isAdmin = roleName === roleNames.ADMIN;
  // Images are hosted on S3. This variable makes it display locally, since we don't have the files
  // in our machines. It's the Horizon dev URL
  const originUrl = isDevelopment
    ? "https://d3s2z0lrnnlgdh.cloudfront.net"
    : window.location.origin;

  // Full width when it's in the personal dashboard
  const lg = isPersonalDashboard ? 0 : 280;

  return (
    <MainAppBarRoot
      sx={{
        left: {
          lg,
        },
        width: {
          lg: `calc(100% - ${lg}px)`,
        },
      }}
      {...other}
    >
      <Toolbar
        disableGutters
        sx={{
          minHeight: 64,
          left: 0,
          px: 2,
        }}
      >
        <BluButton
          onClick={onOpenSidebar}
          isIcon
          color="default"
          sx={{
            display: {
              xs: "inline-flex",
              lg: "none",
            },
          }}
        >
          <MenuIcon fontSize="small" />
        </BluButton>
        {isPersonalDashboard && (
          <BluLogo
            sx={{ width: 102, height: 49 }}
            viewBox="0 0 102 49"
            htmlColor="#0082CA"
          />
        )}
        {isAccountActive && !isPersonalDashboard && (
          <Box display="flex" alignItems="center">
            <Typography sx={{ mx: 3 }} variant="body1" color="primary">
              {activeAccount.clientAccountName}
            </Typography>
            {activeAccount.logoUrl && (
              <img
                style={{
                  maxWidth: "100%",
                  maxHeight: "32px",
                  marginRight: "24px",
                }}
                src={`${originUrl}/${activeAccount.logoUrl}`}
                alt={t("Company logo")}
              />
            )}
          </Box>
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" gap={1}>
          <BluButton
            aria-label="search"
            size="large"
            color="default"
            isIcon
            icon={<SearchIcon />}
          />
          <Notifications />
          {isAdmin && (
            <BluButton
              aria-label="settings"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              component={Link}
              to="/admin/general"
              size="large"
              color="default"
              isIcon
              icon={<SettingsIcon />}
            />
          )}
          <UserProfile
            user={user}
            isAdmin={isAdmin}
            isPersonalDashboard={isPersonalDashboard}
          />
        </Stack>
      </Toolbar>
    </MainAppBarRoot>
  );
}

export default Header;
