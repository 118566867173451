import { useQuery } from "react-query";
import QUERY_KEYS from "constants/queryKeys";
import { useMemo } from "react";
import listDataApi from "../../../../api/listData";

const defaultOptions = {
  retry: false,
  staleTime: Infinity,
};

export default function useListDataQuery(dataConverter) {
  const listData = useQuery(
    [QUERY_KEYS.LIST_DATA],
    () => listDataApi.get(),
    defaultOptions
  );

  // Later we'll need to change this function to handle and normalize different
  // pieces of data. This API will be global for general lookup/list/status data.
  // Lift this Hook when necessary to a parent component
  const { listSegmentsStatus } = useMemo(() => {
    const statusList = listData?.data?.segment_status || [];
    return {
      listSegmentsStatus: dataConverter(statusList),
    };
  }, [listData?.data]);

  return {
    ...listData,
    listSegmentsStatus,
  };
}
