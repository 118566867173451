import { createSlice } from "@reduxjs/toolkit";
import { keysToCamel } from "../utils";

export const NOTIFICATIONS = "notifications";

const initialState = {
  data: [],
  unread: 0,
};

function countUnreadNotification(notifications) {
  return notifications.filter((notification) => notification.read === false)
    .length;
}

const slice = createSlice({
  name: NOTIFICATIONS,
  initialState,
  reducers: {
    addNotification(state, action) {
      state.data = [...state.data, keysToCamel(action.payload)];
      state.unread = countUnreadNotification(state.data);
    },
    removeNotificationById(state, action) {
      state.data = state.data.filter((data) => data.id !== action.payload);
      state.unread = countUnreadNotification(state.data);
    },
    markAllAsRead(state) {
      state.data = state.data.map((data) => ({
        ...data,
        read: true,
      }));
      state.unread = 0;
    },
    markAsReadById(state, action) {
      state.data = state.data.map((data) => {
        if (data.id === action.payload && !data.read) {
          return {
            ...data,
            read: true,
          };
        }
        return data;
      });
      state.unread = countUnreadNotification(state.data);
    },
  },
});

export const { reducer } = slice;

export const addNotification = (data) => async (dispatch) => {
  dispatch(slice.actions.addNotification(data));
};

export const removeNotificationById = (id) => async (dispatch) => {
  dispatch(slice.actions.removeNotificationById(id));
};

export const markAllAsRead = () => async (dispatch) => {
  dispatch(slice.actions.markAllAsRead());
};

export const markAsReadById = (id) => async (dispatch) => {
  dispatch(slice.actions.markAsReadById(id));
};
