import { useMemoizedCallback } from "hooks";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useMemo } from "react";
import useQueryAudiences from "queries/useQueryAudiences";
import BluDataGrid from "components/DataGrid/BluDataGrid";
import LinearProgress from "@mui/material/LinearProgress";
import { getDataScienceColumns } from "./getColumns";

export default function DataScienceTable({
  setSelectedAudiences,
  tableState,
  segmentIds,
  storage,
  setStorage,
  tableDispatch,
}) {
  const { tableData, pageTotalSize, isValid, isLoading, isRefetching, error } =
    useQueryAudiences(tableState, "DATA_SCIENCE");

  const getRowOnSelection = useMemoizedCallback((ids) => {
    const selectedIDs = new Set(ids);
    const selectedRowData = tableData.filter((row) =>
      selectedIDs.has(row.id.toString())
    );
    setSelectedAudiences(selectedRowData);
  });

  const CustomToolbar = useMemoizedCallback(() => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  ));

  const columns = useMemo(() => getDataScienceColumns(), []);

  return (
    <BluDataGrid
      customizationStorage={storage}
      handleCustomizationStorageChange={setStorage}
      tableState={tableState}
      tableDispatch={tableDispatch}
      tableData={tableData}
      isValid={isValid}
      error={error}
      isLoading={isLoading || isRefetching}
      columns={columns}
      pageTotalSize={pageTotalSize}
      density={storage.density}
      onSelectionModelChange={(ids) => getRowOnSelection(ids)}
      selectionModel={segmentIds}
      components={{ LoadingOverlay: LinearProgress, Toolbar: CustomToolbar }}
      checkboxSelection={false}
      disableSelectionOnClick={false}
    />
  );
}
