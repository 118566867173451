import { IconButton, Tooltip } from "@mui/material";
import { MarkEmailRead } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export default function MarkAllAsRead({ handleMarkAllAsRead }) {
  const { t } = useTranslation();

  return (
    <Tooltip title={t("Mark all as read")}>
      <IconButton
        onClick={handleMarkAllAsRead}
        size="small"
        sx={{ color: "inherit" }}
      >
        <MarkEmailRead fontSize="small" />
      </IconButton>
    </Tooltip>
  );
}
