export const ACCOUNT = "ACCOUNT";
export const CHANGE = "CHANGE";
export const SEARCH = "SEARCH";
export const RESET = "RESET";
export const FOLDER = "FOLDER";
export const STATUS = "STATUS";
export const TRIGGER = "TRIGGER";
export const DEFAULT = "DEFAULT";
export const CLEAR = "CLEAR";
export const CREATE = "CREATE";
export const EDIT = "EDIT";
export const DELETE = "DELETE";
export const ALL = {
  id: 0,
  label: "All",
  value: "all",
};
export const CATEGORY = "category";
export const START = "START";
export const END = "END";
export const BOTH = "both";
export const COMBINE_AUDIENCE = "combine_audience";
export const UNIQUE_AUDIENCE = "unique_audiences";
