import axios from "./axios";

const context = "/connections";

export default {
  get: async ({ accountId }) => {
    try {
      const response = await axios.get(context, {
        params: {
          account: accountId,
        },
      });
      return response?.data;
    } catch (e) {
      throw e?.response?.data;
    }
  },
  post: async ({ accountId, partnerId, connectionParameters }) => {
    try {
      const response = await axios.post(context, {
        account_id: accountId,
        partner_id: partnerId,
        connection_parameters: connectionParameters,
      });
      return response?.data;
    } catch (e) {
      throw e?.response?.data?.message;
    }
  },
  put: async ({ id, editedFields }) => {
    try {
      const response = await axios.put(`${context}/${id}`, editedFields);
      return response?.data;
    } catch (e) {
      throw e?.response?.data?.message;
    }
  },
};
