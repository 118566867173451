import { useSelector } from "react-redux";
import { keyExists } from "utils";
import { targets } from "constants/roles";

/**
 * This hook is checking whether the user has permission or not to access a feature. It's searching
 * across the JSON returned from the API. Use it to hide simple scenarios like buttons or dropdowns.
 *
 * For entire sections/pages, we have RoleBasedGuard.js
 *
 * role: My role name: Admin, Power User, General User, Data Scientist and View Only.
 * features: Every feature individually (folders, audiences, etc).
 * sections: Small portions of the UI as sidebar. There is no reason to check for every single
 * feature if the user has access to all of them. We can just return it in this "sections" prop to check if we can render everything.
 * pages: Entire page: Audiences, Models, Builder, etc. If it's true, the user has access to it.
 * Using CRUD operations for each functionality (create, read, update, delete) and custom ones (duplicate, move, run, etc)
 *
 * Example:
 *
 * const responseFromApi = {
 *   role: "admin",
 *   features: {
 *     uploadLogo: {
 *       create: true,
 *       read: true,
 *       update: true,
 *     },
 *     clientAccounts: {
 *       read: true,
 *     },
 *  },
 *  sections: {
 *    someSectionName: {
 *      view: true,
 *    }
 *  },
 *  pages: {
 *    builder: {
 *      view: true,
 *    }
 *  }
 * * */

export default function useRole() {
  const role = useSelector((state) => state.role.data);

  function checkPermission(feature, action, target = targets.features) {
    let hasPermission = false;
    if (role) {
      hasPermission = keyExists(role[target], feature, action);
    }
    return hasPermission;
  }

  return {
    checkPermission,
    role,
  };
}
