/* eslint-disable camelcase */
import axios from "../axios";

const context = "/folders";

export default {
  get: async ({ account }) => {
    try {
      const response = await axios.get(`${context}`, {
        params: {
          account,
        },
      });
      return response?.data;
    } catch (e) {
      throw e?.response?.data;
    }
  },
  // eslint-disable-next-line camelcase
  create: async ({ name, account_id }) => {
    try {
      const response = await axios.post(`${context}`, {
        name,
        // eslint-disable-next-line camelcase
        account_id,
      });
      return response?.data;
    } catch (e) {
      throw e?.response?.data;
    }
  },
  edit: async ({ id, name, account_id }) => {
    try {
      const response = await axios.put(`${context}/${id}`, {
        name,
        account_id,
      });
      return response?.data;
    } catch (e) {
      throw e?.response?.data;
    }
  },
  delete: async ({ id }) => {
    try {
      const response = await axios.delete(`${context}/${id}`);
      return response?.data;
    } catch (e) {
      throw e?.response?.data;
    }
  },
};
