import { useIdleTimer as useIdleTimerLib } from "react-idle-timer";
import { useEffect, useState } from "react";

const timeOutTime = 1000 * 60 * 30;
const COUNTDOWN = 60;
let interval;

export default function useIdleTimer(onIdle, onTimeout) {
  const [isIdle, setIsIdle] = useState(false);
  const [timer, setTimer] = useState(COUNTDOWN);
  const idleTimer = useIdleTimerLib({ onIdle, timeout: timeOutTime });

  useEffect(() => {
    if (isIdle) {
      interval = setInterval(
        () => setTimer((prevState) => prevState - 1),
        1000
      );

      return () => {
        idleTimer.reset();
        clearInterval(interval);
        setTimer(COUNTDOWN);
      };
    }
    return undefined;
  }, [isIdle]);

  useEffect(() => {
    if (timer <= 0) {
      onTimeout();
    }
  }, [timer]);

  return { isIdle, setIsIdle, timer };
}
