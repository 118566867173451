/* eslint-disable no-console */
// import * as Yup from "yup";
import { useFormik } from "formik";
import { Box, FormHelperText } from "@mui/material";
import { useDispatch } from "react-redux";
import { federatedSignIn } from "../../slices/authentication";
import BluButton from "../../elements/Button/BluButton";

export default function Authentication() {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      submit: null,
    },
    // validationSchema: Yup.object({
    //   email: Yup.string()
    //     .email("Must be a valid email")
    //     .max(255)
    //     .required("Email is required"),
    // }),
    onSubmit: async () => {
      try {
        dispatch(federatedSignIn());
      } catch (err) {
        console.error(err);
      }
    },
  });

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      {/* Later we'll have user and password */}
      {/* <TextField */}
      {/*  autoFocus */}
      {/*  error={Boolean(formik.touched.email && formik.errors.email)} */}
      {/*  fullWidth */}
      {/*  helperText={formik.touched.email && formik.errors.email} */}
      {/*  label="Email Address" */}
      {/*  margin="normal" */}
      {/*  name="email" */}
      {/*  onBlur={formik.handleBlur} */}
      {/*  onChange={formik.handleChange} */}
      {/*  type="email" */}
      {/*  value={formik.values.email} */}
      {/* /> */}
      {/* <TextField */}
      {/*  error={Boolean(formik.touched.password && formik.errors.password)} */}
      {/*  fullWidth */}
      {/*  helperText={formik.touched.password && formik.errors.password} */}
      {/*  label="Password" */}
      {/*  margin="normal" */}
      {/*  name="password" */}
      {/*  onBlur={formik.handleBlur} */}
      {/*  onChange={formik.handleChange} */}
      {/*  type="password" */}
      {/*  value={formik.values.password} */}
      {/* /> */}
      {formik.errors.submit && (
        <Box sx={{ mt: 3 }}>
          <FormHelperText error>{formik.errors.submit}</FormHelperText>
        </Box>
      )}
      <Box sx={{ mt: 2 }}>
        <BluButton
          fullWidth
          title="Log In"
          size="large"
          aria-label="log in"
          disabled={formik.isSubmitting}
          type="submit"
        />
      </Box>
    </form>
  );
}
