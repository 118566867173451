import { Routes, Route } from "react-router-dom";
import { Audiences, Insights, AudienceBuilder } from "pages";

export default function AudienceManagement() {
  return (
    <Routes>
      <Route index element={<Audiences />} />
      <Route path="audiences/*" element={<Audiences />} />
      <Route path="insights" element={<Insights />} />
      <Route path="audience-builder" element={<AudienceBuilder />}>
        <Route path=":segmentId" element={<AudienceBuilder />} />
      </Route>
    </Routes>
  );
}
