import { Typography, Grid, Box } from "@mui/material";

export function TextColor() {
  return (
    <Typography component="div" variant="body1">
      <Box sx={{ color: "primary.main" }}>primary.main</Box>
      <Box sx={{ color: "secondary.main" }}>secondary.main</Box>
      <Box sx={{ color: "error.main" }}>error.main</Box>
      <Box sx={{ color: "warning.main" }}>warning.main</Box>
      <Box sx={{ color: "info.main" }}>info.main</Box>
      <Box sx={{ color: "success.main" }}>success.main</Box>
      <Box sx={{ color: "text.primary" }}>text.primary</Box>
      <Box sx={{ color: "text.secondary" }}>text.secondary</Box>
      <Box sx={{ color: "text.disabled" }}>text.disabled</Box>
    </Typography>
  );
}

export function BackgroundColor() {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={4}>
        <Box
          sx={{ bgcolor: "primary.main", color: "primary.contrastText", p: 2 }}
        >
          primary.main
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box
          sx={{
            bgcolor: "secondary.main",
            color: "secondary.contrastText",
            p: 2,
          }}
        >
          secondary.main
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box sx={{ bgcolor: "error.main", color: "error.contrastText", p: 2 }}>
          error.main
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box
          sx={{ bgcolor: "warning.main", color: "warning.contrastText", p: 2 }}
        >
          warning.main
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box sx={{ bgcolor: "info.main", color: "info.contrastText", p: 2 }}>
          info.main
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box
          sx={{ bgcolor: "success.main", color: "success.contrastText", p: 2 }}
        >
          success.main
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box sx={{ bgcolor: "text.primary", color: "background.paper", p: 2 }}>
          text.primary
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box
          sx={{ bgcolor: "text.secondary", color: "background.paper", p: 2 }}
        >
          text.secondary
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box sx={{ bgcolor: "text.disabled", color: "background.paper", p: 2 }}>
          text.disabled
        </Box>
      </Grid>
    </Grid>
  );
}
