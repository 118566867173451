import SettingsIcon from "@mui/icons-material/Settings";

export default function toolsItems(t) {
  return {
    title: t("Tools"),
    id: "admin",
    items: [
      {
        title: t("Admin"),
        id: "admin.general",
        isDefault: true,
        path: "/admin",
        visible: true,
        icon: <SettingsIcon />,
      },
    ],
  };
}
