/* eslint-disable import/prefer-default-export */
// Style related utilities

// show/hide by SX based on val
export function sxIsHidden(isShow = true, sxProps = []) {
  return isShow
    ? [
        {
          display: "block",
        },
        ...(Array.isArray(sxProps) ? sxProps : [sxProps]),
      ]
    : [
        {
          display: "none",
        },
      ];
}
