import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { DefaultListItem, SelectedListItem } from "elements/List/ListItems";
import { Tooltip } from "../components/common";
import { TAXONOMY_TYPE } from "../builderModel";

// List Item for taxonomy category select - load or map next set of data onSelectCategory
// in Taxonomy list - also remove active selection
export function CategoryItem(props) {
  const { item, onSelectCategory, ...rest } = props;
  const Display = (
    <ListItem
      disablePadding
      sx={{
        height: "24px",
      }}
    >
      <ChevronRightIcon
        sx={{
          width: "19px",
          height: "18px",
          backgroundColor: "primary.main",
          color: "white",
          borderRadius: "2px",
          mr: "4px",
        }}
      />
      <ListItemText
        primary={item.name}
        primaryTypographyProps={{ noWrap: true }}
      />
    </ListItem>
  );

  return (
    <DefaultListItem
      item={item}
      onSelectItem={onSelectCategory}
      meta={{ listType: "category", dataType: TAXONOMY_TYPE }}
      displayText={Display}
      {...rest}
    />
  );
}

// const paddingLeft = "29px";
// selectable base segment/attribute list item
export function SegmentItem({
  isSelected,
  item,
  dataType,
  onHandleBuilderTransfer,
  onHandleBuilderSelect,
  sxProps = {},
}) {
  return isSelected ? (
    <SelectedListItem
      // key={item.idx}
      item={item}
      onActionItem={onHandleBuilderTransfer}
      meta={{ itemIdx: item.idx, dataType }}
      displayText={<Tooltip data={item} dataType={dataType} />}
      sxProps={sxProps}
    />
  ) : (
    <DefaultListItem
      // key={item.idx}
      item={item}
      onSelectItem={onHandleBuilderSelect}
      meta={{ itemIdx: item.idx, dataType }}
      sxProps={sxProps}
    />
  );
}
