import { useState, useEffect } from "react";

function getSavedValue(key) {
  const saved = localStorage.getItem(key);
  return JSON.parse(saved);
}

function saveValue(key, value) {
  const newValue = JSON.stringify(value);
  localStorage.setItem(key, newValue);
}

function useLocalStorage(key, defaultValue) {
  const [value, setValue] = useState(getSavedValue(key) || defaultValue);

  useEffect(() => {
    saveValue(key, value);
  }, [key, value]);

  return [value, setValue];
}

export default useLocalStorage;
