import { Typography } from "@mui/material";
import SectionCard from "../../../elements/Cards/SectionCard";

export default function General() {
  return (
    <SectionCard title="General">
      <Typography variant="body2" align="left" color="text.secondary" paragraph>
        Something short and leading about the collection below—its contents
      </Typography>
    </SectionCard>
  );
}
