import { useTranslation } from "react-i18next";
import { Box, Paper } from "@mui/material";
import Page from "elements/Page";
import {
  getTableChange,
  initialTableState,
} from "components/AudiencesDataGrid/helpers";
import SearchField from "elements/Field/SearchField";
import { useMemoizedCallback } from "hooks";
import useCustomizationStorage from "components/DataGrid/useCustomizationStorage";
import useTableState from "components/DataGrid/useTableState";
import useAccount from "contexts/accountContext";
import ModelsTable from "./ModelsTable";

// Searching and loading data in the grid may need an API, thus far it's all mocked in the FE
export default function Models() {
  const { t } = useTranslation();
  const { accountId } = useAccount();

  const [storage, setStorage] = useCustomizationStorage("ModelsDataGrid", {
    columnVisibilityModel: null,
    pageSize: initialTableState.pageSize,
    density: "standard",
  });

  const { tableState, tableChange } = useTableState({
    ...initialTableState,
    account: accountId,
    pageSize: storage.pageSize,
  });

  const tableDispatch = useMemoizedCallback(
    (type, newState, isFromTable = false) => {
      const ret = isFromTable ? newState : getTableChange(type, newState);
      tableChange(ret);
    }
  );

  const onSearchChange = (searchValue, type) => {
    if (searchValue.length === 0 || searchValue.trim().length >= 3) {
      tableDispatch(type, searchValue);
    }
  };

  return (
    <Page title={t("Models")}>
      <Paper elevation={2}>
        <Box pt={5} pl={3} pr={3} pb={2}>
          <SearchField
            searchDisplay={t("Search Models")}
            textFieldProps={{
              fullWidth: true,
              size: "small",
              sx: {
                mb: 2,
              },
            }}
            onSearchChange={onSearchChange}
            debounceTimeout={300}
            triggerValue={tableState?.search}
          />
          <ModelsTable
            tableState={tableState}
            tableChange={tableChange}
            storage={storage}
            setStorage={setStorage}
            tableDispatch={tableDispatch}
          />
        </Box>
      </Paper>
    </Page>
  );
}
