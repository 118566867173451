import Alert from "@mui/material/Alert";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import StatusDisplay from "./StatusDisplay";

const alertIcons = {
  warning: false,
  info: false,
  success: <CheckCircleOutlineIcon fontSize="inherit" sx={{ mr: -1 }} />,
  error: <ErrorOutlineIcon fontSize="inherit" sx={{ mr: -1 }} />,
};

// divider or border bottom?
export default function BuilderStatus({
  builderItemsMax,
  builderStatus,
  count = 0,
}) {
  const { status, statusTxt } = builderStatus;

  return (
    <Alert
      variant="outlined"
      icon={alertIcons[status]}
      severity={status}
      sx={{
        padding: 0.5,
        ml: 0,
        mr: 8,
        mb: 0,
        border: 0,
        alignItems: "center",
      }}
      action={
        <StatusDisplay
          count={count}
          status={status}
          statusTxt={statusTxt}
          builderItemsMax={builderItemsMax}
        />
      }
    >
      {builderStatus[status]}
    </Alert>
  );
}
