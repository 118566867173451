import { useCallback } from "react";

function getEditableValues(editableProps, newRow) {
  return editableProps.reduce(
    (previouEditableValues, prop) => ({
      ...previouEditableValues,
      [prop]: newRow[prop],
    }),
    {}
  );
}

function useDataGridEditFields({ editableFields, mutate }) {
  const updateFields = useCallback(
    async (newRow, oldRow) =>
      new Promise((resolve) => {
        const hasChanges = editableFields.some(
          (prop) => newRow[prop] !== oldRow[prop]
        );

        if (hasChanges) {
          mutate(
            {
              id: newRow.id,
              editedFields: getEditableValues(editableFields, newRow),
            },
            {
              onSuccess: () => {
                resolve(newRow);
              },
              onError: () => {
                resolve(oldRow);
              },
            }
          );
        } else {
          resolve(oldRow);
        }
      }),
    [editableFields, mutate]
  );

  return updateFields;
}

export default useDataGridEditFields;
