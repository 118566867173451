import { useMemoizedCallback } from "hooks";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { useMemo, useState } from "react";
import BluDataGrid from "components/DataGrid/BluDataGrid";
import LinearProgress from "@mui/material/LinearProgress";
import { Box } from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getModelsColumns } from "./getColumns";
import BluButton from "../../../../elements/Button/BluButton";

const mockData = [
  {
    id: "649681",
    seed_segment: "THC_LilysChocolate_WeightLoss-ChocolateBuyer",
    created_at: "2022-12-16T16:31:26.132915Z",
    status: "Processing",
  },
  {
    id: "649682",
    seed_segment: "THC_LilysChocolate_WeightLoss-ChocolateBuyer",
    created_at: "2022-12-16T16:31:26.132915Z",
    status: "Processing",
  },
  {
    id: "649683",
    seed_segment: "THC_LilysChocolate_WeightLoss-ChocolateBuyer",
    created_at: "2022-12-16T16:31:26.132915Z",
    status: "Complete",
  },
  {
    id: "649684",
    seed_segment: "THC_LilysChocolate_WeightLoss-ChocolateBuyer",
    created_at: "2022-12-16T16:31:26.132915Z",
    status: "Complete",
  },
  {
    id: "649685",
    seed_segment: "THC_LilysChocolate_WeightLoss-ChocolateBuyer",
    created_at: "2022-12-16T16:31:26.132915Z",
    status: "Error",
  },
  {
    id: "649686",
    seed_segment: "THC_LilysChocolate_WeightLoss-ChocolateBuyer",
    created_at: "2022-12-16T16:31:26.132915Z",
    status: "Error",
  },
];

function HeaderButton({ isButtonDisabled, modelId }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <BluButton
      onClick={() => navigate(`audience-scaling/${modelId[0]}`)}
      color="primary"
      disabled={isButtonDisabled}
      sx={{ marginLeft: "auto" }}
    >
      {t("Scale Audience")}
      <GroupsIcon sx={{ marginLeft: "9px" }} />
    </BluButton>
  );
}
export default function ModelsTable({
  tableState,
  storage,
  setStorage,
  tableDispatch,
}) {
  const [selectedRows, setSelectedRows] = useState([]);
  const modelId = selectedRows?.map((row) => row.id);

  // Selecting rows only when the status is "Complete"
  const getRowOnSelection = useMemoizedCallback((ids) => {
    const selectedIDs = new Set(ids);
    const selectedRowData = mockData.filter(
      (row) => selectedIDs.has(row.id) && row.status === "Complete"
    );
    setSelectedRows(selectedRowData);
  });

  const CustomToolbar = useMemoizedCallback(() => (
    <GridToolbarContainer>
      <Box sx={{ display: "flex", width: "100%", marginBottom: 2 }}>
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
        <HeaderButton
          isButtonDisabled={selectedRows?.length !== 1}
          modelId={modelId}
        />
      </Box>
    </GridToolbarContainer>
  ));

  const columns = useMemo(() => getModelsColumns(), []);

  return (
    <BluDataGrid
      customizationStorage={storage}
      handleCustomizationStorageChange={setStorage}
      tableState={tableState}
      tableDispatch={tableDispatch}
      tableData={mockData}
      // isValid={isValid}
      // error={error}
      // isLoading={isLoading || isRefetching}
      columns={columns}
      // pageTotalSize={pageTotalSize}
      density={storage.density}
      onSelectionModelChange={(ids) => getRowOnSelection(ids)}
      selectionModel={modelId}
      components={{ LoadingOverlay: LinearProgress, Toolbar: CustomToolbar }}
      checkboxSelection={false}
      disableSelectionOnClick={false}
      mode="client"
    />
  );
}
