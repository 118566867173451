/* eslint-disable import/prefer-default-export */
import { ListItem, Divider, Box } from "@mui/material";
import LayersIcon from "@mui/icons-material/Layers";
import ListItemLink from "../../../elements/Menu/ListItemLink";
import ListSection from "../../../elements/Menu/ListSection";
import ThemeDefaultSwitch from "./Settings/ThemeDefaultSwitch";

export function DevMenu(props) {
  const { title = "Development" } = props;
  return (
    <Box mt={2}>
      <Divider
        sx={{
          borderColor: "#2D3748",
          mb: 2,
        }}
      />
      <ListSection title={title}>
        <ListItemLink
          to="/style-guide"
          primary="Style Guide"
          icon={<LayersIcon />}
        />
        <ListItem>
          <ThemeDefaultSwitch switchProps={{ edge: "start" }} />
        </ListItem>
      </ListSection>
    </Box>
  );
}
