import { useMemo } from "react";
import { useLocation, useResolvedPath } from "react-router-dom";

// determine active match to current location - allowing for partials and extended paths
// using internals of react router nav link logic: reversed to handle partials
// route: /dashboard/audiences | activate if match; partial /dashboard; OR extends past dashboard/audiences/12

export default function useActivePathMatch(to, isPartial = false) {
  const { pathname } = useLocation();
  const resolved = useResolvedPath(to);
  // const active = useMatch({ path: resolved.pathname, end: true });
  const toPathname = resolved.pathname;
  const extended = pathname.length > toPathname.length;
  const checkMatch = useMemo(
    () =>
      pathname === toPathname ||
      (extended && pathname.includes(toPathname)) ||
      (!extended &&
        isPartial &&
        toPathname.startsWith(pathname) &&
        toPathname.charAt(pathname.length) === "/"),
    [pathname, toPathname, isPartial]
  );
  return checkMatch;
}
