import axios from "./axios";

const context = "/partners";

export default {
  get: async () => {
    try {
      const response = await axios.get(context);
      return response?.data;
    } catch (e) {
      throw e?.response?.data;
    }
  },
};
