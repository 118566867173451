import axiosLib from "axios";
import axios from "../axios";

const context = "/accounts";

export default {
  getSignedUploadUrl: async (accountId, fileName) => {
    try {
      const response = await axios.get(
        `${context}/${accountId}/logo_upload?filename=${fileName}`
      );
      return response?.data?.upload_url;
    } catch (e) {
      const errorMessages = { error: e?.message };
      throw errorMessages;
    }
  },
  uploadLogo: async ({ uploadUrl, selectedImage }) => {
    try {
      // Using directly from axios lib to avoid conflicts between AWS and our Authorization header
      const response = await axiosLib.put(uploadUrl, selectedImage, {
        headers: {
          "Content-Type": `${selectedImage?.type}`,
        },
      });
      return response?.data;
    } catch (e) {
      const errorMessages = { error: e?.message };
      throw errorMessages;
    }
  },
};
