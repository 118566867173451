import { Stack, Button } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const colors = [
  "inherit",
  "primary",
  "secondary",
  "success",
  "error",
  "info",
  "warning",
];

const sizes = ["small", "medium", "large"];

// https://mui.com/material-ui/react-button/#loading-button

export function MakeButtons({ type }) {
  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="space-between"
      // flexWrap="wrap"
      alignItems="center"
    >
      {colors.map((c) => (
        <Button
          key={c}
          variant={type}
          color={c}
          sx={{ textTransform: "capitalize" }}
        >
          {c}
        </Button>
      ))}
    </Stack>
  );
}

export function MakeSizeButtons({ type }) {
  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="center"
      alignItems="center"
    >
      {sizes.map((s) => (
        <Button
          key={s}
          variant={type}
          startIcon={<AddCircleIcon />}
          size={s}
          sx={{ textTransform: "capitalize" }}
        >
          {s}
        </Button>
      ))}
    </Stack>
  );
}

export function TextButtons() {
  return <MakeButtons type="text" />;
}

export function ContainedButtons() {
  return <MakeButtons type="contained" />;
}

export function OutlinedButtons() {
  return <MakeButtons type="outlined" />;
}
export function OutlinedSizeButtons() {
  return <MakeSizeButtons type="outlined" />;
}
export function TextSizeButtons() {
  return <MakeSizeButtons type="text" />;
}

export function ContainedSizeButtons() {
  return <MakeSizeButtons type="contained" />;
}
