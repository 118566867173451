import { useCallback, useEffect, useRef } from "react";

// general purpose hooks

export function useMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return useCallback(() => isMounted.current, []);
}

// flag for first render
export function useFirstRender() {
  const firstRender = useRef(true);

  useEffect(() => {
    firstRender.current = false;
  }, []);

  return firstRender.current;
}

// gets the previous value props/state
// https://usehooks.com/usePrevious/
export function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

// prevent rerenders of children from parent updates - when passing callbacks as props
// https://github.com/samanmohamadi/use-memoized-callback
export function useMemoizedCallback(cb) {
  const data = useRef({ callback: null, handler: null });
  data.current.callback = cb;
  if (!data.current.handler) {
    data.current.handler = (...args) => data.current.callback(...args);
  }
  return data.current.handler;
}
