import { useMemo } from "react";
import List from "@mui/material/List";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";

// needs a position relative parent - in List it scrolls with content
export function CoverLoader({ progressProps = {} }) {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: "modal",
      }}
    >
      <CircularProgress {...progressProps} />
    </Box>
  );
}

export function EmptyData({ message = "No Data", sxProps = {} }) {
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        ...sxProps,
      }}
    >
      <PlaylistRemoveIcon fontSize="large" color="warning" />
      <Typography variant="overline" display="block">
        {message}
      </Typography>
    </Box>
  );
}

// TBD list component: paging/load more button etc
export default function SimpleList(props) {
  const {
    children,
    isLoading,
    emptyMessage,
    isControlOverflow = true,
    listProps = {},
  } = props;
  const isEmpty = useMemo(
    () => !isLoading && children?.length < 1,
    [children, isLoading]
  );
  return (
    <>
      <List
        sx={isControlOverflow ? { flex: 1, overflowY: "auto" } : {}}
        disablePadding
        dense
        {...listProps}
      >
        {isEmpty ? <EmptyData message={emptyMessage} /> : children}
      </List>
      {isLoading && <CoverLoader />}
    </>
  );
}
