import { Grid, Typography } from "@mui/material";
import numbro from "numbro";

export default function PartnerList({ rowSpacing, columnSpacing }) {
  return (
    <Grid container rowSpacing={rowSpacing} mt={0}>
      {/* Temporary loop. It'll come from the API */}
      {[1, 2, 3, 4, 5].map((n) => (
        <Grid container item columnSpacing={columnSpacing} key={n}>
          <Grid item xs={6}>
            <Typography>theTradeDesk</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography>{numbro("1152922").format()}</Typography>
          </Grid>
          <Grid item xs={1} />
        </Grid>
      ))}
    </Grid>
  );
}
