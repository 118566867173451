import { useMutation, useQueryClient } from "react-query";
import customizationApi from "../../../api/accounts/customization";
import QUERY_KEYS from "../../../constants/queryKeys";

export function useCustomizationMutations() {
  const queryClient = useQueryClient();

  return useMutation(customizationApi.uploadLogo, {
    onMutate: () => queryClient.cancelQueries([QUERY_KEYS.ACCOUNTS]),
    onSuccess: () =>
      queryClient.invalidateQueries([QUERY_KEYS.ACCOUNTS], {
        refetchInactive: true,
      }),
  });
}
