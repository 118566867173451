import { useTranslation } from "react-i18next";
import { useState } from "react";
import { updateAudienceName } from "utils";
import { useFormik } from "formik";
import * as Yup from "yup";
import useAccount from "contexts/accountContext";
import CopyAcrossAccounts from "./index";
import { useSegmentsCopy } from "../../useSegmentsMutations";

export default function CopyAcrossAccountsConfig({
  setSelectedAudiences,
  selectedAudience,
  setOpenDialog,
  setActionName,
}) {
  const { t } = useTranslation();
  const [selectedAccountId, setSelectedAccountId] = useState("");
  const { activeAccount, accountId } = useAccount();

  const { mutate: copyToAccount, isLoading } = useSegmentsCopy({
    key: "AUDIENCES",
  });

  function clear() {
    setOpenDialog(false);
    setSelectedAudiences([]);
    setActionName("");
    setSelectedAccountId("");
  }

  const formik = useFormik({
    initialValues: {
      segmentName: updateAudienceName(selectedAudience?.name),
    },
    validationSchema: Yup.object({
      segmentName: Yup.string().trim().required("Segment name is required"),
    }),
    onSubmit: async () => {
      const { segmentName } = formik.values;
      copyToAccount(
        {
          segmentId: selectedAudience.id,
          segmentName,
          accountId: selectedAccountId,
        },
        {
          onSettled: () => {
            clear();
          },
        }
      );
    },
    enableReinitialize: true,
  });

  function onSelectAccountChange(accId) {
    setSelectedAccountId(accId);
  }

  const {
    handleSubmit,
    errors,
    values,
    touched,
    setFieldValue,
    setFieldTouched,
    resetForm,
  } = formik;

  function onClose() {
    clear();
    resetForm();
  }

  return {
    Component: CopyAcrossAccounts,
    title: t("Copy Segment Across Accounts"),
    confirmText: t("Confirm"),
    onConfirm: handleSubmit,
    onClose,
    isLoading,
    // If one day we have an account with ID 0 (zero), it'll cause a problem here in !selectedAccountId, returning TRUE
    isConfirmDisabled: !!errors.segmentName || !selectedAccountId,
    props: {
      segmentName: values.segmentName,
      selectedAccountId,
      onSelectAccountChange,
      activeAccount,
      accountId,
      errors,
      touched,
      setFieldValue,
      setFieldTouched,
    },
  };
}
