/* eslint-disable no-console */
/**
 * This custom hook was created to set the token and the user data in every sign-in and state change.
 * Every time the value of accessToken changes (ex. hard reload, f5, sign-in, sign-out)
 * it tries to retrieve the user session and data from AWS. In case the session doesn't
 * exist, the app is redirect to login, and in case it does, it's redirect to the root page "/"
 */
import { Auth, Hub } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { setAccessToken } from "../../slices/authentication";
import { setUserData } from "../../slices/user";

function getAuthenticatedUser() {
  return Auth.currentAuthenticatedUser().then((user) => user);
}

function getTokens() {
  return Auth.currentSession().then((session) => session);
}

async function getUserData() {
  const userTokens = await getTokens();
  const cognitoUser = await getAuthenticatedUser();

  return {
    userTokens,
    user: cognitoUser,
  };
}

const refreshAccessToken = async (dispatch) => {
  try {
    const cognitoUser = await getAuthenticatedUser();
    const currentSession = cognitoUser.signInUserSession;
    cognitoUser.refreshSession(
      currentSession.getRefreshToken(),
      (err, session) => {
        dispatch(setAccessToken(session?.accessToken?.jwtToken));
      }
    );
  } catch (e) {
    console.log("Unable to refresh token", e);
  }
};

// TBD - get some user data from response attributes
function convertUserData(user) {
  return {
    username: user?.username,
    attributes: {
      firstName: user?.attributes?.given_name,
      lastName: user?.attributes?.family_name,
      email: user?.attributes?.email,
    },
  };
}

const ONE_MINUTE_IN_MS = 1000 * 60;
function calculateExpirationTime({ exp, iat }) {
  const expToDate = new Date(exp * 1000);
  const iatToDate = new Date(iat * 1000);
  return Math.abs(expToDate - iatToDate) - ONE_MINUTE_IN_MS; // By subtracting 1 minute we make sure to call the api to refresh the token 1 minute before it actually expires (just a random number)
}

let interval;

export default function useAmplify() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const accessToken = useSelector((state) => state.authentication.accessToken);
  const username = useSelector((state) => state.user.username);

  useEffect(() => {
    if (!accessToken) {
      getUserData()
        .then((response) => {
          const convUser = convertUserData(response?.user);
          // console.log("Auth", response, convUser);
          dispatch(setAccessToken(response?.userTokens?.accessToken?.jwtToken));
          dispatch(setUserData(convUser));
        })
        .catch((err) => {
          console.warn(err?.message || err);
          // TODO add location state for from
          // <Navigate to="/login" state={{ from: location }} replace />;
          // then: let from = location.state?.from?.pathname || "/";
          // navigate(from, { replace: true });
          navigate("login");
        });
      return;
    }
    // console.log("START CALLED", location);
    // fix forced navigation - cannot route directly to page as always redirects on start
    // navigate("/");
    navigate(location?.pathname || "/");
  }, [accessToken]);

  // This useEffect sets the interval to update the accessToken based on the refreshToken
  useEffect(() => {
    if (username) {
      let expirationTime = ONE_MINUTE_IN_MS; // Default 1 minute
      getTokens().then(async (tokens) => {
        const payload = tokens?.accessToken?.payload;

        if (payload.exp && payload.iat) {
          expirationTime = calculateExpirationTime(payload);
          // Calling refresh function right after having a username, so we can update the token
          // after the login. It's important for cases where the user just closed the browser tab
          // and opened it again (To avoid access token to expire while the user is using the app)
          await refreshAccessToken(dispatch);
          interval = setInterval(
            () => refreshAccessToken(dispatch),
            expirationTime
          );
        }
      });

      return () => {
        clearInterval(interval);
      };
    }
    return undefined;
  }, [username]);

  useMemo(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          getUserData().then((response) => {
            const convUser = convertUserData(response?.user);
            dispatch(
              setAccessToken(response?.userTokens?.accessToken?.jwtToken)
            );
            dispatch(setUserData(convUser));
          });
          break;
        case "signOut":
          console.log("Sign out");
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.warn("Sign in failure", data);
          break;
        default:
          break;
      }
    });
  }, []);
}
