import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import { AccountProvider } from "contexts/accountContext";
import MainLayoutContent from "./MainLayoutContent";

export default function MainLayout() {
  const navigate = useNavigate();
  const username = useSelector((state) => state.user.username);

  /** Redirecting to log in if the user is not authenticated. Had to add it here to avoid
   * a scenario where the user, after login out, hits the back button in the browser
   * and can see a blank page
   * Bug Ticket: https://spirehorizon.atlassian.net/browse/BV-693 * */
  useEffect(() => {
    Auth.currentAuthenticatedUser().catch(() => {
      navigate("login");
    });
  }, []);

  return (
    username && (
      <AccountProvider>
        <MainLayoutContent username={username} />
      </AccountProvider>
    )
  );
}
